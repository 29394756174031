import { http } from '@/utilidades'

// módulo con todo el estado relacionado al estado de la aplicación (conexión, geolocalización)
export default {
  namespaced: true,
  state: {
    filtros: [],
    filtro_actual: {},
  },
  mutations: {
    addFiltro(state, filtro) {
      state.filtros.push(filtro)
    },
    removeFiltro(state, id_filtro) {
      const index = state.filtros.findIndex((filtro) => filtro.id === id_filtro)
      state.filtros.splice(index, 1)
    },
    filtroActual(state, filtro) {
      state.filtro_actual = { ...filtro }
    },
  },
  actions: {
    async traerFiltros(context, tipo) {
      try {
        const response = await http({
          url: '/filtros',
          method: 'post',
          data: {
            tipo,
          },
        })
        return response.data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async agregarFiltro(context, filtro) {
      context.commit('addFiltro', filtro)
      try {
        if (!filtro) return []
        const response = await http({
          url: '/filtros/add',
          method: 'post',
          data: {
            filtro,
          },
        })
        return response.data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async ocultarFiltro(context, id_filtro) {
      try {
        if (!id_filtro) return []
        const response = await http({
          url: '/filtros/hide',
          method: 'post',
          data: {
            id_filtro,
          },
        })
        return response.data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    // consulta generica para traer información de la base de datos
    async consultarReporte(context, url) {
      try {
        if (!url) return []
        const response = await http({
          url: url,
          method: 'post',
          data: {
            context: 'report',
          },
        })
        return response.data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async getReport(context, { url, payload = {} }) {
      try {
        const response = await http({
          url: url,
          method: 'post',
          data: {
            ...payload,
          },
        })
        return response.data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async getReport2(context, { url, payload = {} }) {
      try {
        if (!payload) return []
        const response = await http({
          url: url,
          method: 'post',
          data: {
            ...payload,
          },
        })
        return response.data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async todosLosProductores({ dispatch }) {
      const data = await dispatch('consultarReporte', '/productor/all')
      return data
    },
    async familia({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/familia')
      return data
    },
    async tenencia({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/tenencia')
      return data
    },
    async rubro({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/rubro')
      return data
    },
    async pecuario({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/pecuario')
      return data
    },
    async forestal({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/forestal')
      return data
    },
    async artesania({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/artesania')
      return data
    },
    async agroindustria({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/agroindustria')
      return data
    },
    async finca({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/finca')
      return data
    },
    async acuicultura({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/acuicultura')
      return data
    },
    async agroturismo({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/agroturismo')
      return data
    },
    async apicultura({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/apicultura')
      return data
    },
    async pesca({ dispatch }) {
      const data = await dispatch('consultarReporte', '/reportes/pesca')
      return data
    },
    async agriculturaFamiliar({ dispatch }, payload) {
      try {
        if (!payload) return []
        const data = await dispatch('getReport', {
          url: '/reportes/agriculturafamiliar',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async organizacionesYProductores({ dispatch }, payload) {
      try {
        if (!payload) return []
        const data = await dispatch('getReport', {
          url: '/reportes/organizaciones-y-productores',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async organizacionesYProductoresCalc({ dispatch }, payload) {
      try {
        if (!payload) return []
        const data = await dispatch('getReport', {
          url: '/reportes/organizaciones-y-productores-calc',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async contratacionYBeneficiados({ dispatch }, payload) {
      try {
        if (!payload) return []
        const data = await dispatch('getReport', {
          url: '/reportes/contratacion-y-beneficiados',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async contratacionYBeneficiadosCalc({ dispatch }, payload) {
      try {
        if (!payload) return []
        const data = await dispatch('getReport', {
          url: '/reportes/contratacion-y-beneficiados-calc',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async bitacoraDeCambios({ dispatch }, payload) {
      try {
        if (!payload) return []
        const data = await dispatch('getReport', {
          url: '/reportes/bitacora-de-cambios',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async productores({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/productores',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async produccionNacional({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/produccion-nacional',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async produccionNacionalCalc({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/produccion-nacional-calc',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async produccionDetallada({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/produccion-detallada',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async produccionDetallada2({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport2', {
          url: '/reportes/produccion-detallada2',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async produccionConsolidada({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/produccion-consolidada',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async mapaProductor({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/mapa-productores',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },async mapaSiembra({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/mapa-siembra',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async mapaCosecha({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/mapa-cosecha',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async mapaFinca({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/mapa-finca',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async mapaSatelite({ dispatch }, payload) {
      try {
        const data = await dispatch('getReport', {
          url: '/reportes/mapa-satelite',
          payload,
        })
        return data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
  },
}
