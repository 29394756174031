<template>
  <div>
    <h1>Procesa Cultivos detectados por el Satélite</h1>
    <h3>Ingrese archivo Tiff</h3>
    <!-- Mensaje de cargando -->
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Se está cargando...</span>
    </v-overlay>

    <div class="select-container">
      <v-row>
        <v-col cols="5">
          <v-select
            v-model="selectedAnoAgricolaSave"            
            :items="aniosAgricolas"
            :rules="[r.required]"
            label="Selecciona un año agrícola"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="id_cultivo"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="cultivo"
            item-text="descripcion"
            item-value="id"
            label="Rubro*"
            outlined
          />
        </v-col>

        <v-col cols="12">
          <!-- Campo de archivo -->
          <input
            type="file"
            ref="fileInput"
            accept=".tif,.tif.aux.xml"
            style="width: 100%"
          />          
          <v-btn
            depressed
            class="primary"
           style="margin-top: 20px; margin-left: 20px"
            @click="uploadFile"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>




<script>
//import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import parse_georaster from 'georaster'
import { mapState } from 'vuex'
import * as rules from '@/utils/validations'
//import GeoRasterLayer from 'georaster-layer-for-leaflet'

export default {
  name: 'TiffSave',
  data() {
    return {
      url: '',
      r: rules,
      isLoading: false,
      file: null, // Selected file
      metadataObj: {
        max: '',
        min: '',
        stndrdev: 0.10358805220196,
        url: '',
        nombre: '',
        ano_agricola: '',
        id_cultivo: '',
      },
      selectedAnoAgricolaSave: '',
      arrayBuffer : '',
      id_cultivo: '',
      aniosAgricolas: [],
    }
  },
  computed: {
    ...mapState('listados', ['cultivo']),
    token() {
      const user =
        this.$store.state.auth.users[this.$store.state.auth.current_user]
      return user ? user.token : ''
    },
  },
  mounted() {
    if (process.env.NODE_ENV === 'production') {
      this.url = 'https://sigap.mida.gob.pa/suag/storage/app/public/uploads/';
      //this.url = 'http://10.253.177.70/uploads/'
    } else {
      this.url = 'http://localhost/uploads/'
    }
    this.$store.commit('ui/updateToolbar', 'Procesa Datos del Satélite')
    this.calculaAniosAgricolas()
  },
  methods: {
    calculaAniosAgricolas() {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth()

      const startYear = currentMonth < 4 ? currentYear - 1 : currentYear

      for (let i = 0; i < 10; i++) {
        const yearStart = startYear - i
        const yearEnd = yearStart + 1
        this.aniosAgricolas.push(`${yearStart}-${yearEnd}`)
      }
      for (let i = 1; i <= 2; i++) {
        const futureYearStart = startYear + i
        const futureYearEnd = futureYearStart + 1
        this.aniosAgricolas.unshift(`${futureYearStart}-${futureYearEnd}`)
      }
      //this.selectedAnoAgricolaSave = this.aniosAgricolas[3]
    },
    /*     handleFileUpload(event) {
      this.metadataObj.url = this.url
      this.file = event.target.files[0]
      if (this.file) {
        const reader = new FileReader()
        reader.onload = async (e) => {
          this.arrayBuffer = e.target.result
          await this.processFile(this.arrayBuffer) // processFile es otra función que asumo ya tienes
        }
        reader.readAsArrayBuffer(this.file)
      } else {
        alert('Por favor, selecciona un archivo primero.')
      }
    }, */

     async uploadFile() {
      this.isLoading = true
      if (
        this.id_cultivo &&
        this.selectedAnoAgricolaSave
      ) {

        const fileInput = this.$refs.fileInput
        // Verificar si se ha seleccionado un archivo
        if (fileInput.files.length === 0) {
          alert('Por favor, selecciona un archivo TIFF primero.')
          this.isLoading = false
          return
        }
      // selectedFile tiene el archivo
      const selectedFile = fileInput.files[0]

      // llena this.file /////////////
      await this.handleFileUpload(selectedFile) // Procesar el archivo seleccionado

      /*   alert(this.id_cultivo)
        alert(this.selectedAnoAgricolaSave) */
      // Verificar si el archivo se procesó correctamente
       if (!this.file) {
        alert('Hubo un problema al procesar el archivo.')
        this.isLoading = false
        return
      }
      const formData = new FormData()
      formData.append('file', this.file)

      // Mostrar el overlay de carga
      try {
        let response; 
        if (process.env.NODE_ENV === 'production') {
           //await this.$store.dispatch('registros/guardarTiff', this.metadataObj)
          //guarda el archivo
          response = await fetch(
            'https://sigap.mida.gob.pa/suag/public/api/tiff/archivo',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
              body: formData,
            }
          )

        } else {
          //await this.$store.dispatch('registros/guardarTiff', this.metadataObj)
          response = await fetch(
            'http://localhost/suag/public/api/tiff/archivo',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
              body: formData,
            }
          )
        }
        console.log(response.ok)
        console.log(response)
        if (response.ok) {
          const data = await response.json()
          console.log('Archivo subido exitosamente:', data)
        } else {
          console.error('Mi Error al subir el archivo:', response.statusText)
          alert('Hubo problema al subir el archivo.')
        }
        await this.simulateUpload()

      } catch (error) {
        console.error('Mi Error de red:', error)
        alert('Hubo un problema al subir el archivo.')
      } finally {
        this.isLoading = false
      }

     } else {
        alert('Por favor, completa todos los campos antes de guardar.')
        this.isLoading = false
        return
     }
      
    },
    
    async handleFileUpload(file) {
      // Almacenar el archivo seleccionado en this.file
      this.file = file
      if (this.file) {

        //instancia el objeto reader como un Filereader
        const reader = new FileReader()

        //funcion que inicia cuando el objeto reader se ha leido ocmpletamente
        //para obtener los datos del archivo y guardarlos en la BD.
        reader.onload = async (e) => {
          this.arrayBuffer = e.target.result  // e.target.result contiene el contenido del archivo
          await this.processFile(this.arrayBuffer) // arrayBuffer es el contenido del archivo en binario
        }
        // comienza a leer el archivo en binario
        reader.readAsArrayBuffer(this.file)
        
      } else {
        alert('Por favor, selecciona un archivo primero.')
      }
    },

    async processFile(arrayBuffer) {
     if (this.id_cultivo && this.selectedAnoAgricolaSave && this.file.name) {
      try {
        const georaster = await parse_georaster(arrayBuffer)
        this.metadataObj.max = georaster.maxs[0]
        this.metadataObj.min = georaster.mins[0]
        this.metadataObj.url = this.url + this.file.name
        this.metadataObj.nombre = this.file.name
        this.metadataObj.ano_agricola = this.selectedAnoAgricolaSave // Ajustar según sea necesario
        this.metadataObj.id_cultivo = this.id_cultivo
        console.log('metadata', this.metadataObj)
        // llama a set de Tiff en el Backend
        await this.$store.dispatch('registros/guardarTiff', this.metadataObj)
        // verifico el directorio y movio el archivo
      } catch (error) {
        console.error('error guardando datos:', error)
      }
     }
    },

   

    async simulateUpload() {
      // Simula una espera como si se estuviera subiendo un archivo
      return new Promise((resolve) => {
        setTimeout(resolve, 500) // 3 segundos de espera
      })
    },
  },
}
</script>
<style scoped>
.v-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
</style>
