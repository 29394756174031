import { render, staticRenderFns } from "./FormularioConExpe.vue?vue&type=template&id=9a61d7e2&scoped=true"
import script from "./FormularioConExpe.vue?vue&type=script&lang=js"
export * from "./FormularioConExpe.vue?vue&type=script&lang=js"
import style0 from "./FormularioConExpe.vue?vue&type=style&index=0&id=9a61d7e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a61d7e2",
  null
  
)

export default component.exports