var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{domProps:{"textContent":_vm._s(_vm.item.nombre || 'Sin nombre...')}}),_c('div',{staticClass:"grey--text",domProps:{"textContent":_vm._s(
      `${
        _vm.productores[_vm.item.id_productor]
          ? _vm.productores[_vm.item.id_productor].primer_nombre
          : ''
      } ${
        _vm.productores[_vm.item.id_productor]
          ? _vm.productores[_vm.item.id_productor].primer_apellido
          : ''
      } - ${_vm.item.id_productor}`
    )}})])
}
var staticRenderFns = []

export { render, staticRenderFns }