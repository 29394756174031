// Modelo de datos que refleja la estructura de la base de datos a la hora de almacenar la información en el cliente.
// Esta información es de suma importancia pues en caso de que algun campo sea opcional es enviado vacío al server
// lo que evita conflictos de no recibir campos que el usuario no registro.
// Al mismo tiempo esto es un reflejo de la base de datos lo que ayuda a mantener de forma unificada los nombres de los campos
// a través del frontend y el backend.

const telefono_persona = {
  tipo_telefono: '',
  telefono: '',
}

const productor = {
  id: '',
  tipo_id: '',
  primer_nombre: '',
  segundo_nombre: '',
  primer_apellido: '',
  segundo_apellido: '',
  apellido_conyugue: '',
  fecha_nacimiento: '',
  sexo: '',
  email: '',
  direccion: '',
  id_provincia: '',
  id_distrito: '',
  id_corregimiento: '',
  id_poblado: '',
  numero_casa: '',
  apartado_postal: '',
  escolaridad: '',
  sabe_leer: 1,
  sabe_escribir: 1,
  apodo: '',
  id_nacionalidad: 'PA',
  tipo_empresa: 1,
  tiene_organizacion: 0,
  id_organizacion: '',
  nombre_organizacion: '',
  tipo_organizacion: '',
  latitud: '',
  longitud: '',
  latlong: '',
  utm: '',
  actividad: '',
  meses_actividad: '',
  anios_actividad: '',
  created_at: '',
  updated_at: '',
  observacion: '',
}

const empresa = {
  ruc: '',
  email: '',
  nombre: '',
  direccion: '',
  telefono: '',
  apartado_postal: '',
  sitio_web: '',
  id_provincia: '',
  id_distrito: '',
  id_corregimiento: '',
  id_poblado: '',
  latitud: '',
  longitud: '',
  latlong: '',
  utm: '',
  nombre_contacto: '',
  email_contacto: '',
  telefono_contacto: '',
  actividad: '',
  anios_actividad: '',
  meses_actividad: '',
  dv: '',
  id_productor: '',
  created_at: '',
  updated_at: '',
  organizaciones: [],
  certificaciones: [],
}

const familia = {
  id_productor: '',
  programa_familia: [],
  actividad: [],
  trabajador_fijo_hombre: 0,
  trabajador_fijo_mujer: 0,
  trabajador_eventual_hombre: 0,
  trabajador_eventual_mujer: 0,
  actividad_fijo: [],
  actividad_eventual: [],
}

const integrante_familia = {
  id_persona: '',
  tipo_id: '',
  primer_nombre: '',
  segundo_nombre: '',
  primer_apellido: '',
  segundo_apellido: '',
  apodo: '',
  sexo: '',
  fecha_nacimiento: '',
  dias_trabajo_finca: '',
  dias_trabajo_fuera: '',
  parentesco: '',
  ingreso_fuera_finca: 0,
}

const finca = {
  id: '',
  id_enc: '',
  id_finca: '',
  id_productor: '',
  nombre: '',
  num_registro: '',
  ruc: '',
  id_provincia: '',
  id_distrito: '',
  id_corregimiento: '',
  id_poblado: '',
  id_agencia: '',
  latitud: '',
  longitud: '',
  latlong: '',
  utm: '',
  extension: '',
  distancia_vivienda: '',
  accesibilidad: '',
  tiempo_acceso_verano: '',
  tiempo_acceso_invierno: '',
  infraestructura_almacenamiento: '0',
  administrada_por: '',
  actividades: [],
  terrenos: [],
  entidad_apoyo: [],
  asistencias: [],
  fuente_agua: [],
  observacion: '',
}

const tenencia_finca = {
  tenencia_tierra: '',
  extension: '',
}

const terreno_finca = {
  tipo_terreno: '',
  extension: '',
}

const finca_aseguramiento = {
  tipo_entidad_aseguradora: '',
  activo: '',
}

const finca_financiamiento = {
  tipo_entidad_financiera: '',
  activo: '',
}

const establecimiento = {
  id: '',
  id_enc: '',
  id_finca: '',
  rua: '',
  nombre: '',
  latitud: '',
  longitud: '',
  latlong: '',
  utm: '',
  extension: '',
  estado: '',
  tipo_establecimiento: '',
  observacion: '',
}

const parcela = {
  id: '',
  id_enc: '',
  id_finca: '',
  id_establecimiento: '',
  nombre: '',
  porcentaje: '',
  latitud: '',
  longitud: '',
  latlong: '',
  utm: '',
  estado: '',
  tipo_parcela: '',
  observacion: '',
}

const embarcacion = {
  id: '',
  id_enc: '',
  numero_unico: '',
  nombre: '',
  licencia: '',
  tipo_embarcacion: '',
  sub_tipo_embarcacion: '',
  zona_pesca: '',
}

const preparacion = {
  id: '',
  id_enc: '',
  id_finca: '',
  id_establecimiento: '',
  id_parcela: '',
  coa: '',
  fecha: '',
  anio_agricola: '',
  id_cultivo: '',
  variedad_cultivo: '',
  semilla: '',
  denominacion_varietal: '',
  superficie_preparada: '',
  prima_de_seguro: '',
  tipo_prima_seguro: '',
  financiamiento: '0',
  ley_de_beneficio: '',
  estatus: '',
  observacion: '',
}

const siembra = {
  ID: '',
  ID_ENC: '',
  ID_FINCA: '',
  ID_ESTABLECIMIENTO: '',
  ID_PARCELA: '',
  COA: '',
  ID_CULTIVO: '',
  VARIEDAD: '',
  SEMILLA: '',
  DENOMINACION_VARIETAL: '',
  ID_PROVINCIA: '',
  ID_DISTRITO: '',
  ID_CORREGIMIENTO: '',
  ID_POBLADO: '',
  LATITUD: '',
  LONGITUD: '',
  SUPERFICIE_ACTUAL_SEMBRADA: '',
  SUPERFICIE_ADICIONAL_PROYECTADA: '',
  VOLUMEN_ANUAL_PRODUCCION: '',
  CANTIDAD_SEMILLA: '',
  FECHA_ESTIMADA_COSECHA: '',
  PRIMA_SEGURO_ESTATAL: '',
  PRIMA_SEGURO_PRIVADO: '',
  FINANCIAMIENTO_COOPERATIVA: '',
  FINANCIAMIENTO_ESTATAL: '',
  AUTO_FINANCIAMIENTO: '',
  OTRO_TIPO_FINANCIAMIENTO: '',
  REALIZA_TRAZABILIDAD: '',
  FECHA_SIEMBRA: '',
  ANIO_AGRICOLA: '',
  ley_de_beneficio: '',
  tecnologias: [],
  estatus: '',
  observacion: '',
}

const cosecha = {
  id: '',
  id_enc: '',
  id_finca: '',
  id_establecimiento: '',
  id_parcela: '',
  fecha: '',
  anio_agricola: '',
  ley_de_beneficio: '',
  coa: '',
  id_cultivo: '',
  variedad_cultivo: '',
  semilla: '',
  denominacion_varietal: '',
  superficie_cosechada: '',
  produccion_anual_estimada: '',
  superficie_perdida: '',
  produccion: '',
  cantidad_vendida: '',
  precio_de_venta: '',
  sistemas: [],
  tiempo_produccion: '',
  ingreso_anual: '',
  estatus: '',
  observacion: '',
}

const destino = {
  id_destino: '',
  porcentaje: 0,
  identirubro: '',
  produccion: '',
  comentario: '',
  rubro: '',
}

const pecuario = {
  id: '',
  id_enc: '',
  id_finca: '',
  fecha: '',
  ciclo: 1,
  superficie_para_pasto: '',
  superficie_para_hacerla_pasto: '',
  observacion: '',
}

const produccion_pecuaria = {
  id_actividad_pecuaria: '',
  produccion: '',
  ingreso_anual: '',
  estatus: '',
  cantidad: '',
  ugequivalente: '',
  ugsumespecie: '',
  haespecia: '',
}

const forestal = {
  id: '',
  id_enc: '',
  id_finca: '',
  fecha: '',
  ciclo: 1,
  observacion: '',
}

const produccion_forestal = {
  id_rubro_forestal: '',
  produccion: '',
  superficie_ocupada: '',
  ingreso_anual: '',
  estatus: '',
}

const artesania = {
  id: '',
  id_enc: '',
  id_finca: '',
  fecha: '',
  ciclo: 1,
  descripcion: '',
  ingreso_anual: '',
  produccion: '',
  estatus: '',
  observacion: '',
}

const agroindustria = {
  id: '',
  id_finca: '',
  fecha: '',
  ciclo: 1,
  descripcion: '',
  ingreso_anual: '',
  produccion: '',
  estatus: '',
  observacion: '',
}

const acuicultura = {
  id: '',
  id_enc: '',
  id_finca: '',
  id_establecimiento: '',
  id_parcela: '',
  fecha: '',
  ciclo: 1,
  espejo_de_agua: '',
  observacion: '',
  latitud: '',
  longitud: '',
}

const produccion_acuicola = {
  id_especie_acuicola: '',
  produccion: '',
  ingreso_anual: '',
  estatus: '',
}

const agroturismo = {
  id: '',
  id_enc: '',
  id_finca: '',
  fecha: '',
  ciclo: 1,
  descripcion: '',
  ingreso_anual: '',
  estatus: '',
  observacion: '',
}

const apicultura = {
  id: '',
  id_enc: '',
  id_finca: '',
  fecha: '',
  ciclo: 1,
  descripcion: '',
  colmenos: '',
  observacion: '',
}

const produccion_apicola = {
  id_rubro_apicola: '',
  produccion: '',
  ingreso_anual: '',
  estatus: '',
}

const pesca = {
  id: '',
  id_enc: '',
  id_embarcacion: '',
  fecha: '',
  ciclo: 1,
  observacion: '',
}

const produccion_pesca = {
  id_especie_pesca: '',
  produccion: '',
  ingreso_anual: '',
  estatus: '',
}

const ownership = {
  id_institucion: '',
}

const desastre_detalle = {
  id_desastre_detalle: '',
  id_trxdano: '',
  id_registro: '',
  id_clasificadano: '',
  id_actividadrubro: '',
  unidad: '',
  respuesta: '',
  estado: '',
  cantidaddano: '',
  costo_unit: '',
  mo: '',
  mo_cantidad: '',
  mo_costo_unit: '',
  total_detalle: '',
  total_mo: '',
  total_final: '',
  tipo_entidad_aseguradora: '',
  aseguramiento: '',
  financiamiento: '',
  banco: '',
  describa: '',
}

const danotrx = {
  id_trxdano: '',
  id_registro: '',
  fecha_de_actualizacion: '',
  id_evento: '',
  tipo_evento: '',
  factura_insumo: '',
  factura_compra: '',
  modulo: '',
  tecnico: '',
  numidoneidad: '',
  coordinadorarea: '',
  testigo: '',
  observaciones: '',
  latitud: '',
  longitud: '',
  perdidas_y_costos: [],
}

const formulario_de_productor = {
  ...productor,
  numeros: [{ ...telefono_persona }],
  empresa: { ...empresa },
  ...ownership,
}

const formulario_empresa = {
  ...empresa,
}
const formulario_de_agroturismo = {
  ...agroturismo,
  ...ownership,
  desastres: [],
}

const formulario_de_finca = {
  ...finca,
  tenencias: [{ ...tenencia_finca }],
  terrenos: [{ ...terreno_finca }],
  aseguramientos: [],
  financiamientos: [],
}

const formulario_parcela = {
  ...parcela,
}

const formulario_establecimiento = {
  ...establecimiento,
}

const formulario_preparacion = {
  ...preparacion,
}

const formulario_siembra = {
  ...siembra,
}

const formulario_cosecha = {
  ...cosecha,
  destinos: [{ ...destino }],
  desastres: [],
}

const formulario_agroindustria = {
  ...agroindustria,
  desastres: [],
  destinos: [{ ...destino }],
}

const formulario_artesania = {
  ...artesania,
  destinos: [{ ...destino }],
  desastres: [],
}

const formulario_pecuaria = {
  ...pecuario,
  producciones: [{ ...produccion_pecuaria }],
  destinos: [{ ...destino }],
  desastres: [],
}

const formulario_acuicultura = {
  ...acuicultura,
  producciones: [{ ...produccion_acuicola }],
  destinos: [{ ...destino }],
  desastres: [],
}

const formulario_apicultura = {
  ...apicultura,
  producciones: [{ ...produccion_apicola }],
  destinos: [{ ...destino }],
  desastres: [],
}

const formulario_forestal = {
  ...forestal,
  producciones: [{ ...produccion_forestal }],
  destinos: [{ ...destino }],
  desastres: [],
}

const formulario_embarcacion = {
  ...embarcacion,
}

const formulario_pesca = {
  ...pesca,
  producciones: [{ ...produccion_pesca }],
  destinos: [{ ...destino }],
  desastres: [],
}

export {
  productor,
  empresa,
  telefono_persona,
  familia,
  integrante_familia,
  finca,
  terreno_finca,
  tenencia_finca,
  finca_aseguramiento,
  finca_financiamiento,
  establecimiento,
  parcela,
  preparacion,
  siembra,
  cosecha,
  destino,
  pecuario,
  produccion_pecuaria,
  forestal,
  produccion_forestal,
  artesania,
  agroindustria,
  acuicultura,
  produccion_acuicola,
  agroturismo,
  apicultura,
  produccion_apicola,
  pesca,
  produccion_pesca,
  embarcacion,
  formulario_de_productor,
  formulario_de_agroturismo,
  formulario_de_finca,
  formulario_preparacion,
  formulario_siembra,
  formulario_cosecha,
  formulario_agroindustria,
  formulario_artesania,
  formulario_pecuaria,
  formulario_acuicultura,
  formulario_apicultura,
  formulario_forestal,
  formulario_pesca,
  formulario_parcela,
  formulario_establecimiento,
  formulario_embarcacion,
  formulario_empresa,
  danotrx,
  desastre_detalle,
}
