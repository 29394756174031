<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Información de la preparación</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="preparacion"
            :remove-commit="'removerPreparacion'"
            :record-id="preparacion.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="preparacion.estatus"
            :items="estatus"
            label="Estatus"
            outlined
            disabled
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="preparacion.id_finca"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            label="Finca*"
            :items="fincas"
            item-text="nombre"
            item-value="id"
            outlined
            @input="debounceBuscarRegistroExistente"
          >
            <template slot="item" slot-scope="{ item }">
              <finca-select-item :item="item" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <modal-establecimiento />
          <v-select
            v-model="preparacion.id_establecimiento"
            :no-data-text="$vuetify.noDataText"
            clearable
            outlined
            :items="establecimientosPorId(preparacion.id_finca)"
            item-value="id"
            item-text="rua"
            label="RUA"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <modal-parcela />
          <v-select
            v-model="preparacion.id_parcela"
            :no-data-text="$vuetify.noDataText"
            clearable
            outlined
            :items="parcelasPorId(preparacion.id_finca)"
            item-value="id"
            item-text="nombre"
            label="Parcela"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="preparacion.coa"
            :rules="[r.required, r.int, r.min_num(1), r.max_num(5)]"
            label="Número de COA*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="preparacion.id_cultivo"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="cultivo"
            item-text="descripcion"
            item-value="id"
            label="Rubro*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="preparacion.variedad_cultivo"
            :no-data-text="$vuetify.noDataText"
            :items="variedad_cultivo"
            label="Variedad"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="preparacion.semilla"
            :no-data-text="$vuetify.noDataText"
            :items="semilla"
            label="Tipo de semilla"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="preparacion.denominacion_varietal"
            :items="denominacionVarietalDeCultivo(preparacion.id_cultivo)"
            item-value="denominacion_varietal"
            item-text="denominacion_varietal"
            label="Denominación varietal"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <n-date
            label="Fecha de la preparación*"
            range-type="production"
            :rules="[
              r.required,
              (v) =>
                invalidRelativeDate.status ||
                `Fecha inválida con respecto a la ${invalidRelativeDate.record}.`,
            ]"
            :date.sync="preparacion.fecha"
            @change="debounceBuscarRegistroExistente"
          />
        </v-col>
        <!-- año agricola -->
        <v-col cols="6">
          <v-combobox
            v-model="preparacion.anio_agricola"
            placeholder="####-####"
            :items="aniosAgricolas"
            :rules="[r.required]"
            label="Inserte el año agricola"
            outlined
          >
          </v-combobox>
      <!--     <v-autocomplete
            v-model="preparacion.anio_agricola"
            placeholder="####-####"
            :items="aniosAgricolas"
            :rules="[r.inta]"
            label="Inserte el año agricola"
            outlined
          >
          </v-autocomplete> -->
          <!-- <v-date-picker-years
            v-model="preparacion.anio_agricola"
            label="año agricola"
            :min="preparacion.anio_agricola - 1"
            :max="preparacion.fechaSeleccionada"
          ></v-date-picker-years> -->
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="preparacion.ley_de_beneficio"
            :no-data-text="$vuetify.noDataText"
            :items="lista_Ley"
            item-text="programa"
            item-value="programa"
            label="Ley de beneficio"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="preparacion.superficie_preparada"
            :rules="[
              r.required,
              r.float,
              (v) => !alertar_superficie_mayor || alertar_superficie_mayor,
            ]"
            label="Superficie preparada*"
            suffix="Ha"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="preparacion.tipo_prima_seguro"
            :no-data-text="$vuetify.noDataText"
            :items="tipo_prima_seguro"
            label="Tipo de prima de seguro"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="preparacion.financiamiento"
            :no-data-text="$vuetify.noDataText"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="Financiamiento"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="preparacion.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-status="preparacion.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La preparación ya existe'"
      :description="'Los datos colocados corresponden a una preparación existente, desea cargar la información de esta preparación?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import uuid from 'uuid/v4'
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import FincaSelectItem from '../../components/FincaSelectItem.vue'
import {
  toArray,
  clona,
  elObjetoTienePropiedades,
  isProductionDateValidComparedToRecords,
} from '../../utilidades'
import { formulario_preparacion } from '../../modelos'
import * as rules from '@/utils/validations'
import ModalParcela from '../../components/ModalParcela.vue'
import ModalEstablecimiento from '../../components/ModalEstablecimiento.vue'
import NDate from '../../components/global/NDate.vue'
export default {
  name: 'Preparacion',
  components: {
    FincaSelectItem,
    ModalParcela,
    ModalEstablecimiento,
    NDate,
  },
  data: () => {
    return {
      lista_Ley: [],
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      preparacion: clona(formulario_preparacion),
      alertar_superficie_mayor: '',
      loading: false,
      aniosAgricolas: [],
    }
  },
  computed: {
    ...mapState('listados', [
      'cultivo',
      'variedad_cultivo',
      'semilla',
      'denominacion_varietal',
      'tipo_prima_seguro',
      'sino',
      'estatus',
      'programa_gobierno',
    ]),
    ...mapGetters('registros', [
      'existeRegistroEnState',
      'establecimientosPorId',
      'parcelasPorId',
      'getSumaDeExtensionEnPreparaciones',
      'finca',
      'getMatchingRecords',
    ]),
    ...mapGetters('listados', ['denominacionVarietalDeCultivo']),
    fincas() {
      return toArray(this.$store.state.registros.fincas)
    },
    sumaDeSuperficiesDePreparaciones() {
      return parseInt(
        this.getSumaDeExtensionEnPreparaciones(
          this.preparacion.id,
          this.preparacion.id_finca,
          this.preparacion.coa,
          new Date(this.preparacion.fecha).getFullYear()
        )
      )
    },
    invalidRelativeDate() {
      const siembra =
        this.getMatchingRecords({
          recordName: 'siembras',
          matchValues: [
            { key: 'id_productor', value: this.preparacion.id_productor },
            { key: 'COA', value: this.preparacion.coa },
            { key: 'ID_FINCA', value: this.preparacion.id_finca },
            { key: 'ID_PARCELA', value: this.preparacion.id_parcela },
            { key: 'ID_CULTIVO', value: this.preparacion.id_cultivo },
          ],
        })?.[0] ?? {}
      const cosecha =
        this.getMatchingRecords({
          recordName: 'cosechas',
          matchValues: [
            { key: 'id_productor', value: this.preparacion.id_productor },
            { key: 'coa', value: this.preparacion.coa },
            { key: 'id_finca', value: this.preparacion.id_finca },
            { key: 'id_parcela', value: this.preparacion.id_parcela },
            { key: 'id_cultivo', value: this.preparacion.id_cultivo },
          ],
        })?.[0] ?? {}

      const referenceRecord = {
        record: 'preparacion',
        date: this.preparacion.fecha,
      }
      const compareRecords = [
        { record: 'siembra', date: siembra.FECHA_SIEMBRA, cp: 'isBefore' },
        { record: 'cosecha', date: cosecha.fecha, cp: 'isBefore' },
      ].filter((v) => !!v)

      return isProductionDateValidComparedToRecords(
        referenceRecord,
        compareRecords
      )
    },
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
    'preparacion.superficie_preparada'() {
      this.alertSurfaceOverflow()
    },
  },
  mounted() {
    for (let i = 0; i < this.programa_gobierno.length; i++) {
      if (!this.programa_gobierno[i].indexOf('Ley')) {
        this.lista_Ley.push(this.programa_gobierno[i])
      }
      if (!this.programa_gobierno[i].indexOf('LEY')) {
        this.lista_Ley.push(this.programa_gobierno[i])
      }
    }
    this.$store.commit('ui/updateToolbar', 'Preparación')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.preparacion = clona(formulario_preparacion)
    }
    this.calculaAniosAgricolas()
    this.setDefaultAnioAgricola()
  },
  methods: {
    datepickerAbierto: function () {
      console.log('El datepicker ha sido abierto')
    },
    fechaSeleccionada: function () {
      console.log('Una fecha ha sido seleccionada')
    },
    datepickerCerrado: function () {
      console.log('El datepicker ha sido cerrado')
    },
    alertSurfaceOverflow() {
      this.alertar_superficie_mayor = ''
      if (this.preparacion.id_finca) {
        this.alertar_superficie_mayor =
          this.sumaDeSuperficiesDePreparaciones +
            parseInt(this.preparacion.superficie_preparada) >
          this.finca(this.preparacion.id_finca)?.extension
            ? 'La suma de las superficies preparadas para este año, coa y finca exceden la superficie de la finca'
            : ''
      }
    },
    // genera un id si la preparación no posee uno y registra la preparación en el almacenamiento local
    async guardarYContinuar() {
      try {
        this.loading = true
        this.alertSurfaceOverflow()
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return ''
        }
        if (await this.buscarRegistroExistente()) return

        if (!this.obtenerIdDeRuta()) {
          this.preparacion.id = uuid()
          this.preparacion.created_at = new Date().getTime() / 1000
        } else this.preparacion.updated_at = new Date().getTime() / 1000

        this.$store.dispatch(
          'registros/guardarPreparacion',
          clona(this.preparacion)
        )
        this.$router.push('/ru/preparaciones')
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('preparaciones', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/preparacion/${id}`)
      this.cerrarModalRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState('preparaciones', id)) {
        this.preparacion = this.obtenerRegistroDelState(id)
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.id ? registro.id.toString() : ''
        if (id === this.obtenerIdDeRuta()) return false
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.preparacion.id_productor
        const coa = this.preparacion.coa
        const fecha = this.preparacion.fecha
        const id_finca = this.preparacion.id_finca
        const id_parcela = this.preparacion.id_parcela
        const id_cultivo = this.preparacion.id_cultivo
        const anio_agricola = this.preparacion.anio_agricola
        // required fields to be fulfilled
        if (coa && fecha && id_finca && id_cultivo) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarPreparaciones',
            {
              id_productor,
              coa,
              fecha,
              id_finca,
              id_cultivo,
              id_parcela,
              anio_agricola,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async cargarRegistroDelServer(registro) {
      try {
        await this.$store.dispatch('registros/getPreparacion', registro)
        return true
      } catch (e) {
        return false
      }
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalRegistroExistente() {
      this.modal_registro_existe = false
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    obtenerRegistroDelState(id) {
      return {
        ...formulario_preparacion,
        ...this.$store.state.registros.preparaciones[id],
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
    // Método para calcular los años agrícolas con inicio en 1 de mayo y fin en 30 de abril del año siguiente
    calculaAniosAgricolas() {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth()

      // Si estamos antes de mayo, tomamos el año anterior como inicio
      const startYear = currentMonth < 4 ? currentYear - 1 : currentYear

      for (let i = 0; i < 10; i++) {
        const yearStart = startYear - i
        const yearEnd = yearStart + 1
        this.aniosAgricolas.push(`${yearStart}-${yearEnd}`)
      }
      for (let i = 1; i <= 2; i++) {
        const futureYearStart = startYear + i
        const futureYearEnd = futureYearStart + 1
        this.aniosAgricolas.unshift(`${futureYearStart}-${futureYearEnd}`)
      }
    },
    // Método para establecer el año agrícola por defecto
    setDefaultAnioAgricola() {
      // Verificar si el registro ya tiene un año agrícola definido
      if (!this.preparacion.anio_agricola) {
        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth()
        // Si estamos antes de mayo, tomamos el año anterior como inicio
        const startYear = currentMonth < 4 ? currentYear - 1 : currentYear
        const defaultAnioAgricola = `${startYear}-${startYear + 1}`

        if (this.aniosAgricolas.includes(defaultAnioAgricola)) {
          this.preparacion.anio_agricola = defaultAnioAgricola
        } else {
          this.preparacion.anio_agricola = this.aniosAgricolas[0]
        }
      }
    },
  },
}
</script>
