<template>
  <v-dialog ref="dialog" v-model="dialog" width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        :rules="rules"
        :dense="dense"
        outlined
        append-icon="mdi-calendar"
        readonly
        v-on="on"
      />
    </template>
    <v-date-picker
      first-day-of-week="1"
      scrollable
      no-title
      :value="date"
      :min="min"
      :max="max"
      @input="$emit('update:date', $event)"
      @change="dateChanged"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Fecha',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rangeType: {
      type: String,
      default: 'default',
      validator: (v) => ['production', 'birthdate', 'default'].includes(v),
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapState('listados', ['institucion']),
    formattedDate() {
      const date = moment(this.date)
      return date.isValid() ? date.format('LL') : ''
    },
    min() {
      const mesregistro = this.institucion.find((c) => c.id === 1)
      switch (this.rangeType) {
        case 'birthdate':
          return moment().subtract(150, 'years').toISOString()
        case 'production':
          return moment().subtract(mesregistro.ms, 'months').toISOString()
        default:
          return moment().subtract(150, 'years').toISOString()
      }
    },
    max() {
      switch (this.rangeType) {
        case 'birthdate':
          return moment().subtract(10, 'years').toISOString()
        case 'production':
          return moment().add(1, 'year').toISOString()
        default:
          return moment().add(1, 'year').toISOString()
      }
    },
  },
  watch: {
    date: {
      immediate: true,
      handler(newDate) {
        const date = moment(newDate)
        this.$emit('update:date', date.isValid() ? newDate : '')
      },
    },
  },
  methods: {
    dateChanged() {
      this.dialog = false
      this.$emit('change', this.date)
    },
  },
}
</script>
<style>
.v-data-table td {
  white-space: pre;
}
.v-data-t td {
  white-space: pre;
}
</style>
