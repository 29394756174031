<template>
  <v-container>
    <v-col cols="12"> </v-col>
    <v-row dense>
      <v-col cols="12">
        <div class="text-h6">Proceso SOLO realizado por el Administrador </div>
      </v-col>
      <v-col cols="12">
        <div class="text-h6">Cambio de Cédula Errada </div>
      </v-col>
      <v-col cols="12">
        <div class="font-weight-medium blue-grey--text">
          * Campos requeridos
        </div>
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="12">
            <div class="text-h6">Información del Productor</div>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="productor.id"
              placeholder="#-###-###"
              label="Cédula o Pasaporte del Productor o Representante*"
              append-icon="mdi-account"
              outlined
              @input="debounceBuscarRegistroExistente"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.primer_nombre"
              label="Primer nombre*"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.primer_apellido"
              label="Primer apellido*"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              readonly
              :value="tipo_identif"
              label="Tipo de Persona*"
            />
          </v-col>
           <v-col cols="12">
            <div class="text-h6">Coloque la Cedula Correcta del Productor</div>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="idnuevo"
          placeholder="#-###-###"
          label="Cédula o Pasaporte Correcta del Productor o Representante*"
          append-icon="mdi-account"
          outlined
        />
      </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <save-btn
          :record-id="productor.id"
          :text="'Guardar y Continuar'"
          :loading="loading"
          @click="guardarYContinuar"
        />
      </v-col>
    </v-row>
    <registro-noexistente
      :title="'El productor no existe'"
      :description="'La identificación colocada corresponde a un productor no existente, verifique la información o cree el productor en la sección de Productores en el menú principal.'"
      :modal="modal_registro_existe"
      :loading="modal_registro_existe_loading"
      @cerrarModal="cerrarModalDeRegistroExistente"
    />

  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import {
  formulario_de_productor,
  formulario_empresa,
} from '@/modelos'
import * as rules from '@/utils/validations'
import {
  clona,
  elObjetoTienePropiedades,
} from '../../utilidades'

const existing_empresa = {
  ruc: '',
}
export default {
  name: 'CedulaErrada',
  data: () => {
    return {
      idnuevo:'',
      latlongGPX: '',
      obtenerPosicionVar: true,
      cargarArchivoVar: false,
      archivo: null,
      coordenadaslatlongpx: [],
      poligonoCerrado: false,
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_Ruc: false,
      modal_registro_existe_loading: false,
      //
      tipo_personita: '',
      tipo_identif: '',
      estadito: '',
      sino_leer: '',
      sino_escribir: '',
      //
      tiene_empresa: true,
      registro_existente: {},
      existing_empresa: clona(existing_empresa),
      empresa: clona(formulario_empresa),
      productor: clona(formulario_de_productor),
      loading: false,
      coordenadas: [],
      coordenadasString: '',
      utmString: '',
      mapVisible: false,
    }
  },
  computed: {
    // retorna cierto si existe al menos un número en el arreglo de numeros del productor
    //puedeRemoverNumero() {
    //   return this.productor.numeros.length >= 0
    //},
    puedeRemoverEmpresa() {
      return this.empresa.length >= 0
    },
    /* edad() {
      return edad(this.productor.fecha_nacimiento)
    }, */
    puedeGuardar() {
      return (
        this.obtenerIdDeRuta() === '' ||
        this.obtenerIdDeRuta() === this.empresa.ruc
      )
    },
    ...mapState('listados', [
      'sino',
      'tipo_empresa',
      'pais',
      'sexo',
      'tipo_telefono',
      'organizacion',
      'escolaridad',
      'tipo_organizacion',
      'tipo_identificacion',
      'institucion',
      'sistema_gestion_calidad',
      'actividad',
      'estado',
    ]),
    ...mapGetters('listados', [
      'provincias',
      'distritos',
      'corregimientos',
      'poblados',
      'provinciaPorId',
      'distritoPorId',
      'corregimientoPorId',
      'pobladoPorId',
      'sexoPorId',
      'tipoOrganizacionPorId',
      'programaGobiernoPorId',
      'organizacionPorId',
    ]),
    ...mapGetters('registros', ['productor_actual', 'existeRegistroEnState']),
   // ...mapState('registros', ['productor']),
  },
  watch: {  
    'productor.tipo_empresa'() {
      if (this.productor.tipo_empresa == 2) {
        this.tiene_empresa = true
        this.tipo_identif = 'PERSONA JURIDICA'
      } else if (this.productor.tipo_empresa == 1) {
        this.tiene_empresa = false
        this.tipo_identif = 'PERSONA NATURAL'
      } else {
        this.tiene_empresa = false
        this.tipo_identif = 'PERSONA EXTRANJERA'
      }
    },   
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Corregir Cedula')
  },
  methods: {

    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },

    
    obtenerRegistroDelStateRuc(id) {
      return {
        //...clona(formulario_empresa),
        ...clona(this.$store.state.registros.empresas[id]),
      }
    },
    //busca el productor en el State productores
    obtenerRegistroDelState(id) {
      return {
        //...clona(formulario_de_productor),
        ...clona(this.$store.state.registros.productores[id]),
      }
    },
 
    //Busca Productor en el Server
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 5500),

    //busca al PRODUCTOR del Server y lo carga
    async buscarRegistroExistente() {
      //const registro = await this.obtenerUnRegistroONadaUNO()
      if (this.productor.id) {
          await this.mostrarProductor(this.productor.id)
         
          //carga datos al State
       this.productor = this.obtenerRegistroDelState(this.productor.id)
     
       if (this.productor.tipo_empresa == 2) {
        this.tiene_empresa = true
        this.tipo_identif = 'PERSONA JURIDICA'
       } else if (this.productor.tipo_empresa == 1) {
        this.tiene_empresa = false
        this.tipo_identif = 'PERSONA NATURAL'
       } else {
        this.tiene_empresa = false
        this.tipo_identif = 'PERSONA EXTRANJERA'
       }  
      }

      if (this.productor) {
          //console.log(this.productor)
          return true
      } else {
        this.mostrarModalDeRegistroExistente()
        return false
      }
    },

    async eliminarTodoDe(cool) {
      try {
        this.loading_delete = true
        await this.$store.dispatch(
          'registros/eliminarCedulaerr',
          cool
        )
        this.loading_delete = false
      } catch (e) {
        this.loading_delete = false
      }
    },
    //// SI SE USA ////////////////////////////////////////
    obtenerRegistroDelState(id) {
      return {
        ...clona(formulario_de_productor),
        ...clona(this.$store.state.registros.productores[id]),
      }
    },
     async mostrarProductor(pro) {
      try {
        this.loading_add = true
        //obtemgo todo desde antes
        await this.$store.dispatch('registros/getExpediente', {
          id_productor: String(pro),
          fechaproddesde: '',
          fechaprodhasta: '',
        })

        return true
      } catch (e) {
        console.error('Error al cargar productor:', e)
      } finally {
        this.loading_add = false
      }
    },

    //SOLO para Validar la existencia del RUC en el Server
    async buscarRegistroExistenteRuc() {
      //lista 1 RUC del server por la variable ruc enviada
      const registro = await this.obtenerEmpresasPorRegistro()
    
      if (elObjetoTienePropiedades(registro)) {
        this.mostrarModalDeRegistroExistenteRuc()
        const ruc = registro.id ? registro.id.toString() : ''
        //if (id === this.obtenerIdDeRuta()) {
        if (ruc === this.empresa.ruc) {
          this.mostrarModalDeRegistroExistenteRuc()
          return true
        }
        return false
      } else {
        return false
      }
    },
    debounceBuscarRegistroExistenteRuc: debounce(async function () {
      await this.buscarRegistroExistenteRuc()
    }, 5000),
    /*async obtenerUnRegistroONada() {
      try {
        const num_registro = this.finca.num_registro
        const nombre = this.finca.nombre
        const id_productor = this.finca.id_productor
        const id = this.productor.id
        // required fields to be fulfilled
        if (id) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarProductores',
            { id }
          )
          if (status === 'ok' && data.length === 1) {
            return data[0]
          }
        }
        return {}
      } catch (e) {
        return {}
      }
    },*/
    //ESTE NO Obtiene el PRODUCTOR del Server
    async obtenerUnRegistroONadaUNO() {
      try {
        let pap = this.productor.id
        /* if (!pap) {
          pap = this.empresa.id_productor
          this.productor.id = this.empresa.id_productor
        } else { 
          this.empresa.id_productor = this.productor.id
        }*/
        const id = pap
        if (id) {
          const { status, data } = await this.$store.dispatch(
            'registros/traersolouno',
            { id }
          )
          if (status === 'ok' && data.length > 0) {
            return data[0]
          }
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    //obtiene un RUC existente
    async obtenerEmpresasPorRegistro() {
      try {
        this.existing_empresa = clona(existing_empresa)
        const ruc = this.empresa.ruc
        // required fields to be fulfilled
        if (ruc) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarEmpresas',
            { ruc }
          )
          if (status === 'ok' && data.length > 0) {
            if (data[0].ruc !== this.empresa.ruc) {
              this.existing_empresa = clona({
                ruc: this.empresa.ruc,
              })
            } else {
              this.empresa.ruc = data[0].ruc
              return data[0]
            }
          }
        }
        return {}
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    //NO SE USA
    async obtenerUnRegistroONadaRuc() {
      try {
        //const id_productor = this.empresa.id_productor
        const ruc = this.empresa.ruc
        // Verifica RUC solo regresa un registro del RUC verificado
        if (ruc) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarEmpresas',
            { ruc }
          )
          if (status === 'ok' && data.length > 0) {
            return data[0]
          }
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    //NO SE USA
    async cargarRegistroDelServerRuc(directa) {
      try {
        await this.$store.dispatch('registros/getEmpresa', directa)
        return true
      } catch (e) {
        return false
      }
    },
    //Carga al PRODUCTOR desde el server
    async cargarRegistroDelServer(ppp) {
      try {
        if (!ppp) return false
        this.productor = await this.$store.dispatch(
          'registros/getConsulta',
          ppp
        )
        //this.productor.id = this.empresa.id_productor
        return true
      } catch (e) {
        return false
      }
    },

    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
      this.productor = registro
    },
    // registra el productor en el almacenamiento local, lo coloca como productor actual y pasa a la siguiente pantalla
    async guardarYContinuar() {
      try {
        this.loading = true
  
        this.productor.updated_at = new Date().getTime() / 1000
        this.productor.observacion = this.idnuevo
  
   
       if (this.productor.observacion && this.productor.id) {
          
          await this.$store.dispatch('registros/guardarFincaEmpresa', this.productor)
          
          try {
            this.loading_add = true
             //eliominar el registro anterior en el almacenamiento //////
            await this.eliminarTodoDe(this.productor.id)
           
            await this.$store.dispatch('registros/getExpediente', {
              id_productor: String(this.productor.observacion),
              fechaproddesde: '',
              fechaprodhasta: '',
            })
           
          } catch (e) {
            console.error('Error al cargar productor:', e)
          } finally {
            this.loading_add = false
          }

          //this.$router.push('/ru/productor/cedulaerrada')
          alert('las transacciones existentes con identidad errada ' + this.productor.id + ' fueron cambiados a la identidad ' + this.productor.observacion);
       } else {
        alert('Debe introducir la cédula errada y la cédula correcta')
       }
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    extractAndFormatCoordinates() {
      if (this.coordenadasString.startsWith('POLYGON')) {
        const coords = this.coordenadasString.match(/\(([^())]+)\)/)[1].trim()
        const coordPairs = coords
          .split(',')
          .map((pair) => pair.trim().split(' ').reverse().join(' '))
          .join(' ')
        this.coordenadasString = coordPairs
      } else if (this.coordenadasString.startsWith('POINT')) {
        const coords = this.coordenadasString
          .match(/\(([^())]+)\)/)[1]
          .trim()
          .split(' ')
          .reverse()
          .join(' ')
        this.coordenadasString = coords
      }
    },
    mostrarModalDeRegistroExistenteRuc() {
      this.modal_registro_existe_Ruc = true
    },
    cerrarModalDeRegistroExistenteRuc() {
      this.modal_registro_existe_Ruc = false
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalDeRegistroExistente() {
      this.modal_registro_existe = false
    },
  },
}
</script>

<style scoped>
#mapita {
  height: 300px;
  width: 100%;
  z-index: 0;
  margin-top: 50px;
}
</style>
