<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Información de la siembra</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="siembra"
            :remove-commit="'removerSiembra'"
            :record-id="siembra.ID"
            @removed="$router.go(-1)"
          />
        </v-col>
      <v-row dense>
      <v-col cols="9">
        <div class="font-weight-medium grey--text text--darken-2">
          * Campos requeridos
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="siembra.estatus"
          :items="estatus"
          label="Estatus"
          disabled
        />
      </v-col>
    </v-row>
        <v-col cols="12">
          <v-select
            v-model="siembra.ID_FINCA"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="fincas"
            item-text="nombre"
            item-value="id"
            label="Finca*"
            outlined
            @input="debounceBuscarRegistroExistente"
          >
            <template slot="item" slot-scope="{ item }">
              <finca-select-item :item="item" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <modal-establecimiento />
          <v-select
            v-model="siembra.ID_ESTABLECIMIENTO"
            :no-data-text="$vuetify.noDataText"
            :items="establecimientosPorId(siembra.ID_FINCA)"
            item-value="id"
            item-text="rua"
            label="RUA"
            outlined
            clearable
          ></v-select>
        </v-col>
        <v-col cols="6">
          <modal-parcela />
          <v-select
            v-model="siembra.ID_PARCELA"
            :no-data-text="$vuetify.noDataText"
            :items="parcelasPorId(siembra.ID_FINCA)"
            item-value="id"
            item-text="nombre"
            label="Parcela"
            outlined
            clearable
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="siembra.COA"
            :rules="[r.required, r.int, r.min_num(1), r.max_num(5)]"
            label="Número de COA*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="siembra.ID_CULTIVO"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="cultivo"
            item-text="descripcion"
            item-value="id"
            label="Rubro*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.VARIEDAD"
            :no-data-text="$vuetify.noDataText"
            :items="variedad_cultivo"
            label="Variedad"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.SEMILLA"
            :no-data-text="$vuetify.noDataText"
            :items="semilla"
            label="Tipo de semilla"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="siembra.DENOMINACION_VARIETAL"
            :items="denominacionVarietalDeCultivo(siembra.ID_CULTIVO)"
            item-value="denominacion_varietal"
            item-text="denominacion_varietal"
            label="Denominación varietal"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <n-date
            label="Fecha de la siembra*"
            range-type="production"
            :rules="[
              r.required,
              (v) =>
                invalidRelativeDate.status ||
                `Fecha inválida con respecto a la ${invalidRelativeDate.record}.`,
            ]"
            :date.sync="siembra.FECHA_SIEMBRA"
            @change="debounceBuscarRegistroExistente"
          />
        </v-col>
        <!-- año agricola -->
        <v-col cols="6">
          <v-combobox
            v-model="siembra.ANIO_AGRICOLA"
            :rules="[r.required]"
            :items="aniosAgricolas"
            label="Inserte el año agricola"
            placeholder="####-####"
            outlined
          ></v-combobox>
          <!-- <v-autocomplete
            v-model="siembra.ANIO_AGRICOLA"
            :items="aniosAgricolas"
            :rules="[r.required]"
            label="Inserte el año agricola"
            placeholder="####-####"
            outlined
          ></v-autocomplete> -->
          <!-- <v-date-picker-years
            v-model="siembra.ANIO_AGRICOLA"
            label="año agricola"
            :min="siembra.ANIO_AGRICOLA - 1"
            :max="siembra.FECHA_SIEMBRA"
          ></v-date-picker-years> -->
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.ley_de_beneficio"
            :no-data-text="$vuetify.noDataText"
            :items="lista_Ley"
            item-text="programa"
            item-value="programa"
            label="Ley de beneficio"
            outlined
          />
        </v-col>
        <v-col cols="8" md="6">
          <v-text-field
            v-model.number="siembra.CANTIDAD_SEMILLA"
            :rules="[r.required, r.float]"
            label="Cantidad de semilla*"
            outlined
          >
            <template v-slot:append>
              <unit-picker @transform="siembra.CANTIDAD_SEMILLA = $event" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="8" md="6">
          <v-text-field
            v-model.number="siembra.VOLUMEN_ANUAL_PRODUCCION"
            :rules="[r.required, r.float]"
            label="Producción estimada*"
            outlined
          >
            <template v-slot:append>
              <unit-picker
                @transform="siembra.VOLUMEN_ANUAL_PRODUCCION = $event"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="siembra.SUPERFICIE_ACTUAL_SEMBRADA"
            :rules="[
              r.required,
              r.float,
              (v) => !alertar_superficie_mayor || alertar_superficie_mayor,
            ]"
            label="Superficie sembrada*"
            suffix="Ha"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="siembra.SUPERFICIE_ADICIONAL_PROYECTADA"
            :rules="[r.required, r.float]"
            label="Superficie adicional proyectada*"
            suffix="Ha"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <n-date
            label="Fecha estimada de cosecha*"
            :rules="[
              r.required,
              (v) =>
                validHarvestRelativeDate ||
                'Debe ser mayor que la fecha de la siembra',
            ]"
            :date.sync="siembra.FECHA_ESTIMADA_COSECHA"
            range-type="production"
            @change="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.PRIMA_SEGURO_ESTATAL"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="Prima de seguro estatal*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.PRIMA_SEGURO_PRIVADO"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="Prima de seguro privado*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.FINANCIAMIENTO_ESTATAL"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="Financiamiento estatal*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.FINANCIAMIENTO_COOPERATIVA"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="Financiamiento cooperativa*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.AUTO_FINANCIAMIENTO"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="Auto financiamiento*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="siembra.OTRO_TIPO_FINANCIAMIENTO"
            :rules="[r.max(256)]"
            label="Otro tipo de financiamiento"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.REALIZA_TRAZABILIDAD"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="Realiza control de trazabilidad*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="siembra.tecnologias"
            multiple
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="tecnologia"
            label="Tecnologías*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.ID_PROVINCIA"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="provincias"
            item-text="NOMBRE_PROVINCIA"
            item-value="ID_PROVINCIA"
            label="Provincia*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.ID_DISTRITO"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="distritos(siembra.ID_PROVINCIA)"
            item-text="NOMBRE_DISTRITO"
            item-value="ID_DISTRITO"
            label="Distrito*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.ID_CORREGIMIENTO"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="corregimientos(siembra.ID_DISTRITO)"
            item-text="NOMBRE_CORREGIMIENTO"
            item-value="ID_CORREGIMIENTO"
            label="Corregimiento*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="siembra.ID_POBLADO"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="poblados(siembra.ID_CORREGIMIENTO)"
            item-text="NOMBRE_POBLADO"
            item-value="ID_POBLADO"
            label="Poblado*"
            outlined
          ></v-select>
        </v-col>
         <div class="text-h6">Datos Opcionales
           <button @click="toggleDiv">
           <v-icon>{{ mostrarDiv ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></button>
           <div v-if="mostrarDiv">
             <v-col cols="12">
               <v-btn depressed block class="primary--text" @click="obtenerPosicion">
               <v-icon left>mdi-crosshairs</v-icon>
                  Registrar ubicación actual</v-btn
                >
             </v-col>
              <v-row>
             <v-col cols="6">
               <v-text-field
                 v-model.number="siembra.LONGITUD"
                 :rules="[r.x_coord]"
                 label="Longitud"
                 outlined
               ></v-text-field>
             </v-col>
             <v-col cols="6">
               <v-text-field
                 v-model.number="siembra.LATITUD"
                 :rules="[r.y_coord]"
                 label="Latitud"
                 outlined
               ></v-text-field>
             </v-col>
             </v-row>
           </div>
         </div>
         <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="siembra.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-status="siembra.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La siembra ya existe'"
      :description="'Los datos colocados corresponden a una siembra existente, desea cargar la información de esta siembra?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import uuid from 'uuid/v4'
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import FincaSelectItem from '../../components/FincaSelectItem.vue'
import {
  toArray,
  getCurrentPosition,
  clona,
  elObjetoTienePropiedades,
  isProductionDateValidComparedToRecords,
} from '../../utilidades'
import { formulario_siembra } from '../../modelos'
import * as rules from '@/utils/validations'
import ModalParcela from '../../components/ModalParcela.vue'
import ModalEstablecimiento from '../../components/ModalEstablecimiento.vue'

export default {
  name: 'Siembra',
  components: {
    FincaSelectItem,
    ModalParcela,
    ModalEstablecimiento,
  },
  data: () => {
    return {
      mostrarDiv: false,
      string: 'ley',
      lista_Ley: [],
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      siembra: clona(formulario_siembra),
      alertar_superficie_mayor: '',
      loading: false,
      aniosAgricolas: [],
    }
  },
  computed: {
    ...mapState('listados', [
      'cultivo',
      'variedad_cultivo',
      'semilla',
      'denominacion_varietal',
      'sino',
      'tecnologia',
      'peso',
      'peso_default',
      'lb_to_kg',
      'estatus',
      'programa_gobierno',
    ]),
    ...mapGetters('listados', [
      'provincias',
      'distritos',
      'corregimientos',
      'poblados',
      'denominacionVarietalDeCultivo',
    ]),
    ...mapGetters('registros', [
      'existeRegistroEnState',
      'establecimientosPorId',
      'parcelasPorId',
      'getSumaDeExtensionEnSiembras',
      'finca',
      'getMatchingRecords',
    ]),
    // retorna cierto si existe al menos una tecnología en el arreglo de tecnologías usadas en la siembra
    puedeRemoverTecnologia() {
      return this.siembra.tecnologias.length >= 2
    },
    fincas() {
      return toArray(this.$store.state.registros.fincas)
    },
    sumaDeSuperficiesDeSiembras() {
      return parseInt(
        this.getSumaDeExtensionEnSiembras(
          this.siembra.ID,
          this.siembra.ID_FINCA,
          this.siembra.COA,
          new Date(this.siembra.FECHA_SIEMBRA).getFullYear()
        )
      )
    },
    invalidRelativeDate() {
      const preparacion =
        this.getMatchingRecords({
          recordName: 'preparaciones',
          matchValues: [
            { key: 'id_productor', value: this.siembra.id_productor },
            { key: 'coa', value: this.siembra.COA },
            { key: 'id_finca', value: this.siembra.ID_FINCA },
            { key: 'id_parcela', value: this.siembra.ID_PARCELA },
            { key: 'id_cultivo', value: this.siembra.ID_CULTIVO },
          ],
        })?.[0] ?? {}
      const cosecha =
        this.getMatchingRecords({
          recordName: 'cosechas',
          matchValues: [
            { key: 'id_productor', value: this.siembra.id_productor },
            { key: 'coa', value: this.siembra.COA },
            { key: 'id_finca', value: this.siembra.ID_FINCA },
            { key: 'id_parcela', value: this.siembra.ID_PARCELA },
            { key: 'id_cultivo', value: this.siembra.ID_CULTIVO },
          ],
        })?.[0] ?? {}

      const referenceRecord = {
        record: 'siembra',
        date: this.siembra.FECHA_SIEMBRA,
      }
      const compareRecords = [
        { record: 'preparacion', date: preparacion.fecha, cp: 'isAfter' },
        { record: 'cosecha', date: cosecha.fecha, cp: 'isBefore' },
      ].filter((v) => !!v)
      return isProductionDateValidComparedToRecords(
        referenceRecord,
        compareRecords
      )
    },
    validHarvestRelativeDate() {
      const siembra = new Date(this.siembra.FECHA_SIEMBRA).getTime()
      const estimada = new Date(this.siembra.FECHA_ESTIMADA_COSECHA).getTime()

      return estimada > siembra
    },
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
    'siembra.SUPERFICIE_ACTUAL_SEMBRADA'() {
      this.alertSurfaceOverflow()
    },
    'siembra.ID_PROVINCIA'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.siembra.ID_DISTRITO = ''
        this.siembra.ID_CORREGIMIENTO = ''
        this.siembra.ID_POBLADO = ''
      }
    },
    'siembra.ID_DISTRITO'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.siembra.ID_CORREGIMIENTO = ''
        this.siembra.ID_POBLADO = ''
      }
    },
    'siembra.ID_CORREGIMIENTO'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.siembra.ID_POBLADO = ''
      }
    },
  },
  mounted() {
    for (let i = 0; i < this.programa_gobierno.length; i++) {
      if (!this.programa_gobierno[i].indexOf('Ley')) {
        this.lista_Ley.push(this.programa_gobierno[i])
      }
      if (!this.programa_gobierno[i].indexOf('LEY')) {
        this.lista_Ley.push(this.programa_gobierno[i])
      }
    }
    this.$store.commit('ui/updateToolbar', 'Siembra')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.siembra = clona(formulario_siembra)
    }
    this.calculaAniosAgricolas()
    this.setDefaultAnioAgricola()
  },
  methods: {
    toggleDiv() {
      this.mostrarDiv = !this.mostrarDiv;
    },
    alertSurfaceOverflow() {
      this.alertar_superficie_mayor = ''
      if (this.siembra.ID_FINCA) {
        this.alertar_superficie_mayor =
          this.sumaDeSuperficiesDeSiembras +
            parseInt(this.siembra.SUPERFICIE_ACTUAL_SEMBRADA) >
          this.finca(this.siembra.ID_FINCA)?.extension
            ? 'La suma de las superficies sembradas para este año, coa y finca exceden la superficie de la finca'
            : ''
      }
    },
    // obtiene la posición y la carga a los campos [x, y] del formulario
    async obtenerPosicion() {
      const position = await getCurrentPosition()
      if (position) {
        this.siembra.LONGITUD = position.coords.longitude
        this.siembra.LATITUD = position.coords.latitude
      }
    },
    // genera un id si la siembra no posee uno y registra la siembra en el almacenamiento local
    async guardarYContinuar() {
      try {
        this.loading = true
        this.alertSurfaceOverflow()
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return ''
        }
        if (await this.buscarRegistroExistente()) return

        if (!this.obtenerIdDeRuta()) {
          this.siembra.ID = uuid()
          this.siembra.created_at = new Date().getTime() / 1000
        } else this.siembra.updated_at = new Date().getTime() / 1000

        this.$store.dispatch('registros/guardarSiembra', clona(this.siembra))
        this.$router.push('/ru/cultivos')
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async cargarRegistroExistente() {
      const id = this.registro_existente.ID
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('siembras', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/cultivo/${id}`)
      this.cerrarModalRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState('siembras', id)) {
        this.siembra = this.obtenerRegistroDelState(id)
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.ID ? registro.ID.toString() : ''
        if (id === this.obtenerIdDeRuta()) return false
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.siembra.id_productor
        const coa = this.siembra.COA
        const fecha = this.siembra.FECHA_SIEMBRA
        const id_finca = this.siembra.ID_FINCA
        const id_parcela = this.siembra.ID_PARCELA
        const id_cultivo = this.siembra.ID_CULTIVO
        // required fields to be fulfilled
        if (coa && fecha && id_finca && id_cultivo) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarSiembras',
            {
              id_productor,
              coa,
              fecha,
              id_finca,
              id_cultivo,
              id_parcela,
            }
          )
          if (status === 'ok' && data.length > 1) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async cargarRegistroDelServer(registro) {
      try {
        // especial case
        registro.id = registro.ID
        await this.$store.dispatch('registros/getSiembra', registro)
        return true
      } catch (e) {
        return false
      }
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalRegistroExistente() {
      this.modal_registro_existe = false
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    obtenerRegistroDelState(id) {
      return {
        ...formulario_siembra,
        ...this.$store.state.registros.siembras[id],
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
    // Método para calcular los años agrícolas con inicio en 1 de mayo y fin en 30 de abril del año siguiente
    calculaAniosAgricolas() {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth()

      // Si estamos antes de mayo, tomamos el año anterior como inicio
      const startYear = currentMonth < 4 ? currentYear - 1 : currentYear

      for (let i = 0; i < 10; i++) {
        const yearStart = startYear - i
        const yearEnd = yearStart + 1
        this.aniosAgricolas.push(`${yearStart}-${yearEnd}`)
      }
      for (let i = 1; i <= 2; i++) {
        const futureYearStart = startYear + i
        const futureYearEnd = futureYearStart + 1
        this.aniosAgricolas.unshift(`${futureYearStart}-${futureYearEnd}`)
      }
    },
    // Método para establecer el año agrícola por defecto
    setDefaultAnioAgricola() {
      // Verificar si la siembra ya tiene un año agrícola definido
      if (!this.siembra.ANIO_AGRICOLA) {
        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth()

        // Si estamos antes de mayo, tomamos el año anterior como inicio
        const startYear = currentMonth < 4 ? currentYear - 1 : currentYear
        const defaultAnioAgricola = `${startYear}-${startYear + 1}`

        if (this.aniosAgricolas.includes(defaultAnioAgricola)) {
          this.siembra.ANIO_AGRICOLA = defaultAnioAgricola
        } else {
          this.siembra.ANIO_AGRICOLA = this.aniosAgricolas[0]
        }
      }
    },
  },
}
</script>
<style scoped>
.font-weight-medium {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
