import {
  definirProductor,
  http,
  toArray,
  clona,
  listRecordsKeys,
} from '../utilidades'
import { formulario_de_productor } from '../modelos'

function canReplace() {
  return true
}

// módulo con todo el estado relacionado a los registros realizados en la aplicación (productores, fincas, siembras, cosechas, etc)
export default {
  namespaced: true,
  state: {
    id_productor_actual: '',
    productores: {},
    productoresci: {},
    familias: {},
    fincas: {},
    fincasc: {},
    establecimientos: {},
    parcelas: {},
    embarcaciones: {},
    empresas: {},
    preparaciones: {},
    siembras: {},
    cosechas: {},
    agroturismos: {},
    apiculturas: {},
    acuiculturas: {},
    pescas: {},
    pecuarias: {},
    forestales: {},
    artesanias: {},
    agroindustrias: {},
    establecimientosc: {},
    parcelasc: {},
    embarcacionesc: {},
    empresasc: {},
    preparacionesc: {},
    siembrasc: {},
    cosechasc: {},
    agroturismosc: {},
    apiculturasc: {},
    acuiculturasc: {},
    pescasc: {},
    pecuariasc: {},
    forestalesc: {},
    artesaniasc: {},
    agroindustriasc: {},
    sync_all_progress: 0,
  },
  mutations: {
    // define si el productor es familiar o no y que tipo
    definirTipoProductor(state) {
      const productor = state.id_productor_actual
      state.productor = definirProductor(productor, state)
    },
    // coloca al productor actual de la aplicación
    productorActual(state, id_productor) {
      state.id_productor_actual = id_productor
    },
    // agrega solo al productor y telefono pero No al objeto de productores
    agregarPro(productor) {
      //if (canReplace(state.productores, productor.id)) {
      // fill possible missing information
      productor = {
        ...clona(formulario_de_productor),
        ...clona(productor),
      }
      productor.numeros = [...clona(productor.numeros)]
      //state.productores[productor.id] = clona(productor)
      //state.productores = clona(state.productores)
      //state.id_productor_actual = productor.id
      //}
    },
    // agrega un productor al objeto de productores
    agregarProductor(state, productor) {
      if (canReplace(state.productores, productor.id)) {
        // fill possible missing information
        productor = {
          ...clona(formulario_de_productor),
          ...clona(productor),
        }
        productor.numeros = [...clona(productor.numeros)]
        productor.empresa = {
          ...clona(formulario_de_productor.empresa),
          ...clona(productor.empresa),
        }
        state.productores[productor.id] = clona(productor)
        state.productores = clona(state.productores)
        state.id_productor_actual = productor.id
      }
    },
    // agrega un productor al objeto de productor
    noagregarProductor(state, productor) {
      //if (canReplace(state.productores, productor.id)) {
      // fill possible missing information
      productor = {
        ...clona(formulario_de_productor),
        ...clona(productor),
      }
      productor.numeros = [...clona(productor.numeros)]
      productor.empresa = {
        ...clona(formulario_de_productor.empresa),
        ...clona(productor.empresa),
      }
      state.productoresci[productor.id] = clona(productor)
      state.productoresci = clona(state.productoresci)
      //state.id_productor_actual = productor.id
    },
    // remueve un productor del objeto de productores
    removerConsultaProductor(state, id) {
      delete state.productoresci[id]
      state.productoresci = clona(state.productoresci)
    },
    // remueve un productor del objeto de productores
    removerProductor(state, id) {
      delete state.productores[id]
      state.productores = clona(state.productores)
    },
    // agrega una empresa al objeto de empresas
    agregarEmpresa(state, empresa) {
      if (canReplace(state.empresas, empresa.ruc)) {
        state.empresas[empresa.ruc] = clona(empresa)
        state.empresas = clona(state.empresas)
      }
    },
    // remueve una empresa del objeto de empresas
    removerEmpresa(state, ruc) {
      delete state.empresas[ruc]
      state.empresas = clona(state.empresas)
    },
    // agrega una familia al objeto de familias
    agregarFamilia(state, familia) {
      if (canReplace(state.familias, familia.id_productor)) {
        state.familias[familia.id_productor] = clona(familia)
        state.familias = clona(state.familias)
      }
    },
    noagregarFamilia(state, familia) {
        state.familiasc[familia.id_productor] = clona(familia)
        state.familiasc = clona(state.familiasc)
    },
    // remueve una familia del objeto de familias
    removerFamilia(state, id) {
      delete state.familias[id]
      state.familias = clona(state.familias)
    },
    removerConsultarFamilia(state, id) {
      delete state.familiasc[id]
      state.familiasc = clona(state.familiasc)
    },
    // agrega a la CONSULTA de cada objeto del Expediente
    noagregarFinca(state, finca) {
      state.fincasc[finca.id] = clona(finca)
      state.fincasc = clona(state.fincasc)
    },
    noagregarEstablecimiento(state, establecimiento) {
      state.establecimientosc[establecimiento.id] = clona(establecimiento)
      state.establecimientosc = clona(state.establecimientosc)
    },
    noagregarParcela(state, parcela) {
      state.parcelasc[parcela.id] = clona(parcela)
      state.parcelasc = clona(state.parcelasc)
    },
    noagregarEmbarcacion(state, embarcacion) {
      state.embarcacionesc[embarcacion.id] = clona(embarcacion)
      state.embarcacionesc = clona(state.embarcacionesc)
    },
    noagregarPreparacion(state, preparacion) {
      state.preparacionesc[preparacion.id] = clona(preparacion)
      state.preparacionesc = clona(state.preparacionesc)
    },
    noagregarSiembra(state, siembra) {
      state.siembrasc[siembra.ID] = clona(siembra)
      state.siembrasc = clona(state.siembrasc)
    },
    noagregarCosecha(state, cosecha) {
      state.cosechasc[cosecha.id] = clona(cosecha)
      state.cosechasc = clona(state.cosechasc)
    },
    noagregarPecuaria(state, pecuaria) {
      state.pecuariasc[pecuaria.id] = clona(pecuaria)
      state.pecuariasc = clona(state.pecuariasc)
    },
    noagregarForestal(state, forestal) {
      state.forestalesc[forestal.id] = clona(forestal)
      state.forestalesc = clona(state.forestalesc)
    },
    noagregarArtesania(state, artesania) {
      state.artesaniasc[artesania.id] = clona(artesania)
      state.artesaniasc = clona(state.artesaniasc)
    },
    noagregarAgroindustria(state, agroindustria) {
      state.agroindustriasc[agroindustria.id] = clona(agroindustria)
      state.agroindustriasc = clona(state.agroindustriasc)
    },
    noagregarAgroturismo(state, agroturismo) {
      state.agroturismosc[agroturismo.id] = clona(agroturismo)
      state.agroturismosc = clona(state.agroturismosc)
    },
    noagregarAcuicultura(state, acuicultura) {
      state.acuiculturasc[acuicultura.id] = clona(acuicultura)
      state.acuiculturasc = clona(state.acuiculturasc)
    },
    noagregarPesca(state, pesca) {
      state.pescasc[pesca.id] = clona(pesca)
      state.pescasc = clona(state.pescasc)
    },
    noagregarApicultura(state, apicultura) {
      state.apiculturasc[apicultura.id] = clona(apicultura)
      state.apiculturasc = clona(state.apiculturasc)
    },
    // agrega una finca al objeto de fincas
    agregarFinca(state, finca) {
      if (canReplace(state.fincas, finca.id)) {
        state.fincas[finca.id] = clona(finca)
        state.fincas = clona(state.fincas)
      }
    },
    // remueve una finca del objeto de fincas
    removerFinca(state, id) {
      delete state.fincas[id]
      state.fincas = clona(state.fincas)
    },
    // remueve un productor de consulta en objeto de productores
    removerConsultaFinca(state, id) {
      delete state.fincasc[id]
      state.fincasc = clona(state.fincasc)
    },
    // agrega un establecimiento al objeto de establecimientos
    agregarEstablecimiento(state, establecimiento) {
      if (canReplace(state.establecimientos, establecimiento.id)) {
        state.establecimientos[establecimiento.id] = clona(establecimiento)
        state.establecimientos = clona(state.establecimientos)
      }
    },
    // remueve un establecimiento del objeto de establecimientos
    removerEstablecimiento(state, id) {
      delete state.establecimientos[id]
      state.establecimientos = clona(state.establecimientos)
    },
    removerConsultaEstablecimiento(state, id) {
      delete state.establecimientosc[id]
      state.establecimientosc = clona(state.establecimientosc)
    },
    // agrega una parcela al objeto de parcelas
    agregarParcela(state, parcela) {
      if (canReplace(state.parcelas, parcela.id)) {
        state.parcelas[parcela.id] = clona(parcela)
        state.parcelas = clona(state.parcelas)
      }
    },
    // remueve una parcela del objeto de parcelas
    removerParcela(state, id) {
      delete state.parcelas[id]
      state.parcelas = clona(state.parcelas)
    },
    removerConsultaParcela(state, id) {
      delete state.parcelasc[id]
      state.parcelasc = clona(state.parcelasc)
    },
    // agrega una embarcacion al objeto de embarcaciones
    agregarEmbarcacion(state, embarcacion) {
      if (canReplace(state.embarcaciones, embarcacion.id)) {
        state.embarcaciones[embarcacion.id] = clona(embarcacion)
        state.embarcaciones = clona(state.embarcaciones)
      }
    },
    // remueve una embarcacion del objeto de embarcaciones
    removerEmbarcacion(state, id) {
      delete state.embarcaciones[id]
      state.embarcaciones = clona(state.embarcaciones)
    },
    removerConsultaEmbarcacion(state, id) {
      delete state.embarcacionesc[id]
      state.embarcacionesc = clona(state.embarcacionesc)
    },
    // agrega una preparación al objeto de preparaciones
    agregarPreparacion(state, preparacion) {
      if (canReplace(state.preparaciones, preparacion.id)) {
        state.preparaciones[preparacion.id] = clona(preparacion)
        state.preparaciones = clona(state.preparaciones)
      }
    },
    // remueve una preparación del objeto de preparaciones
    removerPreparacion(state, id) {
      delete state.preparaciones[id]
      state.preparaciones = clona(state.preparaciones)
    },
    removerConsultaPreparacion(state, id) {
      delete state.preparacionesc[id]
      state.preparacionesc = clona(state.preparacionesc)
    },
    // agrega una siembra al objeto de siembras
    agregarSiembra(state, siembra) {
      if (canReplace(state.siembras, siembra.ID)) {
        state.siembras[siembra.ID] = clona(siembra)
        state.siembras = clona(state.siembras)
      }
    },
    // remueve una siembra del objeto de siembras
    removerSiembra(state, id) {
      delete state.siembras[id]
      state.siembras = clona(state.siembras)
    },
    removerConsultaSiembra(state, id) {
      delete state.siembrasc[id]
      state.siembrasc = clona(state.siembrasc)
    },
    // agrega una cosecha al objeto de cosechas
    agregarCosecha(state, cosecha) {
      if (canReplace(state.cosechas, cosecha.id)) {
        state.cosechas[cosecha.id] = clona(cosecha)
        state.cosechas = clona(state.cosechas)
      }
    },
    // remueve una cosecha del objeto de cosechas
    removerCosecha(state, id) {
      delete state.cosechas[id]
      state.cosechas = clona(state.cosechas)
    },
    removerConsultaCosecha(state, id) {
      delete state.cosechasc[id]
      state.cosechasc = clona(state.cosechasc)
    },
    // agrega una producción pecuaria al objeto de producciones pecuarias
    agregarPecuaria(state, pecuaria) {
      if (canReplace(state.pecuarias, pecuaria.id)) {
        state.pecuarias[pecuaria.id] = clona(pecuaria)
        state.pecuarias = clona(state.pecuarias)
      }
    },
    // remueve una producción pecuaria del objeto de producciones pecuarias
    removerPecuaria(state, id) {
      delete state.pecuarias[id]
      state.pecuarias = clona(state.pecuarias)
    },
    removerConsultaPecuaria(state, id) {
      delete state.pecuariasc[id]
      state.pecuariasc = clona(state.pecuariasc)
    },
    // agrega una producción forestal al objeto de producciones forestales
    agregarForestal(state, forestal) {
      if (canReplace(state.forestales, forestal.id)) {
        state.forestales[forestal.id] = clona(forestal)
        state.forestales = clona(state.forestales)
      }
    },
    // remueve una producción forestal del objeto de producciones forestales
    removerForestal(state, id) {
      delete state.forestales[id]
      state.forestales = clona(state.forestales)
    },
    removerConsultaForestal(state, id) {
      delete state.forestalesc[id]
      state.forestalesc = clona(state.forestalesc)
    },
    // agrega una producción artesanal al objeto de producciones artesanales
    agregarArtesania(state, artesania) {
      if (canReplace(state.artesanias, artesania.id)) {
        state.artesanias[artesania.id] = clona(artesania)
        state.artesanias = clona(state.artesanias)
      }
    },
    // remueve una producción artesanal del objeto de producciones artesanales
    removerArtesania(state, id) {
      delete state.artesanias[id]
      state.artesanias = clona(state.artesanias)
    },
    removerConsultaArtesania(state, id) {
      delete state.artesaniasc[id]
      state.artesaniasc = clona(state.artesaniasc)
    },
    // agrega una producción agroindustrial al objeto de producciones agroindustriales
    agregarAgroindustria(state, agroindustria) {
      if (canReplace(state.agroindustrias, agroindustria.id)) {
        state.agroindustrias[agroindustria.id] = clona(agroindustria)
        state.agroindustrias = clona(state.agroindustrias)
      }
    },
    // remueve una producción agroindustrial del objeto de producciones agroindustriales
    removerAgroindustria(state, id) {
      delete state.agroindustrias[id]
      state.agroindustrias = clona(state.agroindustrias)
    },
    removerConsultaAgroindustria(state, id) {
      delete state.agroindustriasc[id]
      state.agroindustriasc = clona(state.agroindustriasc)
    },
    // agrega un agroturismo al objeto de agroturismos
    agregarAgroturismo(state, agroturismo) {
      if (canReplace(state.agroturismos, agroturismo.id)) {
        state.agroturismos[agroturismo.id] = clona(agroturismo)
        state.agroturismos = clona(state.agroturismos)
      }
    },
    // remueve una agroturismo del objeto de agroturismos
    removerAgroturismo(state, id) {
      delete state.agroturismos[id]
      state.agroturismos = clona(state.agroturismos)
    },
    removerConsultaAgroturismo(state, id) {
      delete state.agroturismosc[id]
      state.agroturismosc = clona(state.agroturismosc)
    },
    // agrega una produccion actuícola al objeto de producciones acuícolas
    agregarAcuicultura(state, acuicultura) {
      if (canReplace(state.acuiculturas, acuicultura.id)) {
        state.acuiculturas[acuicultura.id] = clona(acuicultura)
        state.acuiculturas = clona(state.acuiculturas)
      }
    },
    // remueve una produucción acuícola del objeto de producciones acuícolas
    removerAcuicultura(state, id) {
      delete state.acuiculturas[id]
      state.acuiculturas = clona(state.acuiculturas)
    },
    removerConsultaAcuicultura(state, id) {
      delete state.acuiculturasc[id]
      state.acuiculturasc = clona(state.acuiculturasc)
    },
    // agrega una pesca al objeto de pescas
    agregarPesca(state, pesca) {
      if (canReplace(state.pescas, pesca.id)) {
        state.pescas[pesca.id] = clona(pesca)
        state.pescas = clona(state.pescas)
      }
    },
    // remueve una pesca del objeto de pescas
    removerPesca(state, id) {
      delete state.pescas[id]
      state.pescas = clona(state.pescas)
    },
    removerConsultaPesca(state, id) {
      delete state.pescasc[id]
      state.pescasc = clona(state.pescasc)
    },
    // agrega una producción apícola al objeto de apiculutras
    agregarApicultura(state, apicultura) {
      if (canReplace(state.apiculturas, apicultura.id)) {
        state.apiculturas[apicultura.id] = clona(apicultura)
        state.apiculturas = clona(state.apiculturas)
      }
    },
    // remueve una producción apícola del objeto de apicultura
    removerApicultura(state, id) {
      delete state.apiculturas[id]
      state.apiculturas = clona(state.apiculturas)
    },
    removerConsultaApicultura(state, id) {
      delete state.apiculturasc[id]
      state.apiculturasc = clona(state.apiculturasc)
    },
  },
  actions: {
    // prueba que existe una conexión autorizada
    async ping() {
      try {
        await http({ url: '/ping', method: 'get', params: {} })
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    // función reutilizable que agregar un regisro a la base de datos y reemplaza el
    // id generado en el cliente por el id generado por la base de datos
    async guardarRegistro(
      { commit },
      {
        url,
        data: payload,
        remove_commit,
        add_commit,
        id_key = 'id',
        id_enc_key = 'id_enc',
      }
    ) {
      
        
      try {
        
        const { data } = await http({
          url: url,
          method: 'post',
          data: payload,
        })
        
        const status = data ? data.status : undefined
        switch (status) {
          case 'ok':
            commit(remove_commit, payload[id_key])
            if (data.record) payload = data.record
            else {
              payload[id_key] = data.id
              payload[id_enc_key] = data.id_enc
              payload.sincronizado = true
            }
            break
          case 'existing_record':
            payload.sincronizado = false
            payload.estatus = 'R'
            commit('ui/updatedExistingRecordModal', true, { root: true })
            break
          case 'old_record':
            payload.sincronizado = false
            payload.estatus = 'V'
            commit('ui/updatedOldRecordModal', true, { root: true })
            break
          case 'incomplete_record':
            payload.sincronizado = false
            commit(
              'ui/setSnack',
              {
                text: 'Se intentó sincronizar un productor con datos incompletos. Verifique.',
                color: 'error',
                timeout: 10000,
              },
              { root: true }
            )
            break
          default:
            payload.sincronizado = false
            break
        }
      } 
      catch (error) {
        payload.sincronizado = false
        this._vm.$sentry.captureException(error)
      } 
      finally {
        if (add_commit) {
          commit(add_commit, payload);
        } else {
          // Manejo alternativo si add_commit es undefined
        }
        commit('definirTipoProductor');
      }
      
      return payload
    },

 async guardarUTM({ dispatch }, archivoGeo) {
      try {
        await dispatch('guardarRegistro', {
          url: '/satelite',
          data: archivoGeo,
        });
      } catch (error) {
        console.error('Error al guardar archivo GeoJSON:', error);
        throw new Error('No se pudo guardar el archivo GeoJSON');
      }
    },
    async guardarTiff({ dispatch }, tiff) {
      try {
        await dispatch('guardarRegistro', {
          url: '/tiff',
          data: tiff,
        });
      } catch (error) {
        console.error('Error al guardar archivo Geotiff:', error);
        throw new Error('No se pudo guardar el archivo Geotiff');
      }
    },

async getAnos_AgricolasTiff({ dispatch }, data = { ruc: '' }) {
      try {
        const res = await dispatch('listarRegistros', {
          url: '/tiff/listarAnos',
          data,
        })
        console.log('res',res)
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },

async getNombres_AnoAgricolasTiff({ dispatch }, data) {
      console.log('data', data)
      try {
        const res = await dispatch('listarRegistros', {
          url: '/tiff/listarNombres',
          data,
        })
        console.log('resNom',res)
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async listarRegistros(context, { url, data }) {
      try {
        const res = await http({
          url,
          method: 'get',
          params: data,
        })
        return res.data || []
      } catch (error) {
        this._vm.$sentry.captureException(error)
        return []
      }
    },
    async getRegistro({ commit }, { url, data, add_commit }) {
      try {
        const res = await http({
          url,
          method: 'get',
          params: data,
        })
        const registro = res?.data?.data
        if (!registro) return
        commit(add_commit, registro)
        return true
      } catch (error) {
        this._vm.$sentry.captureException(error)
        return false
      }
    },
    // sincroniza todos los registros que no hayan podido ser sincronizados
    async sincronizarTodo({ state, dispatch, rootState }, id_productor = null) {
      if (navigator.onLine) {
        rootState.ui.online = true
        // reinicia el progreso de la sincronización
        state.sync_all_progress = 0

        // guarda productores no sincronizados
        state.sync_all_progress += 1
        const productores = id_productor
          ? [id_productor]
          : Object.keys(state.productores)
        for (const key of productores) {
          if (state.productores[key]?.sincronizado === false) {
            if (!(await dispatch('guardarProductor', state.productores[key])))
              return false
          }
        }

        // guarda fincas no sincronizados
        state.sync_all_progress += 1
        const fincas = listRecordsKeys(
          state.fincas,
          'id_productor',
          id_productor
        )
        for (const key of fincas) {
          if (state.fincas[key]?.sincronizado === false) {
            if (!(await dispatch('guardarFinca', state.fincas[key])))
              return false
          }
        }
        // guarda establecimientos no sincronizados
        state.sync_all_progress += 1
        const establecimientos = listRecordsKeys(
          state.establecimientos,
          'id_productor',
          id_productor
        )
        for (const key of establecimientos) {
          if (state.establecimientos[key]?.sincronizado === false) {
            if (
              !(await dispatch(
                'guardarEstablecimiento',
                state.establecimientos[key]
              ))
            )
              return false
          }
        }

        // guarda parcelas no sincronizadas
        state.sync_all_progress += 1
        const parcelas = listRecordsKeys(state.parcelas, 'id_finca', fincas)
        for (const key of parcelas) {
          if (state.parcelas[key]?.sincronizado === false) {
            if (!(await dispatch('guardarParcela', state.parcelas[key])))
              return false
          }
        }

        // guarda preparaciones no sincronizadas
        state.sync_all_progress += 1
        const preparaciones = listRecordsKeys(
          state.preparaciones,
          'id_finca',
          fincas
        )
        for (const key of preparaciones) {
          if (state.preparaciones[key]?.sincronizado === false) {
            if (
              !(await dispatch('guardarPreparacion', state.preparaciones[key]))
            )
              return false
          }
        }

        // guarda siembras no sincronizadas
        state.sync_all_progress += 1
        const siembras = listRecordsKeys(state.siembras, 'ID_FINCA', fincas)
        for (const key of siembras) {
          if (state.siembras[key]?.sincronizado === false) {
            if (!(await dispatch('guardarSiembra', state.siembras[key])))
              return false
          }
        }

        // guarda cosechas no sincronizadas
        state.sync_all_progress += 1
        const cosechas = listRecordsKeys(state.cosechas, 'id_finca', fincas)
        for (const key of cosechas) {
          if (state.cosechas[key]?.sincronizado === false) {
            if (!(await dispatch('guardarCosecha', state.cosechas[key])))
              return false
          }
        }

        // guarda producciones pecuarias no sincronizadas
        state.sync_all_progress += 1
        const pecuarias = listRecordsKeys(state.pecuarias, 'id_finca', fincas)
        for (const key of pecuarias) {
          if (state.pecuarias[key]?.sincronizado === false) {
            if (!(await dispatch('guardarPecuaria', state.pecuarias[key])))
              return false
          }
        }

        // guarda producciones forestales no sincronizadas
        state.sync_all_progress += 1
        const forestales = listRecordsKeys(state.forestales, 'id_finca', fincas)
        for (const key of forestales) {
          if (state.forestales[key]?.sincronizado === false) {
            if (!(await dispatch('guardarForestal', state.forestales[key])))
              return false
          }
        }

        // guarda producciones artesanales no sincronizadas
        state.sync_all_progress += 1
        const artesanias = listRecordsKeys(state.artesanias, 'id_finca', fincas)
        for (const key of artesanias) {
          if (state.artesanias[key]?.sincronizado === false) {
            if (!(await dispatch('guardarArtesania', state.artesanias[key])))
              return false
          }
        }

        // guarda producciones agroindustriales no sincronizadas
        state.sync_all_progress += 1
        const agroindustrias = listRecordsKeys(
          state.agroindustrias,
          'id_finca',
          fincas
        )
        for (const key of agroindustrias) {
          if (state.agroindustrias[key]?.sincronizado === false) {
            if (
              !(await dispatch(
                'guardarAgroindustria',
                state.agroindustrias[key]
              ))
            )
              return false
          }
        }

        // guarda los registros agroturistas no sincronizados
        state.sync_all_progress += 1
        const agroturismos = listRecordsKeys(
          state.agroturismos,
          'id_finca',
          fincas
        )
        for (const key of agroturismos) {
          if (state.agroturismos[key]?.sincronizado === false) {
            if (
              !(await dispatch('guardarAgroturismo', state.agroturismos[key]))
            )
              return false
          }
        }

        // guarda los registros apícolas no sincronizados
        state.sync_all_progress += 1
        const apiculturas = listRecordsKeys(
          state.apiculturas,
          'id_finca',
          fincas
        )
        for (const key of apiculturas) {
          if (state.apiculturas[key]?.sincronizado === false) {
            if (!(await dispatch('guardarApicultura', state.apiculturas[key])))
              return false
          }
        }

        // guarda los registros de acuiculutra no sincronizados
        state.sync_all_progress += 1
        const acuiculturas = listRecordsKeys(
          state.acuiculturas,
          'id_finca',
          fincas
        )
        for (const key of acuiculturas) {
          if (state.acuiculturas[key]?.sincronizado === false) {
            if (
              !(await dispatch('guardarAcuicultura', state.acuiculturas[key]))
            )
              return false
          }
        }

        // guarda embarcaciones no sincronizadas
        state.sync_all_progress += 1
        const embarcaciones = listRecordsKeys(
          state.embarcaciones,
          'id_productor',
          id_productor
        )
        for (const key of embarcaciones) {
          if (state.embarcaciones[key]?.sincronizado === false) {
            if (
              !(await dispatch('guardarEmbarcacion', state.embarcaciones[key]))
            )
              return false
          }
        }
        // guarda empresas no sincronizadas
        state.sync_all_progress += 1
        const empresas = listRecordsKeys(
          state.empresas,
          'id_productor',
          id_productor
        )
        for (const key of empresas) {
          if (state.empresas[key]?.sincronizado === false) {
            if (!(await dispatch('guardarEmpresa', state.empresas[key])))
              return false
          }
        }

        // guarda los registros de pesca no sincronizados
        state.sync_all_progress += 1
        const pescas = listRecordsKeys(
          state.pescas,
          'id_embarcacion',
          embarcaciones
        )
        for (const key of pescas) {
          if (state.pescas[key]?.sincronizado === false) {
            if (!(await dispatch('guardarPesca', state.pescas[key])))
              return false
          }
        }

        // guarda familias no sincronizadas
        state.sync_all_progress += 1
        const familias = listRecordsKeys(
          state.familias,
          'id_productor',
          id_productor
        )
        for (const key of familias) {
          if (state.familias[key]?.sincronizado === false) {
            if (!(await dispatch('guardarFamilia', state.familias[key])))
              return false
          }
        }

        // reinicia el contador de progreso
        state.sync_all_progress = 0
        return true
      } else {
        rootState.ui.online = false
        return false
      }
    },

    async eliminarCedulaerr(
      { state, commit, rootState },
      id_productor
    ) {
      try {
        // listdo de fincas del productor
        const fincas = []
        // listado de empresas de un productor
        const empresas = []
        // listado de embarcaciones de un productor
        const embarcaciones = []
        // si sigue existiendo conexión a internet, eliminar información del productor
        // del almacenamiento local
        if (navigator.onLine) {
          rootState.ui.online = true
          
          // si el id es el productor, vaciar
          if (state.id_productor_actual === id_productor) {
            commit('productorActual', '')
           // commit('definirTipoProductor')
          }
          // elimina todo lo relaciondo al id del productor local del
          // almacenamiento local
          commit('removerProductor', id_productor)

          // remover fincas del productor
          for (const key in state.fincas) {
            if (state.fincas[key].id_productor === id_productor) {
              // guarda las fincas del productor para posterior eliminación de
              // establecimientos, siembras, preparaciones y cosechas del mismo
              fincas.push(key)
              commit('removerFinca', key)
            }
          }

          // remover embarcaciones del productor
          for (const key in state.embarcaciones) {
            if (state.embarcaciones[key].id_productor === id_productor) {
              embarcaciones.push(key)
              commit('removerEmbarcacion', key)
            }
          }

          // remover empresas del productor
          for (const key in state.empresas) {
            if (state.empresas[key].id_productor === id_productor) {
              empresas.push(key)
              commit('removerEmpresa', key)
            }
          }
          // remover establecimientos del productor
          for (const key in state.establecimientos) {
            if (fincas.includes(String(state.establecimientos[key].id_finca))) {
              commit('removerEstablecimiento', key)
            }
          }
          // remover parcelas del productor
          for (const key in state.parcelas) {
            if (fincas.includes(String(state.parcelas[key].id_finca))) {
              commit('removerParcela', key)
            }
          }
          // remover preparaciones del productor
          for (const key in state.preparaciones) {
            if (fincas.includes(String(state.preparaciones[key].id_finca))) {
              commit('removerPreparacion', key)
            }
          }

          // remover siembras del productor
          for (const key in state.siembras) {
            if (fincas.includes(String(state.siembras[key].ID_FINCA))) {
              commit('removerSiembra', key)
            }
          }

          // remover cosechas del productor
          for (const key in state.cosechas) {
            if (fincas.includes(String(state.cosechas[key].id_finca))) {
              commit('removerCosecha', key)
            }
          }
          // remover familia del productor
          for (const key in state.familias) {
            if (state.familias[key].id_productor === id_productor) {
              commit('removerFamilia', key)
            }
          }
          // remover producción pecuaria del productor
          for (const key in state.pecuarias) {
            if (fincas.includes(String(state.pecuarias[key].id_finca))) {
              commit('removerPecuaria', key)
            }
          }
          // remover producción forestal del productor
          for (const key in state.forestales) {
            if (fincas.includes(String(state.forestales[key].id_finca))) {
              commit('removerForestal', key)
            }
          }
          // remover producción artesanal del productor
          for (const key in state.artesanias) {
            if (fincas.includes(String(state.artesanias[key].id_finca))) {
              commit('removerArtesania', key)
            }
          }
          // remover producción agroindustrial del productor
          for (const key in state.agroindustrias) {
            if (fincas.includes(String(state.agroindustrias[key].id_finca))) {
              commit('removerAgroindustria', key)
            }
          }
          // remover producción apícola del productor
          for (const key in state.apiculturas) {
            if (fincas.includes(String(state.apiculturas[key].id_finca))) {
              commit('removerApicultura', key)
            }
          }
          // remover producción acuícola del productor
          for (const key in state.acuiculturas) {
            if (fincas.includes(String(state.acuiculturas[key].id_finca))) {
              commit('removerAcuicultura', key)
            }
          }
          // remover agroturismo del productor
          for (const key in state.agroturismos) {
            if (fincas.includes(String(state.agroturismos[key].id_finca))) {
              commit('removerAgroturismo', key)
            }
          }
          // remover producción apícola del productor
          for (const key in state.apicultura) {
            if (fincas.includes(String(state.apicultura[key].id_finca))) {
              commit('removerApicultura', key)
            }
          }
          // remover pesca del productor
          for (const key in state.pescas) {
            if (
              embarcaciones.includes(String(state.pescas[key].id_embarcacion))
            ) {
              commit('removerPesca', key)
            }
          }
          //commit('definirTipoProductor')
        } else {
          commit(
            'ui/setSnack',
            {
              text: 'Es necesaria una conexión a internet para remover el expediente de un productor.',
              timeout: 10000,
              color: 'warning',
            },
            { root: true }
          )
          rootState.ui.online = false
        }
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },

    async eliminarConsulta(
      { state, commit, rootState },
      id_productor
    ) {
      try {
        // listdo de fincas del productor
        const fincas = []
        // listado de empresas de un productor
        //const empresas = []
        // listado de embarcaciones de un productor
        const embarcaciones = []
        // si sigue existiendo conexión a internet, eliminar información del productor
        // del almacenamiento local
        if (navigator.onLine) {
          rootState.ui.online = true
          
          // si el id es el productor, vaciar
          if (state.productoresci[productor.id] === id_productor) {
           // commit('productorActual', '')
           // commit('definirTipoProductor')
         
          // elimina todo lo relaciondo al id del productor local del
          // almacenamiento local
          commit('removerConsultaProductor', id_productor)
          }
          // remover fincas del productor
          for (const key in state.fincasc) {
            if (state.fincasc[key].id_productor === id_productor) {
              // guarda las fincas del productor para posterior eliminación de
              // establecimientos, siembras, preparaciones y cosechas del mismo
              fincas.push(key)
              commit('removerConsultaFinca', key)
            }
          }

          // remover embarcaciones del productor
          for (const key in state.embarcacionesc) {
            if (state.embarcacionesc[key].id_productor === id_productor) {
              embarcaciones.push(key)
              commit('removerConsultaEmbarcacion', key)
            }
          }
          // remover establecimientos del productor
          for (const key in state.establecimientosc) {
            if (fincas.includes(String(state.establecimientosc[key].id_finca))) {
              commit('removerConsultaEstablecimiento', key)
            }
          }
          // remover parcelas del productor
          for (const key in state.parcelasc) {
            if (fincas.includes(String(state.parcelasc[key].id_finca))) {
              commit('removerConsultaParcela', key)
            }
          }
          // remover preparaciones del productor
          for (const key in state.preparacionesc) {
            if (fincas.includes(String(state.preparacionesc[key].id_finca))) {
              commit('removerConsultaPreparacion', key)
            }
          }

          // remover siembras del productor
          for (const key in state.siembrasc) {
            if (fincas.includes(String(state.siembrasc[key].ID_FINCA))) {
              commit('removerConsultaSiembra', key)
            }
          }

          // remover cosechas del productor
          for (const key in state.cosechasc) {
            if (fincas.includes(String(state.cosechasc[key].id_finca))) {
              commit('removerConsultaCosecha', key)
            }
          }
         
          // remover producción pecuaria del productor
          for (const key in state.pecuariasc) {
            if (fincas.includes(String(state.pecuariasc[key].id_finca))) {
              commit('removerConsultaPecuaria', key)
            }
          }
          // remover producción forestal del productor
          for (const key in state.forestalesc) {
            if (fincas.includes(String(state.forestalesc[key].id_finca))) {
              commit('removerConsultaForestal', key)
            }
          }
          // remover producción artesanal del productor
          for (const key in state.artesaniasc) {
            if (fincas.includes(String(state.artesaniasc[key].id_finca))) {
              commit('removerConsultaArtesania', key)
            }
          }
          // remover producción agroindustrial del productor
          for (const key in state.agroindustriasc) {
            if (fincas.includes(String(state.agroindustriasc[key].id_finca))) {
              commit('removerConsultaAgroindustria', key)
            }
          }
          // remover producción apícola del productor
          for (const key in state.apiculturasc) {
            if (fincas.includes(String(state.apiculturasc[key].id_finca))) {
              commit('removerConsultaApicultura', key)
            }
          }
          // remover producción acuícola del productor
          for (const key in state.acuiculturasc) {
            if (fincas.includes(String(state.acuiculturasc[key].id_finca))) {
              commit('removerConsultaAcuicultura', key)
            }
          }
          // remover agroturismo del productor
          for (const key in state.agroturismosc) {
            if (fincas.includes(String(state.agroturismosc[key].id_finca))) {
              commit('removerConsultaAgroturismo', key)
            }
          }
          // remover producción apícola del productor
          for (const key in state.apiculturasc) {
            if (fincas.includes(String(state.apiculturasc[key].id_finca))) {
              commit('removerConsultaApicultura', key)
            }
          }
          // remover pesca del productor
          for (const key in state.pescasc) {
            if (
              embarcaciones.includes(String(state.pescasc[key].id_embarcacion))
            ) {
              commit('removerConsultaPesca', key)
            }
          }
          // remover consulta de familia del productor
          /* if (state.familiasc[key].id_productor === id_productor) {
            commit('removerConsultaFamilia', key)
          } */
          //commit('definirTipoProductor')
        } else {
          commit(
            'ui/setSnack',
            {
              text: 'Es necesaria una conexión a internet para que no aparezcan las consultas anteriores de un productor.',
              timeout: 10000,
              color: 'warning',
            },
            { root: true }
          )
          rootState.ui.online = false
        }
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },

    // elimina toda la información local relacionada a un productor
    // siempre y cuando todo haya sido sincronizado
    async eliminarDatosDeProductor(
      { state, commit, dispatch, rootState },
      id_productor
    ) {
      try {
        // listdo de fincas del productor
        const fincas = []
        // listado de empresas de un productor
        const empresas = []
        // listado de embarcaciones de un productor
        const embarcaciones = []
        // si sigue existiendo conexión a internet, eliminar información del productor
        // del almacenamiento local
        if (navigator.onLine) {
          rootState.ui.online = true
          // sincroniza todos los datos actuales en la aplicación, si no se sincronizan todos, detener el proceso de eliminación
          if (!(await dispatch('sincronizarTodo', id_productor))) {
            commit(
              'ui/setSnack',
              {
                text: 'Algunos registros para este productor no se pudieron sincronizar. Para prevenir la eliminación de registros no sincronizados de este expediente, verifique los registros marcados en amarillo.',
                timeout: 20000,
                color: 'warning',
              },
              { root: true }
            )
            return []
          }
          // si el id es el productor actual, vaciar
          if (state.id_productor_actual === id_productor) {
            commit('productorActual', '')
            commit('definirTipoProductor')
          }
          // elimina todo lo relaciondo al id del productor local del
          // almacenamiento local
          commit('removerProductor', id_productor)
          // remover fincas del productor
          for (const key in state.fincas) {
            if (state.fincas[key].id_productor === id_productor) {
              // guarda las fincas del productor para posterior eliminación de
              // establecimientos, siembras, preparaciones y cosechas del mismo
              fincas.push(key)
              commit('removerFinca', key)
            }
          }

          // remover embarcaciones del productor
          for (const key in state.embarcaciones) {
            if (state.embarcaciones[key].id_productor === id_productor) {
              embarcaciones.push(key)
              commit('removerEmbarcacion', key)
            }
          }

          // remover empresas del productor
          for (const key in state.empresas) {
            if (state.empresas[key].id_productor === id_productor) {
              empresas.push(key)
              commit('removerEmpresa', key)
            }
          }
          // remover establecimientos del productor
          for (const key in state.establecimientos) {
            if (fincas.includes(String(state.establecimientos[key].id_finca))) {
              commit('removerEstablecimiento', key)
            }
          }
          // remover parcelas del productor
          for (const key in state.parcelas) {
            if (fincas.includes(String(state.parcelas[key].id_finca))) {
              commit('removerParcela', key)
            }
          }
          // remover preparaciones del productor
          for (const key in state.preparaciones) {
            if (fincas.includes(String(state.preparaciones[key].id_finca))) {
              commit('removerPreparacion', key)
            }
          }

          // remover siembras del productor
          for (const key in state.siembras) {
            if (fincas.includes(String(state.siembras[key].ID_FINCA))) {
              commit('removerSiembra', key)
            }
          }

          // remover cosechas del productor
          for (const key in state.cosechas) {
            if (fincas.includes(String(state.cosechas[key].id_finca))) {
              commit('removerCosecha', key)
            }
          }
          // remover familia del productor
          for (const key in state.familias) {
            if (state.familias[key].id_productor === id_productor) {
              commit('removerFamilia', key)
            }
          }
          // remover producción pecuaria del productor
          for (const key in state.pecuarias) {
            if (fincas.includes(String(state.pecuarias[key].id_finca))) {
              commit('removerPecuaria', key)
            }
          }
          // remover producción forestal del productor
          for (const key in state.forestales) {
            if (fincas.includes(String(state.forestales[key].id_finca))) {
              commit('removerForestal', key)
            }
          }
          // remover producción artesanal del productor
          for (const key in state.artesanias) {
            if (fincas.includes(String(state.artesanias[key].id_finca))) {
              commit('removerArtesania', key)
            }
          }
          // remover producción agroindustrial del productor
          for (const key in state.agroindustrias) {
            if (fincas.includes(String(state.agroindustrias[key].id_finca))) {
              commit('removerAgroindustria', key)
            }
          }
          // remover producción apícola del productor
          for (const key in state.apiculturas) {
            if (fincas.includes(String(state.apiculturas[key].id_finca))) {
              commit('removerApicultura', key)
            }
          }
          // remover producción acuícola del productor
          for (const key in state.acuiculturas) {
            if (fincas.includes(String(state.acuiculturas[key].id_finca))) {
              commit('removerAcuicultura', key)
            }
          }
          // remover agroturismo del productor
          for (const key in state.agroturismos) {
            if (fincas.includes(String(state.agroturismos[key].id_finca))) {
              commit('removerAgroturismo', key)
            }
          }
          // remover producción apícola del productor
          for (const key in state.apicultura) {
            if (fincas.includes(String(state.apicultura[key].id_finca))) {
              commit('removerApicultura', key)
            }
          }
          // remover pesca del productor
          for (const key in state.pescas) {
            if (
              embarcaciones.includes(String(state.pescas[key].id_embarcacion))
            ) {
              commit('removerPesca', key)
            }
          }
          commit('definirTipoProductor')
        } else {
          commit(
            'ui/setSnack',
            {
              text: 'Es necesaria una conexión a internet para remover el expediente de un productor.',
              timeout: 10000,
              color: 'warning',
            },
            { root: true }
          )
          rootState.ui.online = false
        }
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    // guarda un productor en el servidor y en el almacenamiento local
    async guardarProductor({ dispatch }, productor) {
      productor = await dispatch('guardarRegistro', {
        url: '/productor',
        data: productor,
        remove_commit: 'removerProductor',
        add_commit: 'agregarProductor',
      })
      return productor.sincronizado
    },
    // guarda una familia en el servidor y en el almacenamiento local
    async guardarFamilia({ dispatch }, familia) {
      familia = await dispatch('guardarRegistro', {
        url: '/familia',
        data: familia,
        remove_commit: 'removerFamilia',
        add_commit: 'agregarFamilia',
        id_key: 'id_productor',
      })
      return familia.sincronizado
    },
    // guarda una finca en el servidor y en el almacenamiento local
    async guardarFinca({ dispatch, state }, finca) {
      const id_local = finca.id
      finca = await dispatch('guardarRegistro', {
        url: '/finca',
        data: finca,
        remove_commit: 'removerFinca',
        add_commit: 'agregarFinca',
      })

      if (finca.sincronizado === false) return

      for (const key in state.establecimientos) {
        if (state.establecimientos[key].id_finca === id_local) {
          state.establecimientos[key].id_finca = finca.id
        }
      }
      for (const key in state.parcelas) {
        if (state.parcelas[key].id_finca === id_local) {
          state.parcelas[key].id_finca = finca.id
        }
      }
      for (const key in state.preparaciones) {
        if (state.preparaciones[key].id_finca === id_local) {
          state.preparaciones[key].id_finca = finca.id
        }
      }
      for (const key in state.siembras) {
        if (state.siembras[key].ID_FINCA === id_local) {
          state.siembras[key].ID_FINCA = finca.id
        }
      }
      for (const key in state.cosechas) {
        if (state.cosechas[key].id_finca === id_local) {
          state.cosechas[key].id_finca = finca.id
        }
      }
      for (const key in state.acuiculturas) {
        if (state.acuiculturas[key].id_finca === id_local) {
          state.acuiculturas[key].id_finca = finca.id
        }
      }
      for (const key in state.agroindustrias) {
        if (state.agroindustrias[key].id_finca === id_local) {
          state.agroindustrias[key].id_finca = finca.id
        }
      }
      for (const key in state.agroturismos) {
        if (state.agroturismos[key].id_finca === id_local) {
          state.agroturismos[key].id_finca = finca.id
        }
      }
      for (const key in state.artesanias) {
        if (state.artesanias[key].id_finca === id_local) {
          state.artesanias[key].id_finca = finca.id
        }
      }
      for (const key in state.forestales) {
        if (state.forestales[key].id_finca === id_local) {
          state.forestales[key].id_finca = finca.id
        }
      }
      for (const key in state.pecuarias) {
        if (state.pecuarias[key].id_finca === id_local) {
          state.pecuarias[key].id_finca = finca.id
        }
      }
      for (const key in state.apiculturas) {
        if (state.apiculturas[key].id_finca === id_local) {
          state.apiculturas[key].id_finca = finca.id
        }
      }
      return finca.sincronizado
    },
    // guarda un establecimiento en el servidor y en el almacenamiento local
    async guardarEstablecimiento({ dispatch, state }, establecimiento) {
      const id_local = establecimiento.id
      establecimiento = await dispatch('guardarRegistro', {
        url: '/establecimiento',
        data: establecimiento,
        remove_commit: 'removerEstablecimiento',
        add_commit: 'agregarEstablecimiento',
      })

      if (establecimiento.sincronizado === false) return

      for (const key in state.preparaciones) {
        if (state.preparaciones[key].id_establecimiento === id_local) {
          state.preparaciones[key].id_establecimiento = establecimiento.id
        }
      }
      for (const key in state.siembras) {
        if (state.siembras[key].ID_ESTABLECIMIENTO === id_local) {
          state.siembras[key].ID_ESTABLECIMIENTO = establecimiento.id
        }
      }
      for (const key in state.cosechas) {
        if (state.cosechas[key].id_establecimiento === id_local) {
          state.cosechas[key].id_establecimiento = establecimiento.id
        }
      }
      for (const key in state.acuiculturas) {
        if (state.acuiculturas[key].id_establecimiento === id_local) {
          state.acuiculturas[key].id_establecimiento = establecimiento.id
        }
      }
      for (const key in state.agroindustrias) {
        if (state.agroindustrias[key].id_establecimiento === id_local) {
          state.agroindustrias[key].id_establecimiento = establecimiento.id
        }
      }
      for (const key in state.agroturismos) {
        if (state.agroturismos[key].id_establecimiento === id_local) {
          state.agroturismos[key].id_establecimiento = establecimiento.id
        }
      }
      for (const key in state.artesanias) {
        if (state.artesanias[key].id_establecimiento === id_local) {
          state.artesanias[key].id_establecimiento = establecimiento.id
        }
      }
      for (const key in state.forestales) {
        if (state.forestales[key].id_establecimiento === id_local) {
          state.forestales[key].id_establecimiento = establecimiento.id
        }
      }
      for (const key in state.pecuarias) {
        if (state.pecuarias[key].id_establecimiento === id_local) {
          state.pecuarias[key].id_establecimiento = establecimiento.id
        }
      }
      for (const key in state.apiculturas) {
        if (state.apiculturas[key].id_establecimiento === id_local) {
          state.apiculturas[key].id_establecimiento = establecimiento.id
        }
      }
      return establecimiento.sincronizado
    },
    // guarda una siembra en el servidor y en el almacenamiento local
    async guardarSiembra({ dispatch }, siembra) {
      siembra = await dispatch('guardarRegistro', {
        url: '/siembra',
        data: siembra,
        remove_commit: 'removerSiembra',
        add_commit: 'agregarSiembra',
        id_key: 'ID',
        id_enc_key: 'ID_ENC',
      })
      return siembra.sincronizado
    },
    // guarda una embarcacion en el servidor y en el almacenamiento local
    async guardarEmbarcacion({ dispatch, state }, embarcacion) {
      const id_local = embarcacion.id
      embarcacion = await dispatch('guardarRegistro', {
        url: '/embarcacion',
        data: embarcacion,
        remove_commit: 'removerEmbarcacion',
        add_commit: 'agregarEmbarcacion',
      })
      // actualiza el id de las pescas enlazadas a esta embarcacion
      for (const key in state.pescas) {
        if (state.pescas[key].id_embarcacion === id_local) {
          state.pescas[key].id_embarcacion = embarcacion.id
        }
      }
      return embarcacion.sincronizado
    },
    // guarda una empresa en el servidor y en el almacenamiento local
    async guardarEmpresa({ dispatch, state }, empresa) {
      const id_local = empresa.ruc
      empresa = await dispatch('guardarRegistro', {
        url: '/productor/setempre',
        data: empresa,
        remove_commit: 'removerEmpresa',
        add_commit: 'agregarEmpresa',
      })
      // actualiza el id del productor enlazadas a esta empresa
      for (const key in state.empresas) {
        if (state.empresas[key].ruc === id_local) {
          state.empresas[key].id_productor = empresa.id_productor
        }
      }
      return empresa.sincronizado
    },
   //Guarda el CAMBIO de CEDULA ERRADA aplicandolo a todas las Fincas del productor anterior y a Empresa si las hay,
    // guardarFincaEmpresa guarda una empresa en el servidor y en el almacenamiento local
    async guardarFincaEmpresa({ dispatch}, productor) {
      productor = await dispatch('guardarRegistro', {
        url: '/productor/setfincaempre',
        data: productor,
        remove_commit: 'removerProductor',
        add_commit: 'agregarProductor',
      })
      return productor.sincronizado
    },

    // guarda una parcela en el servidor y en el almacenamiento local
    async guardarParcela({ dispatch, state }, parcela) {
      const id_local = parcela.id
      parcela = await dispatch('guardarRegistro', {
        url: '/parcela',
        data: parcela,
        remove_commit: 'removerParcela',
        add_commit: 'agregarParcela',
      })

      if (parcela.sincronizado === false) return

      for (const key in state.preparaciones) {
        if (state.preparaciones[key].id_parcela === id_local) {
          state.preparaciones[key].id_parcela = parcela.id
        }
      }
      for (const key in state.siembras) {
        if (state.siembras[key].ID_PARCELA === id_local) {
          state.siembras[key].ID_PARCELA = parcela.id
        }
      }
      for (const key in state.cosechas) {
        if (state.cosechas[key].id_parcela === id_local) {
          state.cosechas[key].id_parcela = parcela.id
        }
      }
      for (const key in state.acuiculturas) {
        if (state.acuiculturas[key].id_parcela === id_local) {
          state.acuiculturas[key].id_parcela = parcela.id
        }
      }
      for (const key in state.agroindustrias) {
        if (state.agroindustrias[key].id_parcela === id_local) {
          state.agroindustrias[key].id_parcela = parcela.id
        }
      }
      for (const key in state.agroturismos) {
        if (state.agroturismos[key].id_parcela === id_local) {
          state.agroturismos[key].id_parcela = parcela.id
        }
      }
      for (const key in state.artesanias) {
        if (state.artesanias[key].id_parcela === id_local) {
          state.artesanias[key].id_parcela = parcela.id
        }
      }
      for (const key in state.forestales) {
        if (state.forestales[key].id_parcela === id_local) {
          state.forestales[key].id_parcela = parcela.id
        }
      }
      for (const key in state.pecuarias) {
        if (state.pecuarias[key].id_parcela === id_local) {
          state.pecuarias[key].id_parcela = parcela.id
        }
      }
      for (const key in state.apiculturas) {
        if (state.apiculturas[key].id_parcela === id_local) {
          state.apiculturas[key].id_parcela = parcela.id
        }
      }
      return parcela.sincronizado
    },
    // guarda la información acuícola en el servidor y en el almacenamiento local
    async guardarAcuicultura({ dispatch }, acuicultura) {
      acuicultura = await dispatch('guardarRegistro', {
        url: '/acuicultura',
        data: acuicultura,
        remove_commit: 'removerAcuicultura',
        add_commit: 'agregarAcuicultura',
      })
      return acuicultura.sincronizado
    },
    // guarda una preparación en el servidor y en el almacenamiento local
    async guardarPreparacion({ dispatch }, preparacion) {
      preparacion = await dispatch('guardarRegistro', {
        url: '/preparacion',
        data: preparacion,
        remove_commit: 'removerPreparacion',
        add_commit: 'agregarPreparacion',
      })
      return preparacion.sincronizado
    },
    // guarda una cosecha en el servidor y en el almacenamiento local
    async guardarCosecha({ dispatch }, cosecha) {
      cosecha = await dispatch('guardarRegistro', {
        url: '/cosecha',
        data: cosecha,
        remove_commit: 'removerCosecha',
        add_commit: 'agregarCosecha',
      })
      return cosecha.sincronizado
    },
    // guarda la información de agroturismo en el servidor y en el almacenamiento local
    async guardarAgroturismo({ dispatch }, agroturismo) {
      agroturismo = await dispatch('guardarRegistro', {
        url: '/agroturismo',
        data: agroturismo,
        remove_commit: 'removerAgroturismo',
        add_commit: 'agregarAgroturismo',
      })
      return agroturismo.sincronizado
    },
    // guarda una producción agroindustrial en el servidor y en el almacenamiento local
    async guardarAgroindustria({ dispatch }, agroindustria) {
      agroindustria = await dispatch('guardarRegistro', {
        url: '/agroindustria',
        data: agroindustria,
        remove_commit: 'removerAgroindustria',
        add_commit: 'agregarAgroindustria',
      })
      return agroindustria.sincronizado
    },
    // guarda la información apícola en el servidor y en el almacenamiento local
    async guardarApicultura({ dispatch }, apicultura) {
      apicultura = await dispatch('guardarRegistro', {
        url: '/apicultura',
        data: apicultura,
        remove_commit: 'removerApicultura',
        add_commit: 'agregarApicultura',
      })
      return apicultura.sincronizado
    },
    // guarda una producción artesanal en el servidor y en el almacenamiento local
    async guardarArtesania({ dispatch }, artesania) {
      artesania = await dispatch('guardarRegistro', {
        url: '/artesania',
        data: artesania,
        remove_commit: 'removerArtesania',
        add_commit: 'agregarArtesania',
      })
      return artesania.sincronizado
    },
    // guarda una producción forestal en el servidor y en el almacenamiento local
    async guardarForestal({ dispatch }, forestal) {
      forestal = await dispatch('guardarRegistro', {
        url: '/forestal',
        data: forestal,
        remove_commit: 'removerForestal',
        add_commit: 'agregarForestal',
      })
      return forestal.sincronizado
    },
    // guarda una producción pecuaria en el servidor y en el almacenamiento local
    async guardarPecuaria({ dispatch }, pecuaria) {
      pecuaria = await dispatch('guardarRegistro', {
        url: '/pecuaria',
        data: pecuaria,
        remove_commit: 'removerPecuaria',
        add_commit: 'agregarPecuaria',
      })
      return pecuaria.sincronizado
    },
    // guarda la información de pesca en el servidor y en el almacenamiento local
    async guardarPesca({ dispatch }, pesca) {
      pesca = await dispatch('guardarRegistro', {
        url: '/pesca',
        data: pesca,
        remove_commit: 'removerPesca',
        add_commit: 'agregarPesca',
      })
      return pesca.sincronizado
    },
    async listarProductores(
      { dispatch },
      data = { id: '', nombre: '', apellido: '', ruc: '' }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/productor/listar',
        data,
      })
      return res
    },

    async listarFincas(
      { dispatch },
      data = { num_registro: '', nombre: '', id_productor: '' }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/finca/listar',
        data,
      })
      return res
    },


    async listarParcelas(
      { dispatch },
      data = { id_finca: '', nombre: '', id_productor: '' }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/parcela/listar',
        data,
      })
      return res
    },
    async listarEstablecimientos(
      { dispatch },
      data = { id_finca: '', nombre: '', rua: '' }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/establecimiento/listar',
        data,
      })
      return res
    },
    async listarPreparaciones(
      { dispatch },
      data = {
        id_productor: '',
        coa: '',
        fecha: '',
        id_finca: '',
        id_rubro: '',
        id_parcela: '',
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/preparacion/listar',
        data,
      })
      return res
    },
    async listarSiembras(
      { dispatch },
      data = {
        id_productor: '',
        coa: '',
        fecha: '',
        id_finca: '',
        id_rubro: '',
        id_parcela: '',
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/siembra/listar',
        data,
      })
      return res
    },
    async listarCosechas(
      { dispatch },
      data = {
        id_productor: '',
        coa: '',
        fecha: '',
        id_finca: '',
        id_rubro: '',
        id_parcela: '',
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/cosecha/listar',
        data,
      })
      return res
    },
    async listarAgroindustrias(
      { dispatch },
      data = {
        id_productor: '',
        ciclo: '',
        fecha: '',
        id_finca: '',
        id_parcela: '',
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/agroindustria/listar',
        data,
      })
      return res
    },
    async listarAgroturismos(
      { dispatch },
      data = {
        id_productor: '',
        ciclo: '',
        fecha: '',
        id_finca: '',
        id_parcela: '',
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/agroturismo/listar',
        data,
      })
      return res
    },
    async listarArtesanias(
      { dispatch },
      data = {
        id_productor: '',
        ciclo: '',
        fecha: '',
        id_finca: '',
        id_parcela: '',
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/artesania/listar',
        data,
      })
      return res
    },
    async listarPecuarias(
      { dispatch },
      data = {
        id_productor: '',
        ciclo: '',
        fecha: '',
        id_finca: '',
        id_parcela: '',
        id_rubros: [],
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/pecuaria/listar',
        data,
      })
      return res
    },
    async listarAcuiculturas(
      { dispatch },
      data = {
        id_productor: '',
        ciclo: '',
        fecha: '',
        id_finca: '',
        id_parcela: '',
        id_rubros: [],
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/acuicultura/listar',
        data,
      })
      return res
    },
    async listarApiculturas(
      { dispatch },
      data = {
        id_productor: '',
        ciclo: '',
        fecha: '',
        id_finca: '',
        id_parcela: '',
        id_rubros: [],
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/apicultura/listar',
        data,
      })
      return res
    },
    async listarForestales(
      { dispatch },
      data = {
        id_productor: '',
        ciclo: '',
        fecha: '',
        id_finca: '',
        id_parcela: '',
        id_rubros: [],
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/forestal/listar',
        data,
      })
      return res
    },
    async listarPescas(
      { dispatch },
      data = {
        id_productor: '',
        ciclo: '',
        fecha: '',
        id_embarcacion: '',
        id_rubros: [],
      }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/pesca/listar',
        data,
      })
      return res
    },
    async listarEmbarcaciones(
      { dispatch },
      data = { id_productor: '', nombre: '' }
    ) {
      const res = await dispatch('listarRegistros', {
        url: '/embarcacion/listar',
        data,
      })
      return res
    },
    async listarEmpresas({ dispatch }, data = { ruc: '' }) {
      const res = await dispatch('listarRegistros', {
        url: '/productor/listemp',
        data,
      })
      return res
    },
    // trae toda la información de un productor y la coloca en el almacenamiento local
    //async getExpediente({ commit, dispatch }, id_productor) {
    async getExpediente(
      { commit, dispatch },
      { id_productor, fechaproddesde, fechaprodhasta }
    ) {
      try {
        // sincroniza toda la información actual de la aplicación
        //alert(id_productor, ' ', fechaproddesde)
        /*console.log(
          'getexpediente=' + id_productor + fechaproddesde + fechaprodhasta
        )*/
        if (!(await dispatch('sincronizarTodo', id_productor))) {
          commit(
            'ui/setSnack',
            {
              text: 'Algunos registros de este productor no se pudieron sincronizar. Para evitar reemplazar registros no sincronizados, revise aquellos marcados en amarillo.',
              timeout: 20000,
              color: 'warning',
            },
            { root: true }
          )
          return []
        }
        const response = await http({
          url: '/productor/expediente',
          method: 'get',
          params: {
            id: id_productor,
            fechaproddesde: fechaproddesde,
            fechaprodhasta: fechaprodhasta,
          },
        })

        // asigna los datos recibidos del servidor a los diferentes
        // módulos de la aplicación
        const prodres = response.data.productor
        const productor = {
          ...clona(formulario_de_productor),
          ...clona(prodres),
        }
        productor.numeros = [...clona(prodres.numeros)]
        productor.empresa = {
          ...clona(formulario_de_productor.empresa),
          ...clona(prodres.empresa),
        }
        commit('agregarProductor', productor)

        const empresas = response.data.empresas
        for (const empresa of empresas) {
          commit('agregarEmpresa', empresa)
        }
        const familia = response.data.familia
        commit('agregarFamilia', familia)

        const fincas = response.data.fincas
        for (const finca of fincas) {
          commit('agregarFinca', finca)
        }
        const establecimientos = response.data.establecimientos
        for (const establecimiento of establecimientos) {
          commit('agregarEstablecimiento', establecimiento)
        }
        const parcelas = response.data.parcelas
        for (const parcela of parcelas) {
          commit('agregarParcela', parcela)
        }
        const embarcaciones = response.data.embarcaciones
        for (const embarcacion of embarcaciones) {
          commit('agregarEmbarcacion', embarcacion)
        }
        const preparaciones = response.data.preparaciones
        for (const preparacion of preparaciones) {
          commit('agregarPreparacion', preparacion)
        }
        const siembras = response.data.siembras
        for (const siembra of siembras) {
          commit('agregarSiembra', siembra)
        }
        const cosechas = response.data.cosechas
        for (const cosecha of cosechas) {
          commit('agregarCosecha', cosecha)
        }
        const acuiculturas = response.data.acuiculturas
        for (const acuicultura of acuiculturas) {
          commit('agregarAcuicultura', acuicultura)
        }
        const agroturismos = response.data.agroturismos
        for (const agroturismo of agroturismos) {
          commit('agregarAgroturismo', agroturismo)
        }
        const agroindustriales = response.data.agroindustriales
        for (const agroindustria of agroindustriales) {
          commit('agregarAgroindustria', agroindustria)
        }
        const apiculturas = response.data.apiculturas
        for (const apicultura of apiculturas) {
          commit('agregarApicultura', apicultura)
        }
        const artesanias = response.data.artesanias
        for (const artesania of artesanias) {
          commit('agregarArtesania', artesania)
        }
        const forestales = response.data.forestales
        for (const forestal of forestales) {
          commit('agregarForestal', forestal)
        }
        const pecuarias = response.data.pecuarias
        for (const pecuaria of pecuarias) {
          commit('agregarPecuaria', pecuaria)
        }
        const pescas = response.data.pescas
        for (const pesca of pescas) {
          commit('agregarPesca', pesca)
        }
        commit('productorActual', productor.id)
        commit('definirTipoProductor')
        return true
      } catch (error) {
        this._vm.$sentry.captureException(error)
        return false
      }
    },

    async getConsulta(
      { commit },
      { id_productor, fechaproddesde, fechaprodhasta }
    ) {
      try {
        if (!id_productor) return []


        //console.log(fechaproddesde)
        const response = await http({
          url: '/productor/expediente',
          method: 'get',
          params: {
            id: id_productor,
            fechaproddesde: fechaproddesde,
            fechaprodhasta: fechaprodhasta,
          },
        })
        // asigna los datos recibidos del servidor a los diferentes
        // módulos de la aplicación
        //                    response.data. viene del server
        const productoresci = response.data.productor
        const productor = {
          ...clona(formulario_de_productor),
          ...clona(productoresci),
        }
        productor.numeros = [...clona(productoresci.numeros)]
        productor.empresa = {
          ...clona(formulario_de_productor.empresa),
          ...clona(productoresci.empresa),
        }
        commit('noagregarProductor', productor)

       /*  const empresasc = response.data.empresas
        for (const empresa of empresasc) {
          commit('noagregarEmpresa', empresa)
        }
        const familiac = response.data.familia
        commit('noagregarFamilia', familia)
 */
        const fincasc = response.data.fincas
        for (const finca of fincasc) {
          commit('noagregarFinca', finca)
        }
        //
        const establecimientosc = response.data.establecimientos
        for (const establecimiento of establecimientosc) {
          commit('noagregarEstablecimiento', establecimiento)
        }
        const parcelasc = response.data.parcelas
        for (const parcela of parcelasc) {
          commit('noagregarParcela', parcela)
        }
        const embarcacionesc = response.data.embarcaciones
        for (const embarcacion of embarcacionesc) {
          commit('noagregarEmbarcacion', embarcacion)
        }
        const preparacionesc = response.data.preparaciones
        for (const preparacion of preparacionesc) {
          commit('noagregarPreparacion', preparacion)
        }
        const siembrasc = response.data.siembras
        for (const siembra of siembrasc) {
          commit('noagregarSiembra', siembra)
        }
        const cosechasc = response.data.cosechas
        for (const cosecha of cosechasc) {
          commit('noagregarCosecha', cosecha)
        }
        const acuiculturasc = response.data.acuiculturas
        for (const acuicultura of acuiculturasc) {
          commit('noagregarAcuicultura', acuicultura)
        }
        const agroturismosc = response.data.agroturismos
        for (const agroturismo of agroturismosc) {
          commit('noagregarAgroturismo', agroturismo)
        }
        const agroindustrialesc = response.data.agroindustriales
        for (const agroindustria of agroindustrialesc) {
          commit('noagregarAgroindustria', agroindustria)
        }
        const apiculturasc = response.data.apiculturas
        for (const apicultura of apiculturasc) {
          commit('noagregarApicultura', apicultura)
        }
        const artesaniasc = response.data.artesanias
        for (const artesania of artesaniasc) {
          commit('noagregarArtesania', artesania)
        }
        const forestalesc = response.data.forestales
        for (const forestal of forestalesc) {
          commit('noagregarForestal', forestal)
        }
        const pecuariasc = response.data.pecuarias
        for (const pecuaria of pecuariasc) {
          commit('noagregarPecuaria', pecuaria)
        }
        const pescasc = response.data.pescas
        for (const pesca of pescasc) {
          commit('noagregarPesca', pesca)
        }
        //commit('productorActual', productor.id)
        //commit('definirTipoProductor')
        return response
      } catch (error) {
        this._vm.$sentry.captureException(error)
        return false
      }
    },
    //Obtiene listado de fincas de un productor para ingresarlo en la lista
    async getFinquita({ state }, id_productor) {
      try {
        if (!id_productor) return []
        const fincasc = listRecordsKeys(
          state.fincasc,
          'id_productor',
          id_productor
        )
        for (const key in state.fincasc) {
          const finca = state.fincasc[key]
          if (finca.id_productor === id_productor) {
            fincasc.push(String(key))
          }
        }
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getDefinicion(context, payload = { id: '' }) {
      try {
        if (!payload) return []
        const definition = await http({
          url: '/productor/definir',
          method: 'get',
          params: payload,
        })
        return definition.data
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async traersolouno(
      { dispatch },
      data = { id: '', nombre: '', apellido: '' }
    ) {
      try {
        if (!data) return []
        const res = await dispatch('listarRegistros', {
          url: '/productor/solo',
          data,
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    //Solo cargael registro de un productor y su telefono
    async getPro({ dispatch }, data = { id: '', nombre: '', apellido: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('listarRegistros', {
          url: '/productor/getProfromRequest',
          data,
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },

    async getProductor({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/productor',
          data,
          add_commit: 'agregarProductor',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getProduco({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/productor',
          data,
          add_commit: 'noagregarProductor',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getFamilia({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/productor/familia',
          data,
          add_commit: 'agregarFamilia',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getFamilias({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/productor/familia',
          data,
          add_commit: 'noagregarFamilia',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    //obtiene una finca de un productor para mostrarlo en el formulario
    async getFinca({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/finca',
          data,
          add_commit: 'agregarFinca',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    //obtiene una finca de un productor para mostrarlo en la consulta
    async getFincas({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/finca',
          data,
          add_commit: 'noagregarFinca',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getParcela({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/parcela',
          data,
          add_commit: 'agregarParcela',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getParcelas({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/parcela',
          data,
          add_commit: 'noagregarParcela',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getEstablecimiento({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/establecimiento',
          data,
          add_commit: 'agregarEstablecimiento',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getPreparacion({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/preparacion',
          data,
          add_commit: 'agregarPreparacion',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getPreparaciones({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/preparacion',
          data,
          add_commit: 'noagregarPreparacion',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getSiembra({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/siembra',
          data,
          add_commit: 'agregarSiembra',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getSiembras({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/siembra',
          data,
          add_commit: 'noagregarSiembra',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getCosecha({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/cosecha',
          data,
          add_commit: 'agregarCosecha',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getCosechas({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/cosecha',
          data,
          add_commit: 'noagregarCosecha',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getAgroindustria({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/agroindustria',
          data,
          add_commit: 'agregarAgroindustria',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getAgroturismo({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/agroturismo',
          data,
          add_commit: 'agregarAgroturismo',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getAgroindustrias({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/agroindustria',
          data,
          add_commit: 'noagregarAgroindustria',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getAgroturismos({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/agroturismo',
          data,
          add_commit: 'noagregarAgroturismo',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getArtesania({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/artesania',
          data,
          add_commit: 'agregarArtesania',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getPecuaria({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/pecuaria',
          data,
          add_commit: 'agregarPecuaria',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getAcuicultura({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/acuicultura',
          data,
          add_commit: 'agregarAcuicultura',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getApicultura({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/apicultura',
          data,
          add_commit: 'agregarApicultura',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getForestal({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/forestal',
          data,
          add_commit: 'agregarForestal',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getPecuarias({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/pecuaria',
          data,
          add_commit: 'noagregarPecuaria',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getAcuiculturas({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/acuicultura',
          data,
          add_commit: 'noagregarAcuicultura',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getApiculturas({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/apicultura',
          data,
          add_commit: 'noagregarApicultura',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getForestales({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/forestal',
          data,
          add_commit: 'noagregarForestal',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getArtesanias({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/artesania',
          data,
          add_commit: 'noagregarArtesania',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getPesca({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/pesca',
          data,
          add_commit: 'agregarPesca',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getPescas({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/pesca',
          data,
          add_commit: 'noagregarPesca',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getEmbarcacion({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/embarcacion',
          data,
          add_commit: 'agregarEmbarcacion',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    async getEmbarcaciones({ dispatch }, data = { id: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/embarcacion',
          data,
          add_commit: 'noagregarEmbarcacion',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
    //Obtiene 1 RUC
    async getEmpresa({ dispatch }, data = { ruc: '' }) {
      try {
        if (!data) return []
        const res = await dispatch('getRegistro', {
          url: '/productor/getempre',
          data,
          add_commit: 'agregarEmpresa',
        })
        return res
      } catch (error) {
        this._vm.$sentry.captureException(error)
      }
    },
  },
  getters: {
    getNombreDeFinca: (state) => (id_finca) => {
      if (!id_finca) return '(no posee)'
      id_finca = String(id_finca)

      const exists = Object.prototype.hasOwnProperty.call(
        state.fincas,
        id_finca
      )
      if (exists) {
        return state.fincas[id_finca].nombre ?? '(sin nombre)'
      }
      return '(no disponible)'
    },
    getNombreDeFincas: (state) => (id_finca) => {
      if (!id_finca) return '(no posee)'
      id_finca = String(id_finca)

      const exists = Object.prototype.hasOwnProperty.call(
        state.fincasc,
        id_finca
      )
      if (exists) {
        return state.fincasc[id_finca].nombre ?? '(sin nombre)'
      }
      return '(no disponible)'
    },
    getRucDeFinca: (state) => (id_finca) => {
      if (!id_finca) return '(no posee)'
      id_finca = String(id_finca)

      const exists = Object.prototype.hasOwnProperty.call(
        state.fincasc,
        id_finca
      )
      if (exists) {
        return state.fincasc[id_finca].ruc ?? '(sin ruc)'
      }
      return '(no disponible)'
    },
    getNombreDeParcela: (state) => (id_parcela) => {
      if (!id_parcela) return '(no posee)'
      id_parcela = String(id_parcela)

      const exists = Object.prototype.hasOwnProperty.call(
        state.parcelas,
        id_parcela
      )
      if (exists) {
        return state.parcelas[id_parcela].nombre ?? '(sin nombre)'
      }
      return '(no disponible)'
    },
    getSumaDeExtensionEnPreparaciones:
      (state) => (id_preparacion, id_finca, coa, year) => {
        const suma = toArray(state.preparaciones)
          .filter((prep) => {
            const state_year = new Date(prep.fecha).getFullYear()
            return (
              prep.id_finca === id_finca &&
              prep.coa === coa &&
              state_year === year &&
              prep.id !== id_preparacion
            )
          })
          .map((prep) => parseFloat(prep.superficie_preparada))
          .reduce((acum, superficie) => {
            acum += superficie
            return acum
          }, 0)
        return suma
      },
    getSumaDeExtensionEnSiembras:
      (state) => (id_siembra, id_finca, coa, year) => {
        const suma = toArray(state.siembras)
          .filter((siembra) => {
            const state_year = new Date(siembra.FECHA_SIEMBRA).getFullYear()
            return (
              siembra.ID_FINCA === id_finca &&
              siembra.COA === coa &&
              state_year === year &&
              siembra.ID !== id_siembra
            )
          })
          .map((siembra) => parseFloat(siembra.SUPERFICIE_ACTUAL_SEMBRADA))
          .reduce((acum, superficie) => {
            acum += superficie
            return acum
          }, 0)
        return suma
      },
    getSumaDeExtensionEnCosechas:
      (state) => (id_cosecha, id_finca, coa, year) => {
        const suma = toArray(state.cosechas)
          .filter((cosecha) => {
            const state_year = new Date(cosecha.fecha).getFullYear()
            return (
              cosecha.id_finca === id_finca &&
              cosecha.coa === coa &&
              state_year === year &&
              cosecha.id !== id_cosecha
            )
          })
          .map((cosecha) => parseFloat(cosecha.superficie_cosechada))
          .reduce((acum, superficie) => {
            acum += superficie
            return acum
          }, 0)
        return suma
      },
    finca: (state) => (id_finca) => {
      return state.fincas[id_finca]
    },
    productor_actual(state) {
      return state.productor
    },
    existeRegistroEnState: (state) => (registro, id) => {
      return Object.prototype.hasOwnProperty.call(state[registro], id)
    },
    registroConPropiedades:
      (state) =>
      (registro, object = {}) => {
        let found
        for (const reg in state[registro]) {
          found = true
          for (const key in object) {
            found = state[registro].reg[key] === object[key]
          }
          if (found === true) return reg
        }
        return found
      },
    embarcaciones(state) {
      return toArray(state.embarcaciones)
    },
    empresas(state) {
      return toArray(state.empresas)
    },
    // retorna un arreglo con los ids de todos los productores en el almacenamiento local
    id_productores(state) {
      const id_prods = []
      for (const key in state.productores) {
        id_prods.push(state.productores[key].id)
      }
      return id_prods
    },
    // retorna un arreglo con los ids de todos los productores en el almacenamiento local
    id_productoresci(state) {
      const id_prodsci = []
      for (const key in state.productoresci) {
        id_prodsci.push(state.productoresci[key].id)
      }
      return id_prodsci
    },
    // retorna una cadena con símbolo de '%' con el progreso de la sincronziación
    // de todos los módulos
    sync_all(state) {
      if (state.sync_all_progress === 0) {
        return ''
      } else {
        return ((state.sync_all_progress / 17) * 100).toFixed() + '%'
      }
    },
    establecimientosPorId: (state) => (id_finca) => {
      if (id_finca) {
        const establecimientos = []
        for (const key in state.establecimientos) {
          if (state.establecimientos[key].id_finca === id_finca) {
            establecimientos.push(state.establecimientos[key])
          }
        }
        return establecimientos
      } else {
        return []
      }
    },
    parcelasPorId: (state) => (id_finca) => {
      if (id_finca) {
        const parcelas = []
        for (const key in state.parcelas) {
          if (state.parcelas[key].id_finca === id_finca) {
            parcelas.push(state.parcelas[key])
          }
        }
        return parcelas
      } else {
        return []
      }
    },
    parcelaPorId: (state) => (id_parcela) => {
      if (id_parcela) {
        const parcelas = []
        for (const key in state.parcelas) {
          if (state.parcelas[key].id === id_parcela) {
            parcelas.push(state.parcelas[key])
          }
        }
        return parcelas
      } else {
        return []
      }
    },
    getMatchingRecords:
      (state) =>
      ({ recordName, matchValues, exceptIds = [], matchAll = true }) => {
        if (!Array.isArray(matchValues)) {
          return []
        }
        exceptIds = exceptIds.map((v) => String(v))
        const records = state[recordName]
        const found = []
        for (const key in records) {
          if (!exceptIds.includes(key)) {
            let didMatch = true
            let exists = false
            matchValues.forEach((v) => {
              const a = records[key][v.key]
              const b = v.value
              if (matchAll) {
                if (
                  !['', null, undefined].includes(a) ||
                  !['', null, undefined].includes(b)
                ) {
                  if (a !== b) {
                    didMatch = false
                  }
                }
              } else {
                if (a === b) {
                  exists = true
                }
              }
            })
            if ((matchAll && didMatch) || (!matchAll && exists)) {
              found.push(records[key])
            }
          }
        }
        return found
      },
    getRecord:
      (state) =>
      ({ recordName, key }) => {
        return state[recordName]?.[key] ?? {}
      },
  },
}
