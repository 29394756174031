<template>
  <v-container>
    <v-row dense>
      <!-- listado de productores en el almacenamiento local -->
      <!-- <v-col cols="12">
        <v-list two-line>
          <template v-for="(productor, index) in productores">
            <form-card
              :key="productor.id"
              :top-text="
                `${productor.primer_nombre} ${productor.primer_apellido}`
              "
              :middle-text="productor.id"
              :sincronized="productor.sincronizado"
              @click="traerRegistroYNavegar(productor)"
            >
              <template v-slot:avatar>
                <v-list-item-avatar>
                  <v-menu offset-y transition="slide-y-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon v-text="'mdi-dots-vertical'" />
                      </v-btn>
                    </template>
                    <v-card class="pa-3">
                      <v-row class="py-1">
                        <v-btn
                          v-if="productor.sincronizado"
                          class="success--text"
                          text
                          block
                          small
                          :disabled="loading_add"
                          @click.stop="cargarProductor(productor)"
                        >
                          <v-icon left v-text="'mdi-reload'" />
                          Cargar expediente nuevamente
                        </v-btn>
                      </v-row>
                      <v-row class="py-1">
                        <v-btn
                          :key="`v-btn-${index}`"
                          :loading="loading_delete"
                          block
                          small
                          class="error--text"
                          text
                          @click="eliminarTodoDe(productor.id)"
                        >
                          <v-icon left v-text="'mdi-delete'" />
                          Remover expediente del dispositivo
                        </v-btn>
                      </v-row>
                    </v-card>
                  </v-menu>
                </v-list-item-avatar>
              </template>
            </form-card>
          </template>
        </v-list>
      </v-col> -->
      <v-col cols="12" class="pb-3">
        <div class="text-h6">Consulta Productores</div>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          v-model="id"
          :rules="[r.max(23)]"
          outlined
          label="Cédula o Pasaporte"
          name="cedula"
          @keyup.enter="buscarProductores"
        ></v-text-field>
      </v-col>    
      <v-col cols="6" md="3">
        <v-text-field
          v-model="ruc"
          :rules="[r.max(23)]"
          outlined
          label="RUC"
          name="ruc"
          @keyup.enter="buscarProductores"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          v-model="nombre"
          :rules="[r.name]"
          outlined
          label="Nombre"
          name="nombre"
          @keyup.enter="buscarProductores"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          v-model="apellido"
          :rules="[r.name]"
          outlined
          label="Apellido"
          name="apellido"
          @keyup.enter="buscarProductores"
        ></v-text-field>
      </v-col>
      
      <v-col cols="6" md="5">
        <n-date
            label="Fecha de Producciones Desde"
            :date.sync="datedesde"
             @change="cambiaFechaStD"
         />
        <!-- <v-text-field
          v-model="fechaproddesde"
          label="Fecha de Producción Desde"
          dense
          outlined
          name="fechaproddesde"
          placeholder="yyyy-mm-dd"
          @input="validarFecha"
        >
        </v-text-field>
        <p v-if="error" style="color: red">{{ error }}</p>
         <n-date
            label="Fecha de Producción Desde"
            :date.sync="fechaproddesde"
             @change="cambiaFechaString"
         /> -->
      </v-col>
      <v-col cols="6" md="5">
        <n-date
          :date.sync="datehasta"
          label="Fecha de Producciones Hasta" 
          @change="cambiaFechaStH" />
      </v-col>
      <!-- Campo para el Año Agrícola
      <v-col cols="6" md="3">
        <v-autocomplete
          v-model="anio_agricola"
          :rules="[r.year]"
          placeholder="####-####"
          :items="aniosAgricolas"
          label="Año Agrícola"
          outlined
        ></v-autocomplete>
      </v-col>  -->
      <v-col cols="12">
        <v-btn
          :loading="loading_search"
          depressed
          block
          class="primary"
          @click="buscarProductores"
        >
          <v-icon left>mdi-magnify</v-icon>
          Buscar
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-iterator :items="listado_producto" :options="options">
          <template v-slot:item="props">
            <v-list-item :key="props.item.id">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                  >{{ props.item.primer_nombre || '' }}
                  {{ props.item.primer_apellido || '' }}</v-list-item-title
                >
                <v-list-item-subtitle class="text-body-2 blue-grey--text"
                  >{{ props.item.id }}
                  {{fechaproddesde}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  :disabled="loading_add"
                  icon
                  @click="
                    mostrarProductor(props.item.id, fechaproddesde, fechaprodhasta)
                  "
                >
                  <v-icon left>mdi-magnify</v-icon>
                  Ver
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="`v-divider-${props.item.id}`"></v-divider>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import * as rules from '@/utils/validations'
export default {
  name: 'Consulta',
  data: () => {
    return {
      r: rules,
      options: {
        itemsPerPage: 10,
      },
      listado_producto: [],
      susto: {},
      id: '',
      nombre: '',
      apellido: '',
      ruc: '',
      productoranterior: {},
      datedesde:'',
      datehasta:'',
      fechaproddesde: '',
      fechaprodhasta: '',
      loading_search: false,
      loading_add: false,
      loading_delete: false,
      anio_agricola: '',
      aniosAgricolas: [],
      errorhas: '',
    }
  },
  computed: {
    ...mapGetters('registros', ['id_productoresci']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta')
    this.calculaAniosAgricolas()
    this.setDefaultAnioAgricola()
  },
  methods: {
    validarFecha() {
      // Verificar si la fecha tiene el formato yyyy-mm-dd
      let regexFecha = /^(\d{4})-(\d{2})-(\d{2})$/
      if (!regexFecha.test(this.fechaproddesde)) {
        this.error =
          'Favor colocar año. mes y dia separado con guiones asi: yyyy-mm-dd'
      } else {
        // Si el formato es correcto, convierte la cadena de fecha en un objeto Date
        let [, anio, mes, dia] = this.fechaproddesde.match(regexFecha)
        // Verificar si el mes es válido (entre 1 y 12)
        if (parseInt(mes) < 1 || parseInt(mes) > 12) {
          this.error = 'El mes debe estar en el rango de 1 a 12.'
          return
        }
        // Verificar si el día es válido para el mes correspondiente
        let diasEnMes = new Date(anio, mes, 0).getDate() // Obtener el número de días en el mes
        if (parseInt(dia) < 1 || parseInt(dia) > diasEnMes) {
          this.error = 'El día no es válido para el mes especificado.'
          return
        }
        // Si todos los controles pasan, la fecha es válida
        this.error = ''
        console.log('Fecha válida:', this.fechaproddesde)
      }
    },
    validarFechahas() {
      // Verificar si la fecha tiene el formato yyyy-mm-dd
      let regexFecha = /^(\d{4})-(\d{2})-(\d{2})$/
      if (!regexFecha.test(this.fechaprodhasta)) {
        this.errorhas =
          'Favor colocar año. mes y dia separado con guiones asi: yyyy-mm-dd'
      } else {
        // Si el formato es correcto, convierte la cadena de fecha en un objeto Date
        let [, anio, mes, dia] = this.fechaprodhasta.match(regexFecha)
        // Verificar si el mes es válido (entre 1 y 12)
        if (parseInt(mes) < 1 || parseInt(mes) > 12) {
          this.errorhas = 'El mes debe estar en el rango de 1 a 12.'
          return
        }
        // Verificar si el día es válido para el mes correspondiente
        let diasEnMes = new Date(anio, mes, 0).getDate() // Obtener el número de días en el mes
        if (parseInt(dia) < 1 || parseInt(dia) > diasEnMes) {
          this.errorhas = 'El día no es válido para el mes especificado.'
          return
        }
        // Si todos los controles pasan, la fecha es válida
        this.errorhas = ''
        console.log('Fecha válida:', this.fechaprodhasta)
      }
    },
    existsInDevice(id = 0) {
      return this.id_productoresci.includes(id)
    },
    async buscarProductores() {
      try {
          this.loading_search = true
          const res = await this.$store.dispatch('registros/listarProductores', {
            id: this.id,
            nombre: this.nombre,
            apellido: this.apellido,
            ruc: this.ruc,
          })
          const { status, data } = res
          if (status === 'ok') {
           this.listado_producto = data
            this.productoranterior = data[0].id
            //alert(this.productoranterior)
          }
          this.loading_search = false
      } catch (e) {
        this.loading_search = false
      }
    },
    /////////////////// LIMPIA DATOS DE LA CONSULTAS ANTERIORES //////////////////////////////////
     async eliminarTodoDe(cool) {
      try {
        this.loading_delete = true
        await this.$store.dispatch(
          'registros/eliminarConsulta',
          {cool,}
        )
        this.loading_delete = false
      } catch (e) {
        this.loading_delete = false
      }
    },
    async cambiaFechaStD() {
      this.fechaproddesde = this.datedesde.toString();
      //alert(this.fechaproddesde)
    },
    async cambiaFechaStH() {
      this.fechaprodhasta = this.datehasta.toString();
      //alert(this.fechaproddesde)
    },

    async mostrarProductor(pro, fechapdesde, fechaphasta) {
      try {
        this.loading_add = true
        //obtemgo todo desde antes
      
        if (pro) {
          await this.eliminarTodoDe(pro)
        }
       //carga datos al state
        await this.$store.dispatch('registros/getConsulta', {
          id_productor: pro,
          fechaproddesde: fechapdesde,
          fechaprodhasta: fechaphasta,
        })
        await this.$store.dispatch(`registros/getProduco`, {
          id: pro,
        })
        this.$router.push(`/ru/productor/conexpediente/${pro}`)
        //return todo
        this.loading_add = false
      } catch (e) {
        this.loading_add = false
      }
    },

    calculaAniosAgricolas() {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth()
      // Si estamos antes de mayo, tomamos el año anterior como inicio
      const startYear = currentMonth < 4 ? currentYear - 1 : currentYear

      for (let i = 0; i < 10; i++) {
        const yearStart = startYear - i
        const yearEnd = yearStart + 1
        this.aniosAgricolas.push(`${yearStart}-${yearEnd}`)
      }
      for (let i = 1; i <= 2; i++) {
        const futureYearStart = startYear + i
        const futureYearEnd = futureYearStart + 1
        this.aniosAgricolas.unshift(`${futureYearStart}-${futureYearEnd}`)
      }
    },
    setDefaultAnioAgricola() {
      const defaultAnioAgricola = '2024-2025'
      if (this.aniosAgricolas.includes(defaultAnioAgricola)) {
        this.anio_agricola = defaultAnioAgricola
      } else {
        this.anio_agricola = this.aniosAgricolas[0]
      }
    },
  },
}
</script>
