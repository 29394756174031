<template>
  <div>
    <h1>Visualiza Imágenes de Cultivos detectados por el Satélite</h1>
    <div class="select-container">
      <v-row>
        <v-col cols="5">
          <v-select
            :items="anos_agricolas"
            v-model="selectedAnoAgricolaBusqueda"
            label="Selecciona un año agrícola"
            @change="buscarNombres"
            class="select-overlay"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="selectedNombreBusqueda"
            :items="nombres_anoAgricola"
            label="Tif correspondiente al año agricola seleccionado"
            class="select-overlay"
          >
          </v-select>
        </v-col>
        <v-col cols="5" md="2">
          <v-btn
            class="warning"
            small
            block
            depressed
            :disabled="loading"
            @click="resetFilter"
            v-text="'Limpiar'"
          />
        </v-col>
        <v-col cols="2">
          <v-btn depressed class="primary" @click="getFile"> FILTRAR </v-btn>
        </v-col>
      </v-row>
    </div>

    <div id="map-container">
      <div id="map" class="map"></div>

      <!-- Overlay de Vuetify -->
      <v-overlay :value="loadingMapa">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <span>Se está cargando...</span>
      </v-overlay>
    </div>
  </div>
</template>




<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import parse_georaster from 'georaster'
import GeoRasterLayer from 'georaster-layer-for-leaflet'

export default {
  name: 'CargarArchivoGeoTIFF',
  data() {
    return {
      file: null, // Selected file
      map: null, // Leaflet map
      georasterLayer: null, // Reference to the GeoRasterLayer
      anos_agricolas: [],
      nombres_anoAgricola: [],
      selectedNombreBusqueda: null,
      selectedAnoAgricolaBusqueda: null,
      ano_agricolaObj: {
        ano_agricola: '',
      },
      loading: false, // Controla la visibilidad del overlay
      loadingMapa: false,
      getFileInfo: {
        nombre: '',
        anio: '',
      },
    }
  },

  computed: {
    token() {
      const user =
        this.$store.state.auth.users[this.$store.state.auth.current_user]
      return user ? user.token : ''
    },
  },
  async mounted() {
    this.$store.commit('ui/updateToolbar', 'Mapa con Imágenes Satelitales')
    this.initializeMap()
    await this.listadoAnos()
    await this.buscarNombres()
  },
  methods: {
    initializeMap() {
      this.map = L.map('map').setView([8.537981, -80.782127], 7)
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors',
      }).addTo(this.map)
    },

    async buscarNombres() {
      this.ano_agricolaObj.ano_agricola = this.selectedAnoAgricolaBusqueda
      let tempNomList = await this.$store.dispatch(
        'registros/getNombres_AnoAgricolasTiff',
        this.ano_agricolaObj
      )
      this.nombres_anoAgricola = tempNomList.data
      this.selectedNombreBusqueda = this.nombres_anoAgricola[0]
    },

    async listadoAnos() {
      let tempList = await this.$store.dispatch(
        'registros/getAnos_AgricolasTiff'
      )
      this.anos_agricolas = tempList.data
      this.selectedAnoAgricolaBusqueda = this.anos_agricolas[0]
    },

    async resetFilter() {
      this.selectedNombreBusqueda = ''
      this.selectedAnoAgricolaBusqueda = ''
      this.filtr = clone({ ...this.filtrModel, modulo: this.filtr.modulo })
      /*  await this.loadReport(
        { includeCharts: 1 },
        this.detailActionName,
        this.detail
      )
      await this.loadReport(
        { includeCharts: 1 },
        this.calculatedActionName,
        this.calculated
      ) */
      this.loading = false
    },

    async displayGeoTIFF(arrayBuffer) {
      try {
        const georaster = await parse_georaster(arrayBuffer)

        const min = georaster.mins[0]
        const max = georaster.maxs[0]

        if (this.georasterLayer) {
          this.map.removeLayer(this.georasterLayer)
        }

        this.georasterLayer = new GeoRasterLayer({
          georaster,
          opacity: 0.7,
          pixelValuesToColorFn: (values) => {
            const value = values[0]
            if (value === null || value < min || value > max) {
              return null
            }
            const normalizedValue = (value - min) / (max - min)
            const colorValue = Math.round(255 * normalizedValue)
            return `rgb(${colorValue}, ${colorValue}, ${colorValue})`
          },
          resolution: 128,
        })
        this.georasterLayer.addTo(this.map)
        this.map.fitBounds(this.georasterLayer.getBounds())
      } catch (error) {
        console.error('Mi Error processing GeoTIFF file:', error)
      }

      this.loadingMapa = false
    },

    async getFile() {
      this.loadingMapa = true
      this.getFileInfo.anio = this.selectedAnoAgricolaBusqueda
      this.getFileInfo.nombre = this.selectedNombreBusqueda

      try {
        let url2 // Define la variable para la URL
        let response // Define la variable para la respuesta
        if (process.env.NODE_ENV === 'production') {
          url2 = 'https://sigap.mida.gob.pa/suag/public'
          // URL para entorno de producción (ajusta la URL correcta del servidor)
          response = await fetch(`${url2}/api/tiff/traerArchivo`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.getFileInfo),
          })
        } else {
          url2 = 'http://localhost/suag/public'
          response = await fetch(`${url2}/api/tiff/traerArchivo`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.getFileInfo),
          })
        }
        if (response.ok) {
          const arrayBuffer = await response.arrayBuffer()
          if (arrayBuffer) {
            await this.displayGeoTIFF(arrayBuffer)
          } else {
            console.error('No se recibió un arrayBuffer en la respuesta.')
          }
        } else {
          console.error('Error al obtener el archivo:', response.statusText)
          alert('Hubo un problema al obtener el archivo.')
        }
      } catch (error) {
        console.error('Error de red:', error)
        alert('Hubo un problema al obtener el archivo.')
      } finally {
        this.loadingMapa = false // Asegúrate de cambiar el estado de carga al final
      }
    },
  },
}
</script>


<style scoped>
/* Contenedor del mapa */
#map-container {
  position: relative;
  /* Asegura que el overlay se posicione correctamente */
}

/* Mapa */
#map {
  height: 500px;
  z-index: 1;
  /* Coloca el mapa en el fondo */
}

/* Elementos de selección */
.select-container {
  position: relative;
  z-index: 2;
  /* Asegura que los selectores estén por encima del mapa */
}

.select-overlay {
  position: relative;
  z-index: 3;
  /* Asegura que los selectores estén por encima del mapa y otros elementos */
}

/* Overlay de carga */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Fondo blanco con opacidad */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  /* Asegura que el overlay de carga esté encima de todo */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #333;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Ajuste opcional para el input */
.file-input {
  width: 100%;
}
</style>
