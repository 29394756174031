<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Información de la parcela</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="parcela"
            :remove-commit="'removerParcela'"
            :record-id="parcela.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="parcela.id_finca"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="fincas"
            item-text="nombre"
            item-value="id"
            label="Finca*"
            outlined
            @input="debounceBuscarRegistroExistente"
          >
            <template slot="item" slot-scope="{ item }">
              <finca-select-item :item="item" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="parcela.nombre"
            :rules="[r.required, r.max(45)]"
            label="Nombre*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field
            v-model="parcela.porcentaje"
            :rules="[
              r.required,
              r.float,
              (v) =>
                !extensionOverflow ||
                'La superficie de las parcelas supera la de la finca.',
            ]"
            label="Superficie"
            suffix="HA"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            v-model="parcela.tipo_parcela"
            :items="tipo_parcela"
            label="Tipo de parcela"
            outlined
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            v-model="parcela.estado"
            :items="estado_parcela"
            item-text="NOMBRE"
            item-value="ID_STATUS"
            :rules="[r.required]"
            label="Estado*"
            outlined
          >
          </v-select>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Ubicación (WGS84)</div>
          <div>
            La parcela sirve para que usted realice la trazabilidad y para que
            diversos clientes consulten el lugar de origen del producto dentro
            de una Finca.
          </div>
        </v-col>

        <!--Boton de coordenadas actuales-->
        <v-col cols="12" class="d-flex justify-center">
          <v-btn cols="6" class="mr-4">
            <div class="text-h6" block depressed @click="apagarCargaGpx">
              Obtener Posicion Actual
            </div>
          </v-btn>

          <!--Boton de cargar archivo GPX-->
          <v-btn cols="6" class="ml-4">
            <div class="text-h6" block depressed @click="apagarPosicionActual">
              Cargar Archivo (GPX)
            </div>
          </v-btn>
        </v-col>

        <!--Componente de coordenadas actuales-->
        <v-col cols="12" v-show="obtenerPosicionVar">
          <v-col cols="12">
            <div class="text-h6">Ubicación (WGS84)</div>
          </v-col>
          <v-col cols="12">
            <v-btn
              depressed
              block
              class="primary--text"
              @click="obtenerPosicion"
            >
              <v-icon left>mdi-crosshairs</v-icon>
              Registrar ubicación actual
            </v-btn>
          </v-col>
          <v-row>
            <v-col cols="6">
              <v-textarea
                v-model="coordenadasString"
                :rules="[validateLatLong, validarPoligonoCierra]"
                label="Latitud/Longitud"
                placeholder="Ej: 40.7128 -74.0060"
                outlined
                @input="validarCoordenadas"
              >
              </v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea
                v-model="utmString"
                label="UTM"
                placeholder="Ej: 33T 500000 4649776"
                outlined
                @input="updateLatLonFromUTM"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-btn depressed block class="primary--text" @click="mostrarMapa">
            <v-icon left>mdi-crosshairs</v-icon>
            Mostrar en el Mapa
          </v-btn>
          <v-col v-show="mapVisible" cols="12">
            <div id="mapita"></div>
          </v-col>
        </v-col>

        <!--Componente de subir archivo GPX-->
        <v-container class="py-0" v-show="cargarArchivoVar">
          <v-col cols="12">
            <div class="text-h6">Subir archivo (GPX)</div>
          </v-col>
          <v-row class="pt-4">
            <v-col cols="12" class="d-flex align-center">
              <v-file-input
                v-model="archivo"
                outlined
                label="Subir archivo gpx"
                @change="cambio"
                accept=".GPX"
              /><!-- 
              <v-btn depressed class="primary--text" @click="guardarGPX">
                Guardar Coordenadas
              </v-btn> -->
            </v-col>
          </v-row>
        </v-container>
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="parcela.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          >
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-status="parcela.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La parcela ya existe'"
      :description="'Los datos colocados corresponden a una parcela existente, desea cargar la información de esta parcela?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import uuid from 'uuid/v4'
import * as turf from '@turf/turf'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { Icon } from 'leaflet'
import debounce from 'lodash.debounce'
import { mapState, mapGetters } from 'vuex'
import FincaSelectItem from '../../components/FincaSelectItem.vue'
import {
  toArray,
  getCurrentPosition,
  clona,
  elObjetoTienePropiedades,
  sumRecordsProp,
} from '../../utilidades'
import { formulario_parcela } from '../../modelos'
import * as rules from '@/utils/validations'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'Parcela',
  components: {
    FincaSelectItem,
  },
  props: {
    modal: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => {
    return {
      latlongGPX: '',
      obtenerPosicionVar: true,
      cargarArchivoVar: false,
      archivo: null,
      coordenadaslatlongpx: [],
      poligonoCerrado: false,
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      parcela: clona(formulario_parcela),
      loading: false,
      coordenadas: [],
      coordenadasString: '',
      utmString: '',
      mapVisible: false,
    }
  },
  computed: {
    ...mapState('listados', ['tipo_parcela', 'estado_parcela']),
    ...mapGetters('registros', [
      'existeRegistroEnState',
      'getMatchingRecords',
      'getRecord',
    ]),
    fincas() {
      return toArray(this.$store.state.registros.fincas)
    },
    extensionOverflow() {
      const matchingRecords = this.getMatchingRecords({
        recordName: 'parcelas',
        matchValues: [{ key: 'id_finca', value: this.parcela.id_finca }],
        exceptIds: [this.parcela.id],
      })
      const totalExtension = sumRecordsProp(matchingRecords, 'porcentaje')
      const fincaExtension =
        this.getRecord({
          recordName: 'fincas',
          key: this.parcela.id_finca,
        })?.extension ?? 0
      return (
        totalExtension + parseFloat(this.parcela.porcentaje) > fincaExtension
      )
    },
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
    utmString(newVal) {
      if (!newVal) {
        this.coordenadasString = '' // Si UTM se borra, borra latlong
      }
    },
    coordenadasString(newVal) {
      if (!newVal) {
        this.utmString = '' // Si latlong se borra, borra UTM
      }
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Parcela')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.parcela = clona(formulario_parcela)
    }
    this.$nextTick(() => {
      this.map = L.map('mapita', { attributionControl: false }).setView(
        [8.8434471, -79.7883048],
        8
      )
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }).addTo(this.map)
    })
  },
  methods: {
    apagarPosicionActual() {
      this.obtenerPosicionVar = false
      this.cargarArchivoVar = true
    },
    apagarCargaGpx() {
      this.cargarArchivoVar = false
      this.obtenerPosicionVar = true
    },
    cambio(event) {
      this.leerArchivo(event)
    },
    leerArchivo(event) {
      console.log('Leyendo archivo')
      const archivo = event
      const reader = new FileReader()

      console.log('ANTES DE reador.onload')
      reader.onload = () => {
        console.log('DESPUE DE reador.onload')
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(reader.result, 'text/xml')

        // Extraer coordenadas de los puntos trkpt
        const trkpts = xmlDoc.getElementsByTagName('trkpt')
        this.coordenadaslatlongpx = Array.from(trkpts).map((point) => ({
          lat: point.getAttribute('lat'),
          lon: point.getAttribute('lon'),
        }))

        // Verificar si el polígono está cerrado, si esta cerrado es true y si no es false
        this.poligonoCerrado = this.esPoligonoCerrado()

        // Si no está cerrado, cerrar el polígono
        if (!this.poligonoCerrado) {
          console.log('El polígono no esta cerrado')
          console.log(
            this.coordenadaslatlongpx[0].lat,
            this.coordenadaslatlongpx[0].lon
          )
          console.log(
            this.coordenadaslatlongpx[this.coordenadaslatlongpx.length - 1].lat,
            this.coordenadaslatlongpx[this.coordenadaslatlongpx.length - 1].lon
          )

          this.cerrarPoligono()
          console.log('El polígono se ha cerrado.')
          console.log(
            this.coordenadaslatlongpx[0].lat,
            this.coordenadaslatlongpx[0].lon
          )
          console.log(
            this.coordenadaslatlongpx[this.coordenadaslatlongpx.length - 1].lat,
            this.coordenadaslatlongpx[this.coordenadaslatlongpx.length - 1].lon
          )

          console.log(
            'leerArchivo this.coordenadaslatlonggpx',
            this.coordenadaslatlongpx
          )
        } else {
          console.log('El polígono esta cerrado')
        }
        this.guardarGPX()
      }
      reader.readAsText(archivo)

      console.log('Termino de leer el archivo')
    },

    esPoligonoCerrado() {
      // Verificar si el primer y último punto son iguales
      const primerPunto = this.coordenadaslatlongpx[0]
      const ultimoPunto =
        this.coordenadaslatlongpx[this.coordenadaslatlongpx.length - 1]
      return (
        primerPunto.lat === ultimoPunto.lat &&
        primerPunto.lon === ultimoPunto.lon
      )
    },

    cerrarPoligono() {
      // Agregar el primer punto al final del arreglo de coordenadas
      const primerPunto = this.coordenadaslatlongpx[0]
      this.coordenadaslatlongpx.push(primerPunto)
    },

    async guardarGPX() {
      // Crear la cadena WKT para lat/lon
      const wktlatlon = `POLYGON((${this.coordenadaslatlongpx
        .map((coord) => `${coord.lon} ${coord.lat}`)
        .join(', ')}))`

      this.latlongGPX = wktlatlon
      /* console.log('wktlatlon', wktlatlon);
      console.log('this.coordenadaslatlongpx', this.coordenadaslatlongpx); */
      console.log('this.latlongGPX', this.latlongGPX)

      // Convertir las coordenadas lat/lon a UTM
      const coordenadasUTM = await Promise.all(
        this.coordenadaslatlongpx.map((coord) => {
          return this.latLonToUTM(coord.lat, coord.lon)
        })
      )

      // Combinar todos los resultados en una sola cadena separada por comas
      const cadenaUTM = coordenadasUTM.join(', ')
      console.log('cadenaUTM:', cadenaUTM)

      this.utmString = cadenaUTM
    },
    addMarker(lat, lon) {
      if (this.marker) {
        this.map.removeLayer(this.marker)
      }
      this.marker = L.marker([lat, lon]).addTo(this.map)
      this.map.setView([lat, lon], 8)
    },
    addPolygon(coords) {
      if (this.polygon) {
        this.map.removeLayer(this.polygon)
      }
      this.polygon = L.polygon(coords, {
        color: 'blue',
        fillColor: '#3e64ff',
        fillOpacity: 0.5,
      }).addTo(this.map)
      this.map.fitBounds(this.polygon.getBounds())
    },
    // obtiene la posición y la carga a los campos [x, y] del formulario
    async obtenerPosicion() {
      const position = await getCurrentPosition()
      if (position) {
        this.coordenadasString = `${position.coords.latitude} ${position.coords.longitude}`
        await this.validarCoordenadas()
      }
    },

    mostrarMapa() {
      this.validarCoordenadas()
      this.mapVisible = !this.mapVisible
      this.$nextTick(() => {
        if (this.mapVisible) {
          this.map.invalidateSize()
          if (this.coordenadas.length === 1) {
            const { lat, lon } = this.coordenadas[0]
            if (!isNaN(lat) && !isNaN(lon)) {
              this.addMarker(lat, lon)
            }
          }
        }
      })
    },

    async latLonToUTM(lat, lon) {
      const zone = Math.floor((lon + 180) / 6) + 1
      const a = 6378137.0
      const e = 0.0818191908426
      const k0 = 0.9996

      const lonOrigin = (zone - 1) * 6 - 180 + 3
      const lonOriginRad = lonOrigin * (Math.PI / 180)
      const latRad = lat * (Math.PI / 180)
      const lonRad = lon * (Math.PI / 180)

      const N = a / Math.sqrt(1 - Math.pow(e * Math.sin(latRad), 2))
      const T = Math.pow(Math.tan(latRad), 2)
      const C = ((e * e) / (1 - e * e)) * Math.pow(Math.cos(latRad), 2)
      const A = Math.cos(latRad) * (lonRad - lonOriginRad)

      const M =
        a *
        ((1 -
          (e * e) / 4 -
          (3 * e * e * e * e) / 64 -
          (5 * e * e * e * e * e * e) / 256) *
          latRad -
          ((3 * e * e) / 8 +
            (3 * e * e * e * e) / 32 +
            (45 * e * e * e * e * e * e) / 1024) *
            Math.sin(2 * latRad) +
          ((15 * e * e * e * e) / 256 + (45 * e * e * e * e * e * e) / 1024) *
            Math.sin(4 * latRad) -
          ((35 * e * e * e * e * e * e) / 3072) * Math.sin(6 * latRad))

      const easting =
        k0 *
          N *
          (A +
            ((1 - T + C) * Math.pow(A, 3)) / 6 +
            ((5 - 18 * T + T * T + 72 * C - 58 * e * e) * Math.pow(A, 5)) /
              120) +
        500000
      const northing =
        k0 *
        (M +
          N *
            Math.tan(latRad) *
            ((A * A) / 2 +
              ((5 - T + 9 * C + 4 * C * C) * Math.pow(A, 4)) / 24 +
              ((61 - 58 * T + T * T + 600 * C - 330 * e * e) * Math.pow(A, 6)) /
                720))

      return `${zone} ${Math.round(easting)} ${Math.round(northing)}`
    },

    utmToLatLon({ zoneNumber, easting, northing }) {
      const a = 6378137.0 // WGS84 major axis
      const eccSquared = 0.00669438 // WGS84 eccentricity squared
      const k0 = 0.9996

      const e1 =
        (1 - Math.sqrt(1 - eccSquared)) / (1 + Math.sqrt(1 - eccSquared))
      const x = easting - 500000.0 // Remove 500,000 meter offset for longitude
      let y = northing

      // Set the Northern Hemisphere if zoneLetter >= N
      const northernHemisphere = true // Panama is in the Northern Hemisphere
      if (!northernHemisphere) {
        y -= 10000000.0 // Remove 10,000,000 meter offset used for southern hemisphere
      }

      const longOrigin = (zoneNumber - 1) * 6 - 180 + 3 // +3 puts origin in middle of zone

      const eccPrimeSquared = eccSquared / (1 - eccSquared)

      const M = y / k0
      const mu =
        M /
        (a *
          (1 -
            eccSquared / 4 -
            (3 * eccSquared * eccSquared) / 64 -
            (5 * eccSquared * eccSquared * eccSquared) / 256))

      const phi1Rad =
        mu +
        ((3 * e1) / 2 - (27 * e1 * e1 * e1) / 32) * Math.sin(2 * mu) +
        ((21 * e1 * e1) / 16 - (55 * e1 * e1 * e1 * e1) / 32) *
          Math.sin(4 * mu) +
        ((151 * e1 * e1 * e1) / 96) * Math.sin(6 * mu)

      const N1 =
        a / Math.sqrt(1 - eccSquared * Math.sin(phi1Rad) * Math.sin(phi1Rad))
      const T1 = Math.tan(phi1Rad) * Math.tan(phi1Rad)
      const C1 = eccPrimeSquared * Math.cos(phi1Rad) * Math.cos(phi1Rad)
      const R1 =
        (a * (1 - eccSquared)) /
        Math.pow(1 - eccSquared * Math.sin(phi1Rad) * Math.sin(phi1Rad), 1.5)
      const D = x / (N1 * k0)

      const lat =
        phi1Rad -
        ((N1 * Math.tan(phi1Rad)) / R1) *
          ((D * D) / 2 -
            ((5 + 3 * T1 + 10 * C1 - 4 * C1 * C1 - 9 * eccPrimeSquared) *
              Math.pow(D, 4)) /
              24 +
            ((61 +
              90 * T1 +
              298 * C1 +
              45 * T1 * T1 -
              252 * eccPrimeSquared -
              3 * C1 * C1) *
              Math.pow(D, 6)) /
              720)
      const lon =
        (D -
          ((1 + 2 * T1 + C1) * Math.pow(D, 3)) / 6 +
          ((5 -
            2 * C1 +
            28 * T1 -
            3 * C1 * C1 +
            8 * eccPrimeSquared +
            24 * T1 * T1) *
            Math.pow(D, 5)) /
            120) /
        Math.cos(phi1Rad)
      const longitude = longOrigin + lon * (180 / Math.PI)

      return {
        lat: ((lat * 180) / Math.PI).toPrecision(7),
        lon: longitude.toPrecision(7),
      }
    },

    async updateUTMFromLatLon() {
      const coordsArray = this.coordenadasString.trim().split(' ')
      if (coordsArray.length < 2 || coordsArray.length % 2 !== 0) {
        return
      }
      let utmArray = []
      for (let i = 0; i < coordsArray.length; i += 2) {
        const lat = parseFloat(coordsArray[i])
        const lon = parseFloat(coordsArray[i + 1])
        if (!isNaN(lat) && !isNaN(lon)) {
          const utm = await this.latLonToUTM(lat, lon)
          utmArray.push(utm)
        }
      }
      this.utmString = utmArray.join(', ')
      // this.parcela.utm = this.utmString
    },

    updateLatLonFromUTM() {
      const utmArray = this.utmString.trim().split(/\s+/) // Dividir por uno o más espacios

      const coords = []
      for (let i = 0; i < utmArray.length; i += 3) {
        const zoneNumber = parseInt(utmArray[i])
        const easting = parseFloat(utmArray[i + 1])
        const northing = parseFloat(utmArray[i + 2])
        if (!isNaN(zoneNumber) && !isNaN(easting) && !isNaN(northing)) {
          const coord = this.utmToLatLon({ zoneNumber, easting, northing })
          coords.push(coord)
        }
      }

      this.coordenadas = coords
      this.updateLatLonString()
    },

    async validarCoordenadas() {
      await this.updateUTMFromLatLon()
      const coordsArray = this.coordenadasString.trim().split(' ')

      if (coordsArray.length < 2 || coordsArray.length % 2 !== 0) {
        this.validado = false
        return
      }

      this.validado = true
      this.coordenadas = []

      for (let i = 0; i < coordsArray.length; i += 2) {
        const lat = parseFloat(coordsArray[i])
        const lon = parseFloat(coordsArray[i + 1])
        if (!isNaN(lat) && !isNaN(lon)) {
          this.coordenadas.push([lat, lon])
        }
      }

      if (this.coordenadas.length === 1) {
        const [lat, lon] = this.coordenadas[0]
        if (!isNaN(lat) && !isNaN(lon)) {
          this.addMarker(lat, lon)

          // Validar si el punto está dentro del polígono de la provincia
          const fincaSeleccionada = this.fincas.find(
            (finca) => finca.id === this.parcela.id_finca
          )
          if (!fincaSeleccionada) {
            this.validado = false
            return
          }

          const idProvincia = fincaSeleccionada.id_provincia
          const provinciaCoords = this.getCoordenadasProvincia(idProvincia) // Debe devolver las coordenadas del polígono

          if (provinciaCoords.length < 4) {
            console.error(
              'Las coordenadas de la provincia no son suficientes para formar un polígono'
            )
            this.validado = false
            return
          }

          // Crear un polígono usando Turf.js
          const polygonProvincia = turf.polygon([provinciaCoords])
          const point = turf.point(this.coordenadas[0])
          const isInside = turf.booleanPointInPolygon(point, polygonProvincia)

          if (!isInside) {
            this.$store.commit('ui/setSnack', {
              text: 'El punto está fuera del área de la provincia seleccionada',
              color: 'error',
            })
            return
          }
        }
      } else if (this.coordenadas.length > 1) {
        // Asegúrate de que el polígono esté cerrado
        if (
          this.coordenadas[0][0] !==
            this.coordenadas[this.coordenadas.length - 1][0] ||
          this.coordenadas[0][1] !==
            this.coordenadas[this.coordenadas.length - 1][1]
        ) {
          this.coordenadas.push(this.coordenadas[0])
        }

        if (this.coordenadas.length >= 4) {
          this.addPolygon(this.coordenadas)

          // Obtener las coordenadas del polígono de la provincia seleccionada
          const fincaSeleccionada = this.fincas.find(
            (finca) => finca.id === this.parcela.id_finca
          )
          if (!fincaSeleccionada) {
            this.validado = false
            return
          }

          const idProvincia = fincaSeleccionada.id_provincia
          const provinciaCoords = this.getCoordenadasProvincia(idProvincia) // Debe devolver las coordenadas del polígono

          if (provinciaCoords.length < 3) {
            this.$store.commit('ui/setSnack', {
              text: 'Las coordenadas de la provincia no son suficientes para formar un polígono',
              color: 'error',
            })
            this.validado = false
            return
          }

          // Crear un polígono usando Turf.js
          const polygonProvincia = turf.polygon([provinciaCoords])
          const inputPolygon = turf.polygon([this.coordenadas])
          const isInside = turf.booleanContains(polygonProvincia, inputPolygon)

          if (!isInside) {
            this.$store.commit('ui/setSnack', {
              text: 'Las coordenadas están fuera del área de la provincia seleccionada',
              color: 'error',
            })
            return
          }
        } else {
          this.$store.commit('ui/setSnack', {
            text: 'El polígono del usuario no tiene suficientes puntos para ser válido',
            color: 'error',
          })
          return
        }
      }
    },

    convertirCoordenadas() {
      const coordsArray = this.coordenadasString.trim().split(' ')
      if (coordsArray.length < 2 || coordsArray.length % 2 !== 0) {
        this.wktString = 'Formato de coordenadas incorrecto'
        return
      }
      if (coordsArray.length === 2) {
        this.coordenadasString = `POINT(${coordsArray.join(' ')})`
      } else {
        this.coordenadasString = `POLYGON(${coordsArray.join(' ')})`
      }
    },

    validarPoligonoCierra(value) {
      const coordsArray = value.trim().split(' ')
      if (coordsArray.length < 4) {
        return true // No hay suficientes coordenadas para formar un polígono, se omite esta validación
      }

      const firstCoord1 = coordsArray[0]
      const firstCoord2 = coordsArray[1]
      const lastCoord1 = coordsArray[coordsArray.length - 2]
      const lastCoord2 = coordsArray[coordsArray.length - 1]

      if (firstCoord1 !== lastCoord1 || firstCoord2 !== lastCoord2) {
        return 'Las coordenadas del polígono deben cerrar en el mismo punto en el que comenzaron.'
      }

      return true
    },
    // Función para obtener el polígono de la provincia actual
    getCoordenadasProvincia(idprovincia) {
      const provincias = {
        1: [
          [9.085102, -82.932137],
          [9.467305, -82.92115],
          [9.610448, -82.854833],
          [9.567775, -82.561686],
          [9.129493, -81.98543],
          [8.860921, -82.178139],
          [9.144004, -82.323488],
          [8.98999, -82.445972],
          [8.935948, -82.356967],
          [8.823806, -82.370032],
          [8.899529, -82.690262],
          [9.085102, -82.932137],
        ],
        2: [
          [9.05558, -80.196959],
          [8.409205, -80.043613],
          [8.094279, -80.494332],
          [8.179925, -80.739464],
          [8.557881, -80.818787],
          [8.9317, -80.545004],
          [8.859357, -80.366981],
          [9.05558, -80.196959],
        ],
        3: [
          [8.877059, -80.86707],
          [8.63296, -80.738824],
          [8.636075, -80.718964],
          [8.783947, -80.651563],
          [8.789206, -80.556462],
          [8.943778, -80.545892],
          [8.86679, -80.364545],
          [9.063015, -80.196555],
          [8.947819, -80.140548],
          [8.992875, -79.969638],
          [9.198515, -79.918925],
          [9.114065, -79.685648],
          [9.488335, -79.484496],
          [9.34603, -79.252305],
          [9.557815, -79.085346],
          [9.634816, -79.567833],
          [8.877059, -80.86707],
        ],
        4: [
          [8.001949, -81.66458],
          [8.309626, -81.529975],
          [8.46796, -82.078706],
          [8.448576, -82.191392],
          [8.755379, -82.17996],
          [8.918726, -82.713461],
          [8.835139, -82.877049],
          [8.751829, -82.916889],
          [8.488054, -82.829921],
          [8.333183, -83.049647],
          [8.041313, -82.901826],
          [8.001949, -81.66458],
        ],
        5: [
          [7.93861, -78.401114],
          [8.785822, -78.441647],
          [9.076213, -78.036436],
          [8.775951, -77.695592],
          [8.623328, -77.913111],
          [8.070826, -77.547939],
          [8.161915, -77.260921],
          [7.933474, -77.167537],
          [7.522938, -77.568256],
          [7.704236, -77.71687],
          [8.102168, -78.172513],
          [7.704236, -77.71687],
          [7.450074, -77.723403],
          [7.483712, -77.812847],
          [7.245966, -77.894987],
          [7.93861, -78.401114],
        ],
        6: [
          [8.136543, -80.720596],
          [8.131105, -80.579147],
          [8.108673, -80.50705],
          [7.998533, -80.391007],
          [7.933251, -80.462418],
          [7.939372, -80.483704],
          [7.83708, -80.572696],
          [7.819347, -80.596369],
          [7.720097, -80.563464],
          [7.688332, -80.584244],
          [7.537986, -80.730581],
          [7.750297, -80.957499],
          [7.91475, -80.935269],
          [8.055841, -80.851979],
          [8.136543, -80.720596],
        ],
        7: [
          [7.997263, -80.368626],
          [7.482423, -79.974584],
          [7.237333, -80.613186],
          [7.517142, -80.7287],
          [7.662158, -80.621376],
          [7.679931, -80.583108],
          [7.83328, -80.573865],
          [7.939568, -80.47336],
          [7.997263, -80.368626],
        ],
        8: [
          [8.929528, -79.543634],
          [9.106622, -79.688422],
          [9.206351, -79.642472],
          [9.211808, -79.592038],
          [9.473242, -79.533535],
          [9.489519, -79.395859],
          [9.40716, -79.423625],
          [9.421877, -79.269418],
          [9.310786, -79.279811],
          [9.364643, -78.887416],
          [9.380003, -78.718095],
          [9.35373, -78.511429],
          [9.27235, -78.39065],
          [9.134791, -78.082987],
          [8.872478, -78.193203],
          [8.827381, -78.344204],
          [8.804276, -78.489431],
          [8.353733, -78.421656],
          [8.929528, -79.543634],
        ],
        9: [
          [8.780969, -81.214464],
          [8.882636, -80.874773],
          [8.749495, -80.737591],
          [8.56519, -80.780325],
          [8.563575, -80.828503],
          [8.13296, -80.704385],
          [7.860862, -80.957703],
          [7.242849, -80.623657],
          [7.213383, -80.886007],
          [7.986016, -81.672356],
          [8.4826, -81.338517],
          [8.435145, -81.120622],
          [8.780969, -81.214464],
        ],
        10: [
          [9.563604, -79.079616],
          [9.566313, -78.972499],
          [9.23613, -78.055222],
          [8.690411, -77.367122],
          [8.530189, -77.446773],
          [9.19913, -78.229549],
          [9.387512, -78.83105],
          [9.298755, -78.994472],
          [9.301465, -79.099529],
          [9.317728, -79.268444],
          [9.563604, -79.079616],
        ],
        11: [
          [8.626559, -77.895918],
          [8.774525, -77.72563],
          [8.72702, -77.628126],
          [8.521998, -77.449599],
          [8.473659, -77.407487],
          [8.203811, -77.271121],
          [8.082977, -77.581665],
          [8.264724, -77.72486],
          [8.626559, -77.895918],
        ],
        12: [
          [8.935302, -81.994299],
          [9.190553, -81.908933],
          [8.787988, -81.228555],
          [8.56538, -81.218162],
          [8.438421, -81.140511],
          [8.475043, -81.183383],
          [8.468156, -81.233396],
          [8.370461, -81.605008],
          [8.283234, -81.659856],
          [8.280576, -81.798627],
          [8.360305, -82.014394],
          [8.478982, -82.153165],
          [8.764227, -82.132868],
          [8.840316, -82.342368],
          [8.986664, -82.406399],
          [9.131704, -82.315237],
          [8.84657, -82.168365],
          [8.935302, -81.994299],
        ],
        13: [
          [8.410114, -80.041988],
          [8.898885, -80.166399],
          [8.957373, -80.137547],
          [9.177221, -79.920073],
          [9.107916, -79.698724],
          [8.92315, -79.567078],
          [8.65311, -79.6989],
          [8.410114, -80.041988],
        ],
        // Agregar los polígonos para otras provincias aquí
      }
      return provincias[idprovincia] || []
    },

    validateLatLong(value) {
      if (!value) {
        return true // Permitir que el campo esté vacío
      }
      try {
        // Verificar si value es una cadena
        if (typeof value !== 'string') {
          return 'El valor no es una cadena.'
        }

        // Dividir la entrada en líneas
        const lines = value.trim().split('\n')

        // Definir el patrón de regex para validar coordenadas decimales
        const decimalPattern = /^-?\d{1,3}\.\d+\s+-?\d{1,3}\.\d+$/

        // Recorrer cada línea para validar el formato
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i].trim()

          // Verificar si la línea cumple con el patrón decimal
          if (!decimalPattern.test(line)) {
            // Intentar dividir por espacio para detectar latitud y longitud
            const parts = line.split(/\s+/)
            if (parts.length % 2 !== 0) {
              return `Formato inválido, ingresar latitud y despues longitud`
            }
            // Validar cada par de latitud y longitud
            for (let j = 0; j < parts.length; j += 2) {
              const latitude = parseFloat(parts[j])
              const longitude = parseFloat(parts[j + 1])
              if (
                isNaN(latitude) ||
                isNaN(longitude) ||
                latitude < -90 ||
                latitude > 90 ||
                longitude < -180 ||
                longitude > 180
              ) {
                return `Formato inválido, ingresar latitud y despues longitud`
              }
            }
          } else {
            // Si cumple el patrón directamente, validar el rango de latitud y longitud
            const [latitude, longitude] = line.split(/\s+/)
            if (
              parseFloat(latitude) < -90 ||
              parseFloat(latitude) > 90 ||
              parseFloat(longitude) < -180 ||
              parseFloat(longitude) > 180
            ) {
              return `Formato inválido, ingresar latitud y despues longitud`
            }
          }
        }
        // Si todas las líneas son válidas
        return true
      } catch (error) {
        console.error('Error en validateLatLong:', error)
        return 'Error al validar las coordenadas.'
      }
    },

    validateUTM(value) {
      const coordPairs = value.split(',').map((coord) => coord.trim())
      const utmRegex = /^\d{1,2}[C-HJ-NP-X]\s\d{6}\s\d{7}$/
      for (const pair of coordPairs) {
        if (!utmRegex.test(pair)) {
          return `Formato incorrecto: ${pair}. Debe ser zone easting northing en formato UTM.`
        }
        const [easting, northing] = pair.split(' ')
        if (parseInt(easting) < 100000 || parseInt(easting) > 999999) {
          return `Easting fuera de rango: ${easting}. Debe estar entre 100000 y 999999.`
        }
        if (parseInt(northing) < 0 || parseInt(northing) > 10000000) {
          return `Northing fuera de rango: ${northing}. Debe estar entre 0 y 10000000.`
        }
      }
      return true
    },

    updateLatLonString() {
      this.coordenadasString = this.coordenadas
        .map((coord) => `${coord.lat} ${coord.lon}`)
        .join(' ')
      this.validarCoordenadas()
    },

    async updateUTMString() {
      const utmCoords = await Promise.all(
        this.coordenadas.map(async (coord) => {
          return await this.latLonToUTM(coord.lat, coord.lon)
        })
      )
      this.utmString = utmCoords.join(', ')
    },

    // Función para extraer y formatear coordenadas de WKT
    extractAndFormatCoordinates() {
      if (this.coordenadasString.startsWith('POLYGON')) {
        const coords = this.coordenadasString.match(/\(([^())]+)\)/)[1].trim()
        const coordPairs = coords
          .split(',')
          .map((pair) => pair.trim().split(' ').reverse().join(' '))
          .join(' ')
        this.coordenadasString = coordPairs
      } else if (this.coordenadasString.startsWith('POINT')) {
        const coords = this.coordenadasString
          .match(/\(([^())]+)\)/)[1]
          .trim()
          .split(' ')
          .reverse()
          .join(' ')
        this.coordenadasString = coords
      }
    },

    convertirAWKT(coordenadasString) {
      const coordsArray = coordenadasString.trim().split(' ')
      if (coordsArray.length < 2 || coordsArray.length % 2 !== 0) {
        return null
      }
      const lonLatPairs = []
      for (let i = 0; i < coordsArray.length; i += 2) {
        const lat = parseFloat(coordsArray[i])
        const lon = parseFloat(coordsArray[i + 1])
        if (!isNaN(lat) && !isNaN(lon)) {
          lonLatPairs.push(`${lon} ${lat}`)
        }
      }
      if (lonLatPairs.length === 1) {
        return `POINT(${lonLatPairs[0]})`
      } else {
        return `POLYGON((${lonLatPairs.join(', ')}))`
      }
    },

    convertirAWKTUTM(utmString) {
      const utmPairs = utmString.trim().split(',') // Dividir por comas
      const lonLatPairs = []
      for (let i = 0; i < utmPairs.length; i++) {
        const utmPair = utmPairs[i].trim().split(/\s+/) // Dividir por uno o más espacios
        const zoneNumber = parseInt(utmPair[0])
        const easting = parseFloat(utmPair[1])
        const northing = parseFloat(utmPair[2])

        if (!isNaN(zoneNumber) && !isNaN(easting) && !isNaN(northing)) {
          lonLatPairs.push(`${easting} ${northing}`)
        } else {
          console.error('Coordenadas UTM inválidas:', utmPairs[i])
          return null
        }
      }
      if (lonLatPairs.length === 1) {
        return `POINT(${lonLatPairs[0]})`
      } else if (lonLatPairs.length > 1) {
        // Cerramos el polígono volviendo al primer punto
        lonLatPairs.push(lonLatPairs[0])
        return `POLYGON((${lonLatPairs.join(', ')}))`
      } else {
        return null
      }
    },

    async guardarYContinuar() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return ''
        }
        if (await this.buscarRegistroExistente()) return

        if (!this.obtenerIdDeRuta()) {
          this.parcela.id = uuid()
          this.parcela.created_at = new Date().getTime() / 1000
        }
        this.parcela.updated_at = new Date().getTime() / 1000

        // Validar y convertir coordenadas antes de guardar
        await this.validarCoordenadas()

        // Convertir coordenadas a WKT
        if (this.coordenadasString) {
          const wkt = this.convertirAWKT(this.coordenadasString)
          if (wkt) {
            this.parcela.latlong = wkt
          } else {
            this.$store.commit('ui/setSnack', {
              text: 'Formato de coordenadas incorrecto',
              color: 'error',
            })
            return
          }
        } else {
          // Si las coordenadas están vacías, eliminarlas de la parcela
          this.parcela.latlong = this.latlongGPX
        }

        // Validar y convertir coordenadas antes de guardar
        await this.updateLatLonFromUTM()
        // Convertir coordenadas UTM a WKT
        if (this.utmString && this.obtenerPosicionVar === true) {
          const wkt = this.convertirAWKTUTM(this.utmString)
          if (wkt) {
            this.parcela.utm = wkt
          } else {
            this.$store.commit('ui/setSnack', {
              text: 'Formato de coordenadas UTM incorrecto',
              color: 'error',
            })
            return
          }
        } else {
          // Si las coordenadas UTM están vacías, eliminarlas de la parcela
          this.parcela.utm = null
        }

        this.$store.dispatch('registros/guardarParcela', clona(this.parcela))

        this.parcela = clona(formulario_parcela)
        if (this.modal) this.$emit('update:modal', false)
        else this.$router.push('/ru/fincas')
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('parcelas', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/parcela/${id}`)
      this.cerrarModalRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState('parcelas', id)) {
        this.parcela = this.obtenerRegistroDelState(id)
        this.coordenadasString = this.parcela.latlong || '' // Actualiza con las coordenadas almacenadas en la base de datos
        this.extractAndFormatCoordinates()
        this.updateUTMFromLatLon() // Convierte coordenadas a UTM y actualiza utmString
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.id ? registro.id.toString() : ''
        if (id === this.obtenerIdDeRuta()) return false
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
      const fincaSeleccionada = this.fincas.find(
        (finca) => finca.id === this.parcela.id_finca
      )
      if (fincaSeleccionada) {
        //console.log(fincaSeleccionada.id_provincia)
      } else {
        console.log('Finca no encontrada')
      }
    }, 1500),
    async obtenerUnRegistroONada() {
      try {
        const id_finca = this.parcela.id_finca
        const nombre = this.parcela.nombre
        // required fields to be fulfilled
        if (nombre && id_finca) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarParcelas',
            {
              id_finca,
              nombre,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async cargarRegistroDelServer(registro) {
      try {
        await this.$store.dispatch('registros/getParcela', registro)
        return true
      } catch (e) {
        return false
      }
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalRegistroExistente() {
      this.modal_registro_existe = false
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    obtenerRegistroDelState(id) {
      return {
        ...formulario_parcela,
        ...this.$store.state.registros.parcelas[id],
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
  },
}
</script>

<style scoped>
#mapita {
  height: 300px;
  width: 100%;
  z-index: 0;
  margin-top: 50px;
}
</style>
