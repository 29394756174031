import Vue from 'vue'
import Router from 'vue-router'
import Cuenta from '@/views/Cuenta.vue'
import RUUTMsave from '@/views/RU/UTMsave.vue'
import RUTiffSave from '@/views/RU/TiffSave.vue'
import RUTiffSearch from '@/views/RU/TiffSearch.vue'
import RUConexpediente from '@/views/RU/Conexpediente.vue'
import RUFormularioConExpe from '@/views/RU/FormularioConExpe.vue'
import RUProductores from '@/views/RU/Productores.vue'
import RUEmpresa from '@/views/RU/Empresa.vue'
import RUCrearEmpresa from '@/views/RU/CrearEmpresa.vue'
import RUConsulta from '@/views/RU/Consulta.vue'
import RUFormularioConsul from '@/views/RU/FormularioConsul.vue'
import RUCedulaErrada from '@/views/RU/CedulaErrada.vue'
import RUProductor from '@/views/RU/Productor.vue'
import RUClasificacionProductor from '@/views/RU/ClasificacionProductor.vue'
import RUFamilia from '@/views/RU/Familia.vue'
import RUCofamilia from '@/views/RU/Cofamilia.vue'
import RUFincas from '@/views/RU/Fincas.vue'
import RUFinca from '@/views/RU/Finca.vue'
import RUCofincas from '@/views/RU/Cofincas.vue'
import RUCofinca from '@/views/RU/Cofinca.vue'
import RUEstablecimientos from '@/views/RU/Establecimientos.vue'
import RUEstablecimiento from '@/views/RU/Establecimiento.vue'
import RUParcelas from '@/views/RU/Parcelas.vue'
import RUParcela from '@/views/RU/Parcela.vue'
import RUCoparcela from '@/views/RU/Coparcela.vue'
import RUCoparcelas from '@/views/RU/Coparcelas.vue'
import RUEmbarcaciones from '@/views/RU/Embarcaciones.vue'
import RUEmbarcacion from '@/views/RU/Embarcacion.vue'
import RUCopreparaciones from '@/views/RU/Copreparaciones.vue'
import RUCopreparacion from '@/views/RU/Copreparacion.vue'
import RUCosiembras from '@/views/RU/Cosiembras.vue'
import RUCosiembra from '@/views/RU/Cosiembra.vue'
import RUCocosechas from '@/views/RU/Cocosechas.vue'
import RUCocosecha from '@/views/RU/Cocosecha.vue'
import RUCopecuarios from '@/views/RU/Copecuarios.vue'
import RUCopecuario from '@/views/RU/Copecuario.vue'
import RUCoacuiculturas from '@/views/RU/Coacuiculturas.vue'
import RUCoacuicultura from '@/views/RU/Coacuicultura.vue'
import RUCoapiculturas from '@/views/RU/Coapiculturas.vue'
import RUCoapicultura from '@/views/RU/Coapicultura.vue'
import RUCopescas from '@/views/RU/Copescas.vue'
import RUCopesca from '@/views/RU/Copesca.vue'
import RUCoagroturismos from '@/views/RU/Coagroturismos.vue'
import RUCoagroturismo from '@/views/RU/Coagroturismo.vue'
import RUCoforestales from '@/views/RU/Coforestales.vue'
import RUCoforestal from '@/views/RU/Coforestal.vue'
import RUCoartesanias from '@/views/RU/Coartesanias.vue'
import RUCoartesania from '@/views/RU/Coartesania.vue'
import RUCoagroindustrias from '@/views/RU/Coagroindustrias.vue'
import RUCoagroindustria from '@/views/RU/Coagroindustria.vue'
import RUPreparaciones from '@/views/RU/Preparaciones.vue'
import RUPreparacion from '@/views/RU/Preparacion.vue'
import RUSiembras from '@/views/RU/Siembras.vue'
import RUSiembra from '@/views/RU/Siembra.vue'
import RUCosechas from '@/views/RU/Cosechas.vue'
import RUCosecha from '@/views/RU/Cosecha.vue'
import RUPecuarios from '@/views/RU/Pecuarios.vue'
import RUPecuario from '@/views/RU/Pecuario.vue'
import RUAcuiculturas from '@/views/RU/Acuiculturas.vue'
import RUAcuicultura from '@/views/RU/Acuicultura.vue'
import RUApiculturas from '@/views/RU/Apiculturas.vue'
import RUApicultura from '@/views/RU/Apicultura.vue'
import RUPescas from '@/views/RU/Pescas.vue'
import RUPesca from '@/views/RU/Pesca.vue'
import RUAgroturismos from '@/views/RU/Agroturismos.vue'
import RUAgroturismo from '@/views/RU/Agroturismo.vue'
import RUForestales from '@/views/RU/Forestales.vue'
import RUForestal from '@/views/RU/Forestal.vue'
import RUArtesanias from '@/views/RU/Artesanias.vue'
import RUArtesania from '@/views/RU/Artesania.vue'
import RUAgroindustrias from '@/views/RU/Agroindustrias.vue'
import RUAgroindustria from '@/views/RU/Agroindustria.vue'
import RUListados from '@/views/RU/Listados.vue'
Vue.use(Router)
export default new Router({
  routes: [
    {
      path: '/cuenta',
      name: 'Cuenta',
      component: Cuenta,
    },
    {
      path: '/bienvenido',
      name: 'bienvenido',
      component: () => import('@/views/Bienvenido.vue'),
    },
    {
      path: '/ru/productores',
      name: 'productores',
      component: RUProductores,
    },
    {
      path: '/ru/productor/empresa',
      name: 'empresa',
      component: RUEmpresa,
    },
    {
      path: '/ru/productor/crearempresa/:id?',
      name: 'crearempresa',
      component: RUCrearEmpresa,
      props: true,
    },
    {
      path: '/ru/reportes/productor',
      name: 'reportesproductor',
      component: () => import('./views/RU/Reportes/Productor.vue'),
    },
    {
      path: '/ru/reportes/productor/familia',
      name: 'reportesfamilia',
      component: () => import('./views/RU/Reportes/Familia.vue'),
    },
    {
      path: '/ru/reportes/productor/agricultura-familiar',
      name: 'reportesAgriculturaFamiliar',
      component: () => import('@/views/RU/Reportes/AgriculturaFamiliar.vue'),
    },
    {
      path: '/ru/reportes/productor/organizaciones-y-productores',
      name: 'reportesOrganizacionesYProductores',
      component: () =>
        import('@/views/RU/Reportes/OrganizacionesYProductores.vue'),
    },
    {
      path: '/ru/reportes/productor/contratacion-y-beneficiados',
      name: 'reportesContratacionYBeneficiados',
      component: () =>
        import('@/views/RU/Reportes/ContratacionYBeneficiados.vue'),
    },
    {
      path: '/ru/reportes/productor/bitacora-de-cambios',
      name: 'reportesBitacoraDeCambios',
      component: () => import('@/views/RU/Reportes/BitacoraDeCambios.vue'),
    },
    {
      path: '/ru/reportes/productores',
      name: 'reportesProductores',
      component: () => import('@/views/RU/Reportes/Productores.vue'),
    },
    {
      path: '/ru/reportes/productor/produccion-nacional',
      name: 'reportesProduccionNacional',
      component: () => import('@/views/RU/Reportes/ProduccionNacional.vue'),
    },
    {
      path: '/ru/reportes/productor/produccion-detallada',
      name: 'reportesProduccionDetallada',
      component: () => import('@/views/RU/Reportes/ProduccionDetallada.vue'),
    },
    {
      path: '/ru/reportes/productor/produccion-consolidada',
      name: 'reportesProduccionConsolidada',
      component: () => import('@/views/RU/Reportes/ProduccionConsolidada.vue'),
    },
    {
      path: '/ru/reportes/productor/mapa-productor',
      name: 'MapaProductor',
      component: () => import('@/views/RU/Reportes/MapaProductor.vue'),
    },
    {
      path: '/ru/reportes/productor/mapa-siembra',
      name: 'MapaSiembra',
      component: () => import('@/views/RU/Reportes/MapaSiembra.vue'),
    },
    {
      path: '/ru/reportes/productor/mapa-cosecha',
      name: 'MapaCosecha',
      component: () => import('@/views/RU/Reportes/MapaCosecha.vue'),
    },
    {
      path: '/ru/reportes/productor/mapa-geoespacial',
      name: 'MapaGeoespacial',
      component: () => import('@/views/RU/Reportes/MapaGeoespacial.vue'),
    },
    {
      path: '/ru/reportes/finca',
      name: 'reportesfinca',
      component: () => import('./views/RU/Reportes/Finca.vue'),
    },
    {
      path: '/ru/reportes/finca/rubro',
      name: 'reportesfincarubro',
      component: () => import('./views/RU/Reportes/FincaRubro.vue'),
    },
    {
      path: '/ru/reportes/finca/tenencia',
      name: 'reportesfincatenencia',
      component: () => import('./views/RU/Reportes/FincaTenencia.vue'),
    },
    {
      path: '/ru/reportes/pecuario',
      name: 'reportespecuario',
      component: () => import('./views/RU/Reportes/Pecuario.vue'),
    },
    {
      path: '/ru/reportes/forestal',
      name: 'reportesforestal',
      component: () => import('./views/RU/Reportes/Forestal.vue'),
    },
    {
      path: '/ru/reportes/artesania',
      name: 'reportesartesania',
      component: () => import('./views/RU/Reportes/Artesania.vue'),
    },
    {
      path: '/ru/reportes/agroindustria',
      name: 'reportesagroindustria',
      component: () => import('./views/RU/Reportes/Agroindustria.vue'),
    },
    {
      path: '/ru/reportes/acuicultura',
      name: 'reportesacuicultura',
      component: () => import('./views/RU/Reportes/Acuicultura.vue'),
    },
    {
      path: '/ru/reportes/agroturismo',
      name: 'reportesagroturismo',
      component: () => import('./views/RU/Reportes/Agroturismo.vue'),
    },
    {
      path: '/ru/reportes/apicultura',
      name: 'reportesapicultura',
      component: () => import('./views/RU/Reportes/Apicultura.vue'),
    },
    {
      path: '/ru/reportes/pesca',
      name: 'reportespesca',
      component: () => import('./views/RU/Reportes/Pesca.vue'),
    },
    {
      path: '/ru/productor/familia',
      name: 'familia',
      component: RUFamilia,
    },
    {
      path: '/ru/productor/cofamilia',
      name: 'cofamilia',
      component: RUCofamilia,
    },
    {
      path: '/ru/productor/formularioconsul',
      name: 'formularioconsul',
      component: RUFormularioConsul,
    },
    {
      path: '/ru/productor/consulta',
      name: 'consulta',
      component: RUConsulta,
    },
    {
      path: '/ru/productor/utmsave',
      name: 'utmsave',
      component: RUUTMsave,
    },
    {
      path: '/ru/productor/tiffsave',
      name: 'tiffsave',
      component: RUTiffSave,
    },
    
    {
      path: '/ru/productor/tiffsearch',
      name: 'tiffsearch',
      component: RUTiffSearch,
    },
    {
      path: '/ru/productor/cedulaerrada',
      name: 'cedulaerrada',
      component: RUCedulaErrada,
    },
    {
      path: '/ru/productor/:id?',
      name: 'productor',
      component: RUProductor,
    },
    {
      path: '/ru/productor/conexpediente/:id?',
      name: 'conexpediente',
      component: RUConexpediente,
    },
    {
      path: '/ru/productor/formularioconexpe/:id?',
      name: 'formularioconexpe',
      component: RUFormularioConExpe,
    },
    {
      path: '/ru/productor/:id/clasificacion',
      name: 'clasificacion',
      component: RUClasificacionProductor,
    },
    {
      path: '/ru/fincas',
      name: 'fincas',
      component: RUFincas,
    },
    {
      path: '/ru/finca/:id?',
      name: 'finca',
      component: RUFinca,
    },
    {
      path: '/ru/cofincas',
      name: 'cofincas',
      component: RUCofincas,
    },
    {
      path: '/ru/cofinca/:id?',
      name: 'cofinca',
      component: RUCofinca,
    },
    {
      path: '/ru/establecimientos',
      name: 'establecimientos',
      component: RUEstablecimientos,
    },
    {
      path: '/ru/establecimiento/:id?',
      name: 'establecimiento',
      component: RUEstablecimiento,
    },
    {
      path: '/ru/parcelas',
      name: 'parcelas',
      component: RUParcelas,
    },
    {
      path: '/ru/parcela/:id?',
      name: 'parcela',
      component: RUParcela,
    },
    {
      path: '/ru/coparcelas',
      name: 'coparcelas',
      component: RUCoparcelas,
    },
    {
      path: '/ru/coparcela/:id?',
      name: 'coparcela',
      component: RUCoparcela,
    },
    {
      path: '/ru/embarcaciones',
      name: 'embarcaciones',
      component: RUEmbarcaciones,
    },
    {
      path: '/ru/embarcacion/:id?',
      name: 'embarcacion',
      component: RUEmbarcacion,
    },
    {
      path: '/ru/preparaciones',
      name: 'preparacion',
      component: RUPreparaciones,
    },
    {
      path: '/ru/preparacion/:id?',
      name: 'preparaciones',
      component: RUPreparacion,
    },
    {
      path: '/ru/cultivos',
      name: 'siembras',
      component: RUSiembras,
    },
    {
      path: '/ru/cultivo/:id?',
      name: 'siembra',
      component: RUSiembra,
    },
    {
      path: '/ru/cosechas',
      name: 'cosechas',
      component: RUCosechas,
    },
    {
      path: '/ru/cosecha/:id?',
      name: 'cosecha',
      component: RUCosecha,
    },
    {
      path: '/ru/pecuarios',
      name: 'pecuarios',
      component: RUPecuarios,
    },
    {
      path: '/ru/pecuario/:id?',
      name: 'pecuario',
      component: RUPecuario,
    },
    {
      path: '/ru/acuiculturas',
      name: 'acuiculturas',
      component: RUAcuiculturas,
    },
    {
      path: '/ru/acuicultura/:id?',
      name: 'acuicultura',
      component: RUAcuicultura,
    },
    {
      path: '/ru/apiculturas',
      name: 'apiculturas',
      component: RUApiculturas,
    },
    {
      path: '/ru/apicultura/:id?',
      name: 'apicultura',
      component: RUApicultura,
    },
    {
      path: '/ru/pescas',
      name: 'pescas',
      component: RUPescas,
    },
    {
      path: '/ru/pesca/:id?',
      name: 'pesca',
      component: RUPesca,
    },
    {
      path: '/ru/agroturismos',
      name: 'agroturismos',
      component: RUAgroturismos,
    },
    {
      path: '/ru/agroturismo/:id?',
      name: 'agroturismo',
      component: RUAgroturismo,
    },
    {
      path: '/ru/forestales',
      name: 'forestales',
      component: RUForestales,
    },
    {
      path: '/ru/forestal/:id?',
      name: 'forestal',
      component: RUForestal,
    },
    {
      path: '/ru/artesanias',
      name: 'artesanias',
      component: RUArtesanias,
    },
    {
      path: '/ru/artesania/:id?',
      name: 'artesania',
      component: RUArtesania,
    },
    {
      path: '/ru/agroindustrias',
      name: 'agroindustrias',
      component: RUAgroindustrias,
    },
    {
      path: '/ru/agroindustria/:id?',
      name: 'agroindustria',
      component: RUAgroindustria,
    },
    {
      path: '/ru/listados',
      name: 'listados',
      component: RUListados,
    },
    {
      path: '/ru/copreparaciones',
      name: 'copreparacion',
      component: RUCopreparaciones,
    },
    {
      path: '/ru/copreparacion/:id?',
      name: 'copreparaciones',
      component: RUCopreparacion,
    },
    {
      path: '/ru/cocultivos',
      name: 'cosiembras',
      component: RUCosiembras,
    },
    {
      path: '/ru/cocultivo/:id?',
      name: 'cosiembra',
      component: RUCosiembra,
    },
    {
      path: '/ru/cocosechas',
      name: 'cocosechas',
      component: RUCocosechas,
    },
    {
      path: '/ru/cocosecha/:id?',
      name: 'cocosecha',
      component: RUCocosecha,
    },
    {
      path: '/ru/copecuarios',
      name: 'copecuarios',
      component: RUCopecuarios,
    },
    {
      path: '/ru/copecuario/:id?',
      name: 'copecuario',
      component: RUCopecuario,
    },
    {
      path: '/ru/coacuiculturas',
      name: 'coacuiculturas',
      component: RUCoacuiculturas,
    },
    {
      path: '/ru/coacuicultura/:id?',
      name: 'coacuicultura',
      component: RUCoacuicultura,
    },
    {
      path: '/ru/coapiculturas',
      name: 'coapiculturas',
      component: RUCoapiculturas,
    },
    {
      path: '/ru/coapicultura/:id?',
      name: 'coapicultura',
      component: RUCoapicultura,
    },
    {
      path: '/ru/copescas',
      name: 'copescas',
      component: RUCopescas,
    },
    {
      path: '/ru/copesca/:id?',
      name: 'copesca',
      component: RUCopesca,
    },
    {
      path: '/ru/coagroturismos',
      name: 'coagroturismos',
      component: RUCoagroturismos,
    },
    {
      path: '/ru/coagroturismo/:id?',
      name: 'coagroturismo',
      component: RUCoagroturismo,
    },
    {
      path: '/ru/coforestales',
      name: 'coforestales',
      component: RUCoforestales,
    },
    {
      path: '/ru/coforestal/:id?',
      name: 'coforestal',
      component: RUCoforestal,
    },
    {
      path: '/ru/coartesanias',
      name: 'coartesanias',
      component: RUCoartesanias,
    },
    {
      path: '/ru/coartesania/:id?',
      name: 'coartesania',
      component: RUCoartesania,
    },
    {
      path: '/ru/coagroindustrias',
      name: 'coagroindustrias',
      component: RUCoagroindustrias,
    },
    {
      path: '/ru/coagroindustria/:id?',
      name: 'coagroindustria',
      component: RUCoagroindustria,
    },
    {
      path: '*',
      name: 'inicio',
      component: RUProductores,
    },
  ],
  // coloca el scroll al inicio de la página al navegar de una ruta a otra
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
