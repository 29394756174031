<template>
  <v-container class="py-0">
    <v-row class="pt-4">      
  <div>
    <h1>Procesamiento de Multipolígonos en UTM</h1>
  </div>
   <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Se está cargando...</span>
    </v-overlay>

      <v-col cols="6" md="6">
        <v-file-input v-model="archivo" outlined label="File" @change="leerArchivo" accept=".geojson" />
      </v-col>
      <v-col cols="6" md="6">
        <button class="boton-guardar" @click="guardarGeojson">Guardar</button>
      </v-col>
    </v-row>

    <v-col cols="12">
      <div class="scrollable-table">
        <div class="table-container">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="header">Nombre</th>
                  <th class="header">Perimetr_m</th>
                  <th class="header">Area_m2</th>
                  <th class="header">Area_ha</th>
                  <th class="header">ID</th>
                  <th class="header">Id_cultivo</th>
                  <th class="header">No_coa</th>
                  <th class="header">Ano_agrico</th>
                  <th class="header">Id_distri</th>
                  <th class="header">Nom_distri</th>
                  <th class="header">Id_corr</th>
                  <th class="header">Nom_corr</th>
                  <th class="header">Fecha_elab</th>
                  <th class="header">Tipo</th>
                  <th class="header">Coordenadas</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sumatoria Total de Area</td>
                  <td>{{ totalPerimeter.toFixed(3) }}</td>
                  <td>{{ totalAreaM2.toFixed(3) }}</td>
                  <td>{{ totalAreaHa.toFixed(3) }}</td>
                </tr>
                <tr v-for="(feature, index) in features" :key="index">
                  <td>{{ feature.properties.Nombre }}</td>
                  <td>{{ feature.properties.Perimetr_m }}</td>
                  <td>{{ feature.properties.Area_m2 }}</td>
                  <td>{{ feature.properties.Area_ha }}</td>
                  <td>{{ index + 1 }}</td>
                  <td>{{ feature.properties.Id_cultivo }}</td>
                  <td>{{ feature.properties.No_coa }}</td>
                  <td>{{ feature.properties.Ano_agrico }}</td>
                  <td>{{ feature.properties.Id_distri }}</td>
                  <td>{{ feature.properties.Nom_distri }}</td>
                  <td>{{ feature.properties.Id_corr }}</td>
                  <td>{{ feature.properties.Nom_corr }}</td>
                  <td>{{ feature.properties.Fecha_elab }}</td>
                  <td>{{ feature.geometry.type }}</td>
                  <td>
                    <button @click="showFullCoordinates = !showFullCoordinates">
                      {{ showFullCoordinates ? 'Ocultar' : 'Mostrar más' }}
                    </button>
                    <span v-if="!showFullCoordinates">{{ truncate(feature.geometry.coordinates, 20) }}</span>
                    <span v-else>{{ feature.geometry.coordinates }}</span>

                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'UTMsave',
  data() {
    return {
      isLoading: false,
      showFullCoordinates: false,
      archivo: null,
      features: [],
      totalPerimeter: 0,
      totalAreaM2: 0,
      totalAreaHa: 0,
      payload: {}
    };
  },

  mounted() {    
    this.$store.commit('ui/updateToolbar', 'Procesa Multipoligonos UTM')
  },
  methods: {
    prepararPayload() {
      if (this.payload.features.length > 0) {
        const geojson = this.payload;

        console.log('geojson', geojson)
        console.log('geojson.crs', geojson.crs.properties.name)
        console.log('geojson.features[0]', geojson.features[0])

        this.payload = {
          "type": geojson.type,
          "name": geojson.name,
          "crs": geojson.crs,
          "features": geojson.features
        };
        console.log('this.payload', this.payload)
      } else {
        console.error('No hay datos de features cargados');
      }
    },


   async guardarGeojson() {
      this.isLoading = true;
      await this.$store.dispatch('registros/guardarUTM', this.payload)
      console.log('saved')
      
        await this.simulateUpload();
        
        this.isLoading = false;
    },
     simulateUpload() {
      // Simula una espera como si se estuviera subiendo un archivo
      return new Promise((resolve) => {
        setTimeout(resolve, 200); // 3 segundos de espera
      });
    },
    truncate(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
    leerArchivo(event) {
      const archivo = this.archivo;
      if (archivo) {
        const lector = new FileReader();
        lector.onload = (e) => {
          try {
            const geojson = JSON.parse(e.target.result);
            this.payload = geojson
            console.log('geojson.features', geojson.features)
            if (geojson && geojson.features) {
              this.features = geojson.features.map((feature) => ({
                geometry: {
                  type: feature.geometry.type,
                  coordinates: JSON.stringify(feature.geometry.coordinates),
                },
                properties: feature.properties,
              }));
            } else {
              console.error('Archivo GeoJSON no válido');
            }
            
            console.log('this.features', this.features)
            this.sumatoria();
            this.prepararPayload();
            console.log('payload', this.payload)
          } catch (error) {
            console.error('Error al parsear el archivo GeoJSON:', error);
          }
        };
        lector.readAsText(archivo);
      } else {
        console.error('No se seleccionó ningún archivo');
      }

    },

    sumatoria() {
      this.features.forEach(feature => {
        this.totalPerimeter += feature.properties.Perimetr_m;
        this.totalAreaM2 += feature.properties.Area_m2;
        this.totalAreaHa += feature.properties.Area_ha;
      });
    }
  },
};
</script>

<style scoped>
.scrollable-table {
  max-height: 400px;
  overflow: auto;
}

.header {
  background-color: #d4edda;
  /* Color verde claro */
}

.boton-guardar {

  border: 1px solid black;
  background-color: #d4edda;
  /* Verde claro */
  color: black;
  /* Texto en negro */
  border-radius: 5px;
  margin-top: 5px;
  /* Espacio arriba del botón */
  padding: 10px 20px;
  /* Padding interno */
}

.table-container {
  overflow-x: auto;
}
</style>
